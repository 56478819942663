 
 
import React, { useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import { sendGAExternalLinkEvent } from "../../utility/google-analytics";

const ExternalLinkHandler = () => {

    const [open, setOpen] = useState(false);
    const [externalUrl, setExternalUrl] = useState(null);
  
    const handleClickToOpen = () => {
      setOpen(true);
    };
    
    const handleToClose = () => {
      setOpen(false);
      setExternalUrl(null);
    };
  
    const openExternalUrl = () => {
      if (externalUrl != null) {
        window.open(externalUrl, "_blank");        
        sendGAExternalLinkEvent(externalUrl);
      }
      setExternalUrl(null);
    }
  
    document.onclick = (e) => {
      let target = e.target;
      
      while(target) {
        if (target.tagName === 'A') {
          if (target.href.indexOf(target.baseURI) < 0 && target.href.indexOf('.pdf') < 0) {
            e.preventDefault();
            setExternalUrl(target.href);
            handleClickToOpen();
          }
          break;        
        }
        
        target = target.parentElement;
      }
    };
  
    return (
        <ConfirmDialog    
            open={open}
            setOpen={setOpen}
            onConfirm={openExternalUrl}    
            confirmText="CONTINUE TO NEW SITE"
            cancelText="Cancel"                      
        >
            <h2>You are now leaving YourMedicareSolutions.com</h2>
            Thank you for visiting<p></p>
        </ConfirmDialog>
        );
};

export default ExternalLinkHandler;