import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Button } from "@material-ui/core";
import moment from "moment";
import { submitEnrollment } from "../../api/enrollmentApi";
import axios from "axios";
import { browserName, browserVersion } from "react-device-detect";
import { getTelemetry } from "../../utility/telemetry";

const ReviewChange = ({
  values,
  handleNext,
  handlePrevious,
  handleJumpTo,
  activeStep,
  acceptTerms,
  steps,
  preparer,
  setFormValues,
  setBackButtonDisabled,
}) => {
  let p1 = activeStep === 0 ? true : false;

  let submitDateTime = "";

  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    let ip = JSON.stringify(res.data.IPv4);

    // console.log(ip);

    return ip;
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const handleNextClick = async () => {
    setIsProcessing(true);
    setBackButtonDisabled(true);

    submitDateTime = moment().format("MM/DD/YYYY h:mm:ss a");
    // console.log(submitDateTime);

    let f = values;

    f.dor = submitDateTime;
    f.timestamp = submitDateTime;
    f.ipaddress = ""; //await getIpAddress();
    f.browsername = browserName;
    f.browserversion = browserVersion;
    f.telemetry = getTelemetry();

    let s = await submitEnrollment(f);

    let o = JSON.parse(s);

    // console.log("Submit enrollment success: " + JSON.stringify(s));
    // console.log("Confirmation: " + o.confirmationno);

    if (s.confirmationno !== "") {
      setFormValues({ ...values, confirmationno: o.confirmationno });
      handleNext();
    } else {
    }

    setIsProcessing(false);
  };

  // console.log("Review Preparer: " + preparer);

  return (
    <div>
      <div
        className="
                  field
                  field--name-webform
                  field--type-webform
                  field--label-hidden
                  field__item
                "
      >
        <div
          className="
                    webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                  "
          data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
          data-msg-required="This field is required."
          action="/mbrx-enroll-2021"
          method="post"
          id="webform-submission-mbrx-enroll-2021-node-234-add-form"
          acceptCharset="UTF-8"
          noValidate="noValidate"
          data-drupal-form-fields="edit-step-1,edit-step-2,edit-step-3,edit-step-4,edit-agree-terms-enroll-auth,edit-datesigned,edit-signaturefirst,edit-signaturelast,edit-preparer-enrollee,edit-preparer-auth-rep,edit-preparer-assisted,edit-preparer-agent,edit-callcenter,edit-assistorsigned,edit-assistorrelationship2applicant,edit-assistorsigneddate,edit-agentfirst,edit-agentlast,edit-agentid,edit-enrollee-perm-state,edit-agencyid,edit-agentphone,edit-callcentername,edit-callcenterrep,edit-ridnbr,edit-please-specify-one-present,edit-please-specify-one-attestation,edit-datesignedpaper,edit-agentsigned,edit-has-authrep-info,edit-authrepfirstname,edit-authreplastname,edit-authreprelationship,edit-authrepstreet,edit-authrepcity,edit-authrepstate,edit-authrepzip,edit-authrepphone,edit-authrepmail,edit-accessibleformatoption-braille,edit-accessibleformatoption-large-print,edit-accessibleformatoption-audio-cd,edit-actions-wizard-prev,edit-actions-wizard-next"
        >
          <div
            data-webform-key="step_5"
            data-drupal-selector="edit-step-5"
            className="js-form-wrapper form-wrapper form-group"
            data-msg-required="Step 5 is required."
            id="edit-step-5--2"
          >
            <div
              id="edit-step-5-title"
              className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                      "
            >
              <h2>Review Enrollment</h2>
            </div>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <div>
              <div
                className="dialog-off-canvas-main-canvas"
                data-off-canvas-main-canvas=""
              >
                <div
                  role="main"
                  className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
                ></div>
                <div className="row d-md-flex">
                  <section className="col-sm-12 internal-page">
                    <div className="region region-content">
                      <article className="webform full clearfix">
                        <div className="content">
                          <div>
                            <p className="toggle2">
                              S5743_090920NN02_C
                              <br />
                            </p>
                          </div>
                        </div>

                        <div
                          className="
                        webform-submission-form
                        webform-submission-add-form                        
                        webform-details-toggle
                      "
                        >
                          <div className="js-form-wrapper form-wrapper form-group">
                            <div
                              className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            >
                              {/*Row 1*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Personal Information*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Personal information
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div
                                      {...(values.firstname === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        First name:{" "}
                                      </label>{" "}
                                      {values.firstname}
                                      <br />
                                    </div>

                                    <div
                                      {...(values.lastname === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Last name:
                                      </label>{" "}
                                      {values.lastname}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.dob === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Date of birth:
                                      </label>{" "}
                                      {values.dob}
                                      <br />
                                    </div>
                                  </div>
                                </div>

                                {/*Phone email changes*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Phone email changes
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body"></div>
                                  <div
                                    {...(values.homephone === "" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    <label className="control-label">
                                      Home phone:
                                    </label>{" "}
                                    {values.homephone}
                                    <br />
                                  </div>
                                  <div
                                    {...(values.altphone === "" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    <label className="control-label">
                                      Alternate phone:
                                    </label>{" "}
                                    {values.altphone}
                                    <br />
                                  </div>
                                  <div
                                    {...(values.email === "" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    <label className="control-label">
                                      Email address
                                    </label>{" "}
                                    {values.email}
                                    <br />
                                  </div>
                                </div>
                              </div>

                              {/*Row 2*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Permanent address change*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Permanent address change
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div
                                      {...(values.permst1 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Address:
                                      </label>{" "}
                                      {values.permst1}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.permst2 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Address 2
                                      </label>{" "}
                                      {values.permst2}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.permcity === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        City:
                                      </label>{" "}
                                      {values.permcity}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.permstate === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        State:
                                      </label>{" "}
                                      {values.permstate}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.permzip5 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Zip code (xxxxx):
                                      </label>{" "}
                                      {values.permzip5}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.permzip4 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Zip+4 (xxxx)
                                      </label>{" "}
                                      {values.permzip4}
                                      <br />
                                    </div>
                                  </div>
                                </div>

                                {/*Mailing address change*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Mailing address change
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div
                                      {...(values.mailst1 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Mailing Address:
                                      </label>{" "}
                                      {values.mailst1}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.mailst2 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Mailing Address 2:
                                      </label>{" "}
                                      {values.mailst2}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.mailcity === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Mailing City:
                                      </label>{" "}
                                      {values.mailcity}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.mailst === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Mailing State:
                                      </label>{" "}
                                      {values.mailst}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.mailzip5 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Mailing Zip Code (xxxxx):
                                      </label>{" "}
                                      {values.mailzip5}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.mailzip4 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Mailing Zip+4:
                                      </label>{" "}
                                      {values.mailzip4}
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*Row 3*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Billing address change*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-billing-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-billing-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Billing address change
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div
                                      {...(values.billingaddress1 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Billing Address:
                                      </label>{" "}
                                      {values.billingaddress1}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.billingaddress2 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Billing Address 2:
                                      </label>{" "}
                                      {values.billingaddress2}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.billingcity === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Billing City:
                                      </label>{" "}
                                      {values.billingcity}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.billingst === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Billing State:
                                      </label>{" "}
                                      {values.billingst}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.billingzip5 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Billing Zip Code (xxxxx):
                                      </label>{" "}
                                      {values.billingzip5}
                                      <br />
                                    </div>
                                    <div
                                      {...(values.billingzip4 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Billing Zip+4:
                                      </label>{" "}
                                      {values.billingzip4}
                                      <br />
                                    </div>
                                  </div>
                                </div>

                                {/*Applicant/Auth Rep Signature*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Authorized signature
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div>
                                      <label className="control-label">
                                        Today's date:
                                      </label>{" "}
                                      {moment().format("MM/DD/YYYY")}
                                      <br />
                                    </div>

                                    <div>
                                      <label className="control-label">
                                        First name
                                      </label>{" "}
                                      {values.signaturefirst}
                                      <br />
                                    </div>

                                    <div>
                                      <label className="control-label">
                                        Last name:
                                      </label>{" "}
                                      {values.signaturelast}
                                      <br />
                                    </div>
                                    <div>
                                      <label className="control-label">
                                        Signature confirm:
                                      </label>{" "}
                                      {values.signaturefirst.length +
                                        values.signaturelast.length >
                                      0
                                        ? "Y"
                                        : "N"}
                                      <br />
                                    </div>
                                    <div>
                                      <label className="control-label">
                                      Request effective date for changes to take effect:
                                      </label>{" "}
                                      {values.demographicseffectivedate &&  moment(values.demographicseffectivedate).format("MM/DD/YYYY")} 
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Button
                                className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                                onClick={handlePrevious}
                                //disabled={p1}
                                {...(p1 && {
                                  style: { display: "none" },
                                })}
                              >
                                Previous
                              </Button>

                              <Button
                                className="
                                  webform-button--next
                                  button
                                  js-form-submit
                                  form-submit
                                  btn-default btn"
                                onClick={handleNextClick}
                                disabled={isProcessing}
                                {...(isProcessing && {
                                  style: { color: "black" },
                                })}
                              >
                                {isProcessing ? (
                                  <div>
                                    <span
                                      className="spinner-border pmd-spinner spinner-border-sm text-light mr-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    "Processing..."
                                  </div>
                                ) : (
                                  "Send Now"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewChange;
