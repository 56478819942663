import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const PharmacyTools = () => {
  const [content, isLoading] = useContent("4YVKtuXpuWSdTOLNSzzPlB");

  const flexContainerAdjusted = {
    width: '280px'
  }
  

  if (isLoading) return <p>Loading...</p>;

  // console.log(content);

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/compare-plans"> Compare plans </Link>
                        </li>
                        <li>Plan research tools</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content.ModuleHeading}
              />
            </h1>

            <article
              data-history-node-id="191"
              role="article"
              about="/pharmacy-tools-landing"
              className="standard full clearfix"
            >
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  {/* NPA tools landing */}
                  <p>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.bodyText}
                    />
                  </p>

                  <div className="flex-container">
                    <div style={flexContainerAdjusted}>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[0].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[0].fields.heading}</h4>

                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[0].fields.shortBodyText
                          }
                        />
                      </p>
                    </div>

                    <div style={flexContainerAdjusted}>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[1].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[1].fields.heading}</h4>

                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[1].fields.shortBodyText
                          }
                        />
                      </p>
                    </div>

                    <div style={flexContainerAdjusted}>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[2].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[2].fields.heading}</h4>

                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[2].fields.shortBodyText
                          }
                        />
                      </p>
                    </div>

                    <div style={flexContainerAdjusted}>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[3].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[3].fields.heading}</h4>

                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[3].fields.shortBodyText
                          }
                        />
                      </p>
                    </div>

                    <div style={flexContainerAdjusted}>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[4].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[4].fields.heading}</h4>

                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[4].fields.shortBodyText
                          }
                        />
                      </p>
                    </div>

                    <div style={flexContainerAdjusted}>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[5].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[5].fields.heading}</h4>

                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[5].fields.shortBodyText
                          }
                        />
                      </p>
                    </div>

                    <div style={flexContainerAdjusted}>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[6].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[6].fields.heading}</h4>

                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[6].fields.shortBodyText
                          }
                        />
                      </p>
                    </div>
                  </div>
                  {/* <p>
                    <sup>&dagger;</sup>CVS Caremark Part D Services is an
                    independent company providing pharmacy benefit management
                    services.
                  </p> */}
                  <ReactMarkdown  rehypePlugins={[rehypeRaw]} children={content.bodyText2} />
                  {/* end NPA tools landing */}
                </div>

                <div className="field field--name-field-table-menu field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div className="field-collection-container">
                      <div className="field field--name-field-table-menu-content field--type-entity-reference-revisions field--label-hidden field__items">
                        <div className="field__item odd">
                          <div className="paragraph paragraph--type--table-menu-content paragraph--view-mode--default"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field field--name-field-add-accordion field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div id="accordion-191" className="form-group"></div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PharmacyTools;
