import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import logo from "../../assets/files/inline-images/logo.png";
import NavBar from "./NavBar";
import BurgerBarMenu from "./BurgerBarMenu";
import useSearch from "../../services/contentful/search";
import { Button, Spinner } from "react-bootstrap";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../state/actions/updateEnrollData";
import { slide as Menu } from "react-burger-menu";

const Header = ({ history, resizeText }) => {
  //let history = useHistory();

  const [bbMenuOpen, setBBMenuOpen] = useState(false);

  const { state, actions } = useStateMachine({
    updateEnrollData,
  });

  const client = require("contentful").createClient({
    space: "xfdtsvzh7hmn",
    accessToken: "4SzAC92dntRfImuTwxJKiB0Vyg9y3MKpJxVcCLp6fHg",
    environment: process.env.REACT_APP_CONTENTFUL_ENV,
  });

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    let query = searchParams.get("query") ?? "";
    setSearchString(query);
}, [history.location]);

  const handleSearchInput = (e) => {
    // console.log("Search string: " + e.target.value);

    setSearchString(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      // console.log("Enter key was pressed. Run your function.");
      event.preventDefault();
      handleSearch();
    }
  }

  // useEffect(() => {
  //   const listener = (event) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       // console.log("Enter key was pressed. Run your function.");
  //       event.preventDefault();
  //       handleSearch();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);



  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);

    // console.log("Search string: " + searchString);

    let entries = "";
    let assets = "";

    await client
      .getEntries({ query: searchString })
      .then((response) => {
        entries = response.items;
        //setLoading(false);
        // console.log(response.items);
      })
      .catch(() => {});

      await client
      .getAssets({ query: searchString })
      .then((response) => {
        assets = response.items;
        setLoading(false);
        //console.log(response.items);
      })
      .catch(() => {});


    let searchParams = new URLSearchParams({'query': searchString });
    history.push({
      pathname: "/search",
      search: searchParams.toString(),
      state: { entries: entries,
                assets: assets },
    });
  };

  return (
    <div className="header_wrapper_section" id="header_wrapper_section">
      <div id="header-left" className="container py-4 head-point">
        <div className="region region-header-left">
          <div
            className="search-block-form block block-search block-search-form-block"
            data-msg-required="This field is required."
            id="block-searchform-2"
            role="search"
          >
            <div>
              <div className="form-item js-form-item form-type-search js-form-type-search form-item-keys js-form-item-keys form-no-label form-group">
                <label htmlFor="edit-keys" className="control-label sr-only">
                  Search
                </label>

                <div className="input-group">
                  <input
                    value={searchString}
                    onChange={handleSearchInput}
                    type="text"
                    //onKeyPress={handleSearch}
                    onKeyPress={handleKeyPress}
                    title="Enter the terms you wish to search for."
                    className="form-control"
                    placeholder="Search"
                    //type="text"
                    id="searchBox"
                    //name="keys"
                    size="15"
                    maxLength="128"
                  />
                  <span className="input-group-btn">
                    <Button
                      className="button btn-primary btn icon-only"
                      onClick={handleSearch}
                    >
                      <span className="sr-only">Search</span>
                      <span
                        className="icon glyphicon glyphicon-search"
                        aria-hidden="true"
                      ></span>
                    </Button>
                  </span>
                </div>
              </div>
              <div
                className="form-actions form-group js-form-wrapper form-wrapper"
                data-msg-required="This field is required."
                id="edit-actions"
              ></div>
            </div>
          </div>

          <NavLink
            className="logo navbar-btn pull-left"
            to="/"
            title="Home"
            rel="home"
          >
            <img src={logo} alt="Home" />
          </NavLink>

          <section
            id="block-mobilesearch"
            className="m-icon block block-block-content block-block-content5702eff7-1460-4ef2-ac7c-190a373ca9be clearfix"
          >
            <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
              <p>
                <button
                  className="button btn-primary btn icon-only m-search"
                  //onClick="show_search()"
                  type="submit"
                >
                  <span className="icon glyphicon glyphicon-search">
                    search
                  </span>
                </button>
              </p>
            </div>
          </section>
        </div>

        <div className="right-section">
          <div className="region region-header-right">
            <div
              className="search-block-form search-section block block-search block-search-form-block"
              data-msg-required="This field is required."
              id="block-searchform"
              role="search"
            >
              <form id="search-block-form--2">
                <div className="form-item js-form-item form-type-search js-form-type-search form-item-keys js-form-item-keys form-no-label form-group">
                  <label
                    htmlFor="edit-keys--2"
                    className="control-label sr-only"
                  >
                    Search
                  </label>

                  <div className="input-group">
                    <input
                      value={searchString}
                      onChange={handleSearchInput}
                      onKeyPress={handleKeyPress}
                      title="Enter the terms you wish to search for."
                      className="form-search form-control"
                      placeholder="Search"
                      type="text"
                      id="edit-keys--2"
                      //name="keys"
                      //value=""
                      size="15"
                      maxLength="128"
                      data-toggle="tooltip"
                    />
                    <span className="input-group-btn">
                      <Button
                        onClick={handleSearch}
                        className="button js-form-submit form-submit btn-primary btn icon-only"
                      >
                        <span className="sr-only">Search</span>
                        <span
                          className="icon glyphicon glyphicon-search"
                          aria-hidden="true"
                        ></span>
                      </Button>
                    </span>
                  </div>
                </div>
                <div
                  className="form-actions form-group js-form-wrapper form-wrapper"
                  data-msg-required="This field is required."
                  id="edit-actions--2"
                ></div>
              </form>
            </div>
            <section
              id="block-header-right-text"
              className="tab-area mr-3 block block-block-content block-block-content4babdd19-78c0-4968-8ac5-0dffacb8e10e clearfix"
            >
              <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                <ul>
                  <li>
                    <NavLink
                      className="normal"
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        resizeText(0);
                      }}
                    >
                      Tt
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="big"
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        resizeText(1.5);
                      }}
                    >
                      Tt
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="bigger"
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        resizeText(2);
                      }}
                    >
                      Tt
                    </NavLink>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>

        <BurgerBarMenu bbMenuOpen={bbMenuOpen} setBBMenuOpen={setBBMenuOpen} />
      </div>

      <NavBar />
    </div>
  );
};

const HeaderWithRouter = withRouter(Header);

export default HeaderWithRouter;
