import { useEffect, useState } from "react";

const client = require("contentful").createClient({
  space: "xfdtsvzh7hmn",
  accessToken: "4SzAC92dntRfImuTwxJKiB0Vyg9y3MKpJxVcCLp6fHg",
  environment: process.env.REACT_APP_CONTENTFUL_ENV,
});

export default function useContent(entryId) {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    client.getEntry(entryId).then((response) => {
      setContent(response.fields);
      setLoading(false);
      console.log(response.fields);
    });
  }, []);

  return [content, isLoading];
}
