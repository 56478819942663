import React, { useState, useEffect } from "react";
import "../../assets/modules/contrib/back_to_top/css/back_to_top.css";
import BackToTopImg from "../../assets/modules/contrib/back_to_top/backtotop.png";
import Fab from "@material-ui/core/Fab";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const style = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  };

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div>
      {showScroll && (
        <div>
          <Fab onClick={scrollTop} style={style}>
            <img src={BackToTopImg} alt="Back to top" />
          </Fab>
        </div>
      )}
    </div>
  );
};

export default BackToTop;
