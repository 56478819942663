import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "50%",
    left: "50%",
    right: "50%",
    // position: "fixed",
    // width: "50%",
    // height: "50%",
    // top: "50%",
    // left: "50%",
    // right: "50%",
    // bottom: "50%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Terms2025 = ({ termsOpen, handleTermsOpen, handleTermsClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={termsOpen}
        onClose={handleTermsClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={termsOpen}>
          <div className={classes.paper}>
            <h2>
              Enrollment authorization: By completing this enrollment
              application, I agree to the following
            </h2>

            <ol>
              <li>I must keep Part A or Part B to stay in MedicareBlue Rx.</li>
              <li>
                By joining this Medicare prescription drug plan, I acknowledge
                that MedicareBlue Rx will share my information with Medicare,
                who may use it to track my enrollment, to make payments, and for
                other purposes allowed by federal law that authorize the
                collection of this information.
              </li>
              <li>
                Your response to this form is voluntary. However, failure to
                respond may affect enrollment in the plan.
              </li>
              <li>
                The information on this enrollment form is correct to the best
                of my knowledge. I understand that if I intentionally provide
                false information on this form, I will be disenrolled from the
                plan.
              </li>
              <li>
                I understand that people with Medicare are generally not covered
                under Medicare while out of the country, except for limited
                coverage near the U.S. border.
              </li>
            </ol>

            <h2>Privacy Act Statement</h2>

            <p>
              The Centers for Medicare &amp; Medicaid Services (CMS) collects
              information from Medicare plans to track beneficiary enrollment in
              Medicare Advantage (MA) or Prescription Drug Plans (PDP), improve
              care, and for the payment of Medicare benefits. Sections 1851 and
              1860D-1 of the Social Security Act and 42 CFR §§ 422.50, 422.60,
              423.30 and 423.32 authorize the collection of this information.
              CMS may use, disclose and exchange enrollment data from Medicare
              beneficiaries as specified in the System of Records Notice (SORN)
              “Medicare Advantage Prescription Drug (MARx)”, System No.
              09-70-0588. Your response to this form is voluntary. However,
              failure to respond may affect enrollment in the plan.
            </p>
            <Button
              className="
              webform-button--next
              button
              js-form-submit
              form-submit
              btn-default btn"
              onClick={handleTermsClose}
            >
              Close
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Terms2025;
