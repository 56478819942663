import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

const BurgerBarMenu = ({ bbMenuOpen, setBBMenuOpen }) => {
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "20px",
      height: "20px",
      right: "25px",
      top: "30px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      //background: "#373a47",
      background: "white",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#055A8D",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  //const [menuOpen, setMenuOpen] = useState(false);
  // useEffect(() => {}, [menuOpen]);

  let menuOpen = false;
  const closeMenu = () => {
    menuOpen = false;
  };

  return (
    <div className="container py-4 head-point">
      <div className="container-fluid">
        <div className="navbar-header navbar-toggle">
          <Menu right noOverlay isOpen={menuOpen} styles={styles}>
            <ul className="menu menu--main nav navbar-nav">
              <li>
                <NavLink to="/" onClick={() => closeMenu()}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/compare-plans" onClick={() => closeMenu()}>
                  Compare plans
                </NavLink>
              </li>
              <li>
                <NavLink to="/enroll-0" onClick={() => closeMenu()}>
                  Enroll
                </NavLink>
              </li>
              <li>
                <NavLink to="/Members" onClick={() => closeMenu()}>
                  Members
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Documents/IndividualPlanDocs2022"
                  onClick={() => closeMenu()}
                >
                  Documents
                </NavLink>
              </li>
              <li>
                <NavLink to="/PartDBasics" onClick={() => closeMenu()}>
                  Part D basics
                </NavLink>
              </li>
              <li>
                <NavLink to="/GroupBenefits" onClick={() => closeMenu()}>
                  Employer group plans
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact/medicareblue-rx"
                  onClick={() => closeMenu()}
                >
                  Contact us
                </NavLink>
              </li>
            </ul>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default BurgerBarMenu;
