import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";

const Step4_2024 = ({
  //values,
  handleChange,
  handleNext,
  handlePrevious,
  activeStep,
  acceptTerms,
  steps,
}) => {
  const {
    register,
    watch,
    formState: { error, isValid },
  } = useForm();

  const { state, actions } = useStateMachine({ updateEnrollData });

  let p1 = activeStep === 0 ? true : false;

  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);

  const handleNextClick = () => {
    var radios = document.querySelectorAll('input[type="radio"]');
    var checkedOne = Array.prototype.slice.call(radios).some((x) => x.checked);
    if (checkedOne) {
      handleNext();
    } else {
      setDisplayCheckOptionError(true);
    }

    // console.log(displayCheckOptionError);
  };

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <div data-drupal-messages-fallback="" className="hidden"></div>
            <section
              id="block-clearstonebreadcrumbs"
              className="
              block
              block-clearstone-updates
              block-clearstone-breadcrumbs-block
              clearfix
            "
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>MBRx Enroll 2024</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <article className="webform full clearfix">
              <div className="content">
                <div
                  className="
                  field
                  field--name-body
                  field--type-text-with-summary
                  field--label-hidden
                  field__item
                "
                >
                  <p>
                    <span className="form-required"> </span> indicates required
                    information
                  </p>
                </div>

                <div
                  className="
                  field
                  field--name-webform
                  field--type-webform
                  field--label-hidden
                  field__item
                "
                >
                  <form
                    className="
                    webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                  "
                    data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
                    data-msg-required="This field is required."
                    action="/mbrx-enroll-2021"
                    method="post"
                    id="webform-submission-mbrx-enroll-2021-node-234-add-form"
                    acceptCharset="UTF-8"
                    noValidate="noValidate"
                    data-drupal-form-fields="edit-step-1,edit-step-2,edit-step-3,edit-adddrugcov-y,edit-adddrugcov-blank,edit-addcovname,edit-addcovid,edit-addgroupid,edit-instname,edit-instadd,edit-instcity,edit-inststate,edit-instzip,edit-instphone,edit-actions-wizard-prev,edit-actions-wizard-next"
                  >
                    <div
                      data-webform-key="step_4"
                      data-drupal-selector="edit-step-4"
                      className="js-form-wrapper form-wrapper form-group"
                      data-msg-required="Step 4 is required."
                      id="edit-step-4--2"
                    >
                      <div
                        id="edit-step-4-title"
                        className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                      "
                      >
                        <h1>Coordinate Benefits</h1>
                      </div>
                      <div
                        className="
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                        data-drupal-selector="edit-medicare-coordinate-benefits"
                        aria-describedby="edit-medicare-coordinate-benefits--description"
                        data-msg-required="Please answer the following questions to help Medicare coordinate your benefits is required."
                        id="edit-medicare-coordinate-benefits"
                      >
                        <div className="panel-heading">
                          <div className="panel-title collapse-link">
                            Please answer the following questions to help
                            Medicare coordinate your benefits
                          </div>
                        </div>

                        <div className="panel-body">
                          <p
                            id="edit-medicare-coordinate-benefits--description"
                            className="help-block"
                          >
                            Some individuals may have other drug coverage,
                            including other private insurance, TRICARE, federal
                            employee health benefits coverage, Veterans Affairs
                            (VA) benefits, or state pharmaceutical assistance
                            programs.
                          </p>
                          <fieldset
                            data-drupal-selector="edit-adddrugcov"
                            className="
                            radios--wrapper
                            fieldgroup
                            form-composite
                            webform-composite-visible-title
                            required
                            js-webform-type-radios
                            webform-type-radios
                            js-form-item
                            form-item
                            js-form-wrapper
                            form-wrapper
                          "
                            data-msg-required="Will you have other prescription drug coverage in addition to MedicareBlue Rx? is required."
                            id="edit-adddrugcov--wrapper"
                          >
                            <legend>
                              <span
                                className="
                                fieldset-legend
                                js-form-required
                                form-required
                              "
                              >
                                Will you have other prescription drug coverage
                                in addition to MedicareBlue Rx?
                              </span>
                            </legend>
                            <div className="fieldset-wrapper">
                              <div
                                id="edit-adddrugcov"
                                className="
                                js-webform-radios
                                webform-options-display-one-column
                              "
                              >
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-adddrugcov
                                  js-form-item-adddrugcov
                                  radio
                                "
                                >
                                  <label
                                    htmlFor="edit-adddrugcov-y"
                                    className="control-label option"
                                  >
                                    <input
                                      {...register("adddrugcov")}
                                      onChange={handleChange("adddrugcov")}
                                      checked={
                                        state.enrollData.adddrugcov === "Y"
                                      }
                                      data-drupal-selector="edit-adddrugcov-y"
                                      className="form-radio"
                                      data-msg-required="Will you have other prescription drug coverage in addition to MedicareBlue Rx? is required."
                                      type="radio"
                                      id="edit-adddrugcov-y"
                                      //name="adddrugcov"
                                      value="Y"
                                      required="required"
                                      //aria-required="true"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-adddrugcov
                                  js-form-item-adddrugcov
                                  radio
                                "
                                >
                                  <label
                                    htmlFor="edit-adddrugcov-blank"
                                    className="control-label option"
                                  >
                                    <input
                                      {...register("adddrugcov")}
                                      onChange={handleChange("adddrugcov")}
                                      checked={
                                        state.enrollData.adddrugcov === "Blank"
                                      }
                                      data-drupal-selector="edit-adddrugcov-blank"
                                      className="form-radio"
                                      data-msg-required="Will you have other prescription drug coverage in addition to MedicareBlue Rx? is required."
                                      type="radio"
                                      id="edit-adddrugcov-blank"
                                      name="adddrugcov"
                                      value="Blank"
                                      required="required"
                                      //aria-required="true"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <div
                            {...(state.enrollData.adddrugcov !== "Y" && {
                              style: { display: "none" },
                            })}
                          >
                            <div
                              className="                            
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                              data-drupal-selector="edit-other-coverage-details"
                              data-msg-required="Other coverage details is required."
                              id="edit-other-coverage-details"
                            >
                              <div className="panel-heading">
                                <div className="panel-title collapse-link">
                                  Other coverage details
                                </div>
                              </div>

                              <div className="panel-body">
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-addcovname
                                js-form-item-addcovname
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-addcovname"
                                    className="control-label"
                                  >
                                    Name of other coverage
                                  </label>

                                  <input
                                    {...register("addcovname", {
                                      required:
                                        "Name of other coverage is required.",
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                      maxLength: {
                                        value: 50,
                                        message:
                                          "Name of other coverage field has a maximum length of 50.",
                                      },
                                    })}
                                    value={state.enrollData.addcovname}
                                    onChange={handleChange("addcovname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-addcovname"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Name of other coverage field has a maximum length of 50."
                                    // data-msg-required="Name of other coverage is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-addcovname"
                                    //name="addcovname"
                                    //value=""
                                    size="60"
                                    maxLength="50"
                                  />
                                </div>
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-addcovid
                                js-form-item-addcovid
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-addcovid"
                                    className="control-label"
                                  >
                                    Member number for this coverage
                                  </label>

                                  <input
                                    {...register("addcovid", {
                                      required:
                                        "Member number for this coverage is required.",
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                      maxLength: {
                                        value: 15,
                                        message:
                                          "Member number for this coverage field has a maximum length of 15.",
                                      },
                                    })}
                                    value={state.enrollData.addcovid}
                                    onChange={handleChange("addcovid")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-addcovid"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Member number for this coverage field has a maximum length of 15."
                                    // data-msg-required="Member number for this coverage is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-addcovid"
                                    //name="addcovid"
                                    //value=""
                                    size="60"
                                    maxLength="15"
                                  />
                                </div>
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-addgroupid
                                js-form-item-addgroupid
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-addgroupid"
                                    className="control-label"
                                  >
                                    Group number for this coverage
                                  </label>

                                  <input
                                    {...register("addgroupid", {
                                      required:
                                        "Group number for this coverage is required.",
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                      maxLength: {
                                        value: 14,
                                        message:
                                          "Group number for this coverage field has a maximum length of 14.",
                                      },
                                    })}
                                    value={state.enrollData.addgroupid}
                                    onChange={handleChange("addgroupid")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-addgroupid"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Group number for this coverage field has a maximum length of 14."
                                    // data-msg-required="Group number for this coverage is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-addgroupid"
                                    //name="addgroupid"
                                    //value=""
                                    size="60"
                                    maxLength="14"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                              style={{ display: "None" }}
                              data-drupal-selector="edit-institution-details"
                              data-msg-required="Institution details is required."
                              data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022longtermcare\u0022]":{"value":"Y"}}}'
                              id="edit-institution-details"
                            >
                              <div className="panel-heading">
                                <div className="panel-title collapse-link">
                                  Institution details
                                </div>
                              </div>

                              <div className="panel-body">
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-instname
                                js-form-item-instname
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-instname"
                                    className="control-label"
                                  >
                                    Name of institution
                                  </label>

                                  <input
                                    {...register("instname")}
                                    value={state.enrollData.instname}
                                    onChange={handleChange("instname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-instname"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Name of institution field has a maximum length of 60."
                                    // data-msg-required="Name of institution is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-instname"
                                    // name="instname"
                                    // value=""
                                    size="60"
                                    maxLength="60"
                                  />
                                </div>
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-instadd
                                js-form-item-instadd
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-instadd"
                                    className="control-label"
                                  >
                                    Street address
                                  </label>

                                  <input
                                    {...register("instadd")}
                                    value={state.enrollData.instadd}
                                    onChange={handleChange("instadd")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-instadd"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Street address field has a maximum length of 25."
                                    // data-msg-required="Street address is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-instadd"
                                    // name="instadd"
                                    // value=""
                                    size="60"
                                    maxLength="25"
                                  />
                                </div>
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-instcity
                                js-form-item-instcity
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-instcity"
                                    className="control-label"
                                  >
                                    City
                                  </label>

                                  <input
                                    {...register("instcity")}
                                    value={state.enrollData.instcity}
                                    onChange={handleChange("instcity")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-instcity"
                                    className="form-text form-control"
                                    // data-msg-maxLength="City field has a maximum length of 25."
                                    // data-msg-required="City is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-instcity"
                                    // name="instcity"
                                    // value=""
                                    size="60"
                                    maxLength="25"
                                  />
                                </div>
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-select
                                js-form-type-select
                                form-item-inststate
                                js-form-item-inststate
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-inststate"
                                    className="control-label"
                                  >
                                    State
                                  </label>

                                  <div className="select-wrapper">
                                    <select
                                      {...register("inststate")}
                                      value={state.enrollData.inststate}
                                      onChange={handleChange("inststate")}
                                      data-drupal-selector="edit-inststate"
                                      className="form-select form-control"
                                      data-msg-required="State is required."
                                      id="edit-inststate"
                                      //name="inststate"
                                    >
                                      <option value="">- None -</option>
                                      <option value="AL">Alabama</option>
                                      <option value="AK">Alaska</option>
                                      <option value="AZ">Arizona</option>
                                      <option value="AR">Arkansas</option>
                                      <option value="CA">California</option>
                                      <option value="CO">Colorado</option>
                                      <option value="CT">Connecticut</option>
                                      <option value="DE">Delaware</option>
                                      <option value="DC">
                                        District of Columbia
                                      </option>
                                      <option value="FL">Florida</option>
                                      <option value="GA">Georgia</option>
                                      <option value="HI">Hawaii</option>
                                      <option value="ID">Idaho</option>
                                      <option value="IL">Illinois</option>
                                      <option value="IN">Indiana</option>
                                      <option value="IA">Iowa</option>
                                      <option value="KS">Kansas</option>
                                      <option value="KY">Kentucky</option>
                                      <option value="LA">Louisiana</option>
                                      <option value="ME">Maine</option>
                                      <option value="MD">Maryland</option>
                                      <option value="MA">Massachusetts</option>
                                      <option value="MI">Michigan</option>
                                      <option value="MN">Minnesota</option>
                                      <option value="MS">Mississippi</option>
                                      <option value="MO">Missouri</option>
                                      <option value="MT">Montana</option>
                                      <option value="NE">Nebraska</option>
                                      <option value="NV">Nevada</option>
                                      <option value="NH">New Hampshire</option>
                                      <option value="NJ">New Jersey</option>
                                      <option value="NM">New Mexico</option>
                                      <option value="NY">New York</option>
                                      <option value="NC">North Carolina</option>
                                      <option value="ND">North Dakota</option>
                                      <option value="OH">Ohio</option>
                                      <option value="OK">Oklahoma</option>
                                      <option value="OR">Oregon</option>
                                      <option value="PA">Pennsylvania</option>
                                      <option value="RI">Rhode Island</option>
                                      <option value="SC">South Carolina</option>
                                      <option value="SD">South Dakota</option>
                                      <option value="TN">Tennessee</option>
                                      <option value="TX">Texas</option>
                                      <option value="UT">Utah</option>
                                      <option value="VT">Vermont</option>
                                      <option value="VA">Virginia</option>
                                      <option value="WV">Washington</option>
                                      <option value="WI">West Virginia</option>
                                      <option value="WY">Wisconsin</option>
                                      <option value="Wyoming">Wyoming</option>
                                    </select>
                                  </div>
                                </div>
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-instzip
                                js-form-item-instzip
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-instzip"
                                    className="control-label"
                                  >
                                    Zip code
                                  </label>

                                  <input
                                    {...register("instzip")}
                                    value={state.enrollData.instzip}
                                    onChange={handleChange("instzip")}
                                    data-drupal-selector="edit-instzip"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Zip code field has a maximum length of 5."
                                    // data-msg-required="Zip code is required."
                                    type="text"
                                    id="edit-instzip"
                                    // name="instzip"
                                    // value=""
                                    size="60"
                                    maxLength="5"
                                  />
                                </div>
                                <div
                                  className="
                                form-item
                                js-form-item
                                form-type-textfield
                                js-form-type-textfield
                                form-item-instphone
                                js-form-item-instphone
                                form-group
                              "
                                >
                                  <label
                                    htmlFor="edit-instphone"
                                    className="control-label"
                                  >
                                    Phone number
                                  </label>

                                  <input
                                    {...register("instphone")}
                                    value={state.enrollData.instphone}
                                    onChange={handleChange("instphone")}
                                    data-inputmask-mask="(999) 999-9999"
                                    className="
                                  js-webform-input-mask
                                  form-text form-control
                                "
                                    pattern="^\(\d\d\d\) \d\d\d-\d\d\d\d$"
                                    data-drupal-selector="edit-instphone"
                                    // data-msg-maxLength="Phone number field has a maximum length of 255."
                                    // data-msg-required="Phone number is required."
                                    // data-msg-pattern="Phone number does not meet the requirements."
                                    type="text"
                                    id="edit-instphone"
                                    // name="instphone"
                                    // value=""
                                    size="60"
                                    maxLength="255"
                                    inputMode="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-drupal-selector="edit-actions"
                      className="
                      form-actions
                      webform-actions
                      form-group
                      js-form-wrapper
                      form-wrapper
                    "
                      data-msg-required="Submit button(s) is required."
                      id="edit-actions"
                    >
                      {/* <button
                        formNoValidate="formNoValidate"
                        className="
                        webform-button--previous
                        button
                        js-form-submit
                        form-submit
                        btn-default btn
                      "
                        data-drupal-selector="edit-actions-wizard-prev"
                        data-msg-required="This field is required."
                        type="submit"
                        id="edit-actions-wizard-prev"
                        name="op"
                        value="Previous Page"
                      >
                        Previous Page
                      </button>
                      <button
                        className="
                        webform-button--next
                        button
                        js-form-submit
                        form-submit
                        btn-default btn
                      "
                        data-drupal-selector="edit-actions-wizard-next"
                        data-msg-required="This field is required."
                        type="submit"
                        id="edit-actions-wizard-next"
                        name="op"
                        value="Continue to Step 5"
                      >
                        Continue to Step 5
                      </button> */}

                      <Button
                        className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                        onClick={handlePrevious}
                        //disabled={p1}
                        {...(p1 && {
                          style: { display: "none" },
                        })}
                      >
                        Previous
                      </Button>

                      <Button
                        className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                        onClick={handleNextClick}
                        disabled={activeStep === 5 && !acceptTerms && isValid}
                      >
                        {activeStep === steps.length - 1
                          ? "Finish"
                          : activeStep === steps.length - 2
                          ? "Submit"
                          : "Continue to " + steps[activeStep + 1].toString()}
                      </Button>
                      <div
                        className="error"
                        {...(displayCheckOptionError !== true && {
                          style: { display: "none" },
                        })}
                      >
                        At least one item must be selected.
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Step4_2024;
