import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PlanTools from "../Common/PlanTools";

const PharmacyInfo = () => {
  const [content, isLoading] = useContent("1DoywUM2BUk2cCVBrRPtn3");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/Members"> Members </Link>
                        </li>
                        <li>Pharmacy information</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content.headline}
              />
            </h1>

            <article className="page full clearfix">
              <div className="content">
                <div>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.copy}
                  />
                </div>
              </div>
            </article>
          </div>
        </section>

        <aside
          className="col-sm-4 col-md-3 side-section order-md-1"
          role="complementary"
        >
          <div className="region region-sidebar-second">
            {/* <ul className="menu menu--main nav navbar-nav">
              <li className="active active-trail first med-pharmacy-information">
                <Link
                  to="/Members/PharmacyInfo"
                  className="active-trail is-active"
                >
                  Pharmacy information
                </Link>
              </li>
              <li className="med-drug-information">
                <Link to="/Members/drug-information">Drug information</Link>
              </li>
              <li className="med-claims-history">
                <Link to="/Members/DrugClaims">Claims history</Link>
              </li>
              <li className="med-coverage-determinations,-appeals,-grievances">
                <Link to="/Members/coverage-determinations">
                  Coverage determinations, appeals, grievances
                </Link>
              </li>
              <li className="med-order-member-id-card">
                <Link to="/Members/OrderMemberIdCard">
                  Order member ID card
                </Link>
              </li>
              <li className="med-medication-therapy-management-program">
                <Link to="/Members/medication-therapy-management-program">
                  Medication therapy management program
                </Link>
              </li>
              <li className="med-payment-information">
                <Link to="/Members/PaymentInfo">Payment information</Link>
              </li>
              <li className="last med-member-discounts">
                <Link to="/Members/MemberDiscounts">Member discounts</Link>
              </li>
            </ul> */}

            <section
              id="block-homecontact-2"
              className="contact-gradient p-5 mt-5 text-left pull-left block block-block-content block-block-contentc84e5a3b-f998-486b-8e22-4dd0249790fd clearfix"
            >
              <PlanTools />
            </section>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default PharmacyInfo;
