import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";

const FindMyPharmacyGroup2021 = () => {
  const [content, isLoading] = useContent("4vVFX3e5Z5wcI2fmaOf1Zw");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Find My Pharmacy MedicareBlue Group 2021</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown children={content.title} />
            </h1>

            <article
              data-history-node-id="232"
              role="article"
              about="/2021GroupPharmacy"
              typeof="schema:WebPage"
              className="page full clearfix"
            >
              <span
                property="schema:name"
                content="Find My Pharmacy MedicareBlue Group 2021"
                className="hidden"
              ></span>

              <div className="content">
                <div
                  property="schema:text"
                  className="field field--name-body field--type-text-with-summary field--label-hidden field__item"
                >
                  <iframe
                    id="frameTool"
                    width="100%"
                    height="800px"
                    frameborder="0"
                    src="https://www.medicareplanrx.com/jccf/clearstone_egwp_pharmacy_21.html"
                  ></iframe>
                </div>

                <div className="field field--name-field-add-accordion field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div id="accordion-232" className="form-group">
                      <h3 className="accordion-title">
                        <Link
                          id="about-pharmacy-networks"
                          to="/Pharmacy/FindMyPharmacyGroup2021#about-pharmacy-networks"
                        >
                          About pharmacy networks
                        </Link>
                      </h3>
                      <div
                        className="accordion-description"
                        data-quickedit-entity-id="paragraph/574"
                      >
                        <div className="field field--name-field-description-standard-page field--type-text-long field--label-above">
                          <p>
                            <ReactMarkdown children={content.disclaimer} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FindMyPharmacyGroup2021;
