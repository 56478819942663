import React from "react";
import { useLocation } from "react-router-dom";
import searchMap from "../../services/SearchRouteMap";
import { NavLink } from "react-router-dom";

const SearchResultsList = () => {
  const location = useLocation();

  const excludeResults = ['2021'];

  const getRouteMapEntry = (result) => {
    // console.log("Search result: " + JSON.stringify(result));
    // console.log("Entry ID: " + result.sys.id);

    let entry = searchMap.find((m) => m.entryId === result.sys.id);

    // console.log("Entry: " + entry);

    return entry === undefined ? "/" : entry.route;
  };

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <h1 className="page-header">Search Results:</h1>
            <br />
            {location.state.entries.length > 0 && (
              location.state.entries.map((r) => (
                <p
                  key={r.sys.id}
                  {...((getRouteMapEntry(r) === "/" || excludeResults.some(element => (r.fields.title ?? '').includes(element))) && {
                    style: { display: "none" },
                  })}
                >
                   <NavLink to={getRouteMapEntry(r)}>{(r.fields.title) ? r.fields.title.substr(r.fields.title.indexOf('>') + 1) : ''}</NavLink>
                </p>
              ))
            )}
            {location.state.assets.length > 0 && (
              location.state.assets.map((r) => (
                <p
                  key={r.sys.id}  
                  {...(excludeResults.some(element => (r.fields.title ?? '').includes(element)) && {
                    style: { display: "none" },
                  })}                
                >
                  {r.fields.file.contentType == 'application/pdf' &&
                    <span><img src="//images.ctfassets.net/xfdtsvzh7hmn/3YAU2BAFvUVXzne2BpwdPz/87688b4c4ca2a95636bd20d9505fbb3c/icon-pdf.png" alt="PDF Icon"></img>&nbsp;</span>
                  }
                  <a href={r.fields.file.url}>{r.fields.file.fileName}</a>
                </p>
              ))
            )} 
            {!location.state.entries.length &&
              !location.state.assets.length && 
              <p>No results found.</p>
            }
          </div>
        </section>
      </div>
    </div>
  );
};

export default SearchResultsList;
