import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const IndividualPlanDocs2021 = () => {
  const [content, isLoading] = useContent("5EU1FMmEEL9TGRhG3QpcDe");

  if (isLoading) return <p>Loading...</p>;

  // console.log(content);

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/Documents/IndividualPlanDocs2021">
                            {" "}
                            Documents{" "}
                          </Link>
                        </li>
                        <li>2021 Individual plan documents</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <p />
            <article className="page full clearfix">
              <div className="content">
                {/* begin page */}
                <div id="docspage">
                  <h1>
                    <ReactMarkdown children={content.title} />
                  </h1>

                  <ReactMarkdown children={content.bodyText} />

                  <h2>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.subhead1}
                    />
                  </h2>

                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[0].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule[0].fields
                            .descriptiveText
                        }
                      />
                      <div className="pdflist nobullet">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[0].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[1].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule[1].fields
                            .descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[1].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[2].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule[2].fields
                            .descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[2].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[3].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule[3].fields
                            .descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[3].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[4].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule[4].fields
                            .descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[4].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[5].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule[5].fields
                            .descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[5].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />

                  <h2>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.subhead2}
                    />
                  </h2>

                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule2[0].fields
                              .documentTitle
                          }
                        />
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule2[0].fields
                              .descriptiveText
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule2[0].fields.documentList
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule2[1].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule2[1].fields
                            .descriptiveText
                        }
                      />
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule2[1].fields.documentList
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  {/* <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule2[2].fields.title
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule2[2].fields.bodyText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule2[2].fields.linkName
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br /> */}

                  <h2>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.subhead3}
                    />
                  </h2>

                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[0].fields.documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.docContentModule3[0].fields.descriptiveText
                        }
                      />
                      <div className="pdflist nobullet">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[0].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[1].fields.documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.docContentModule3[1].fields.descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[1].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[2].fields.documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.docContentModule3[2].fields.descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[2].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[3].fields.documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.docContentModule3[3].fields.descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[3].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[4].fields.documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.docContentModule3[4].fields.descriptiveText
                        }
                      />
                      <div className="pdflist">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[4].fields.documentList
                          }
                        />
                      </div>
                    </li>
                  </ul>
                  <br />

                  <h2>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.subhead4}
                    />
                  </h2>

                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule4[0].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule4[0].fields
                            .descriptiveText
                        }
                      />
                    </li>
                    <div className="pdflist">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule4[0].fields.documentList
                        }
                      />
                    </div>
                  </ul>
                  <br />
                  {/* <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule4[1].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule4[1].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule4[2].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule4[2].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule4[3].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule4[3].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br /> */}

                  <h2>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.subhead5}
                    />
                  </h2>

                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule5[0].fields
                              .documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule5[0].fields
                            .descriptiveText
                        }
                      />
                    </li>
                    <div className="pdflist">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule5[0].fields.documentList
                        }
                      />
                    </div>
                  </ul>
                  <br />
                  {/* <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule5[1].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule5[1].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule5[2].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule5[2].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule5[3].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentModule5[3].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br /> */}

                  <h2>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.subhead6}
                    />
                  </h2>

                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentMod6[0].fields.documentTitle
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentMod6[0].fields.descriptiveText
                        }
                      />
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentMod6[0].fields.documentList
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  {/* <ul className="nobullet">
                    <li className="pdficon">
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentMod6[1].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentMod6[1].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br />
                  <ul className="nobullet">
                    <li>
                      <strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentMod6[2].fields.linkName
                          }
                        />
                      </strong>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.documentContentMod6[2].fields.bodyText
                        }
                      />
                    </li>
                  </ul>
                  <br /> */}
                </div>
                {/* end page */}
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default IndividualPlanDocs2021;
