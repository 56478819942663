import { handleResponse, handleError } from "./apiUtils";
const url = process.env.REACT_APP_VERIFY_API_URL;
const env = process.env.REACT_APP_ENV;

export async function verifyBankAccount(acctInfo) {

  // 3/22/24 - disable verification for non-production environments
  if (env === 'dev' || env === 'stage') {
    console.log('Verification disabled for environment: ' + env);
    return true;
  }

  try {
      let response = await fetch(url, {
        mode: "cors",
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(acctInfo),
      });
      return handleResponse(response);
    } catch (error) {
      // console.log(error.)
      return handleError(error);
    }
    
}
