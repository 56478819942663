import { handleResponse, handleError, getConfirmationNum } from "./apiUtils";
const url = process.env.REACT_APP_CONTACT_API_URL;
const env = process.env.REACT_APP_ENV;

export async function submitContact(contact) {

  // Reg expression to match '123' anywhere in the string
  let patternTrigger = /123/;

  // 4/8/24 - disable API call for non-production environments
  if ((env === 'dev' || env === 'stage') && (patternTrigger.test(contact.FirstName) || patternTrigger.test(contact.LastName))) {
    console.log('API call disabled for environment: ' + env);
    contact.ConfNumber = getConfirmationNum();
    return JSON.stringify(contact);
  }

  try {
    let response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(contact),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
