import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";

const ContactEMTMHealthCarePro = () => {
  const[content, isLoading] = useContent("2USLxOYUWeLhMljiTFR8eH");

  if (isLoading) return <p>Loading...</p>;
  
  return (
    <div>
      <div className="slide__bg">
        <div className="region region-slider">
          <div
            block="block-views-block-content-views-block-1"
            className="form-group"
          >
            <div className="view view-content-views view-id-content_views view-display-id-block_1 js-view-dom-id-3b2ef65e5814eaf59d22537ab049607bd44a6df413739fe1336e4b6e8065dc14">
              <div className="view-content">
                <div>
                  <div className="banner-content container internal-banner-page">
                    <div className="col-md-6 internal-banner-section">
                      <h1>EMTM Health Care Professionals Contact Form</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <section
                id="block-clearstonebreadcrumbs"
                className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
              >
                <div className="breadcrumb-wrap">
                  <div className="region--breadcrumb nst-1">
                    <section
                      id="block-ttheme-breadcrumbs"
                      className="block-system block-system-breadcrumb-block"
                    >
                      <div role="navigation">
                        <ol className="breadcrumb">
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            Contact Form for EMTM Health Care Professionals Only
                          </li>
                        </ol>
                      </div>
                    </section>
                  </div>
                </div>
              </section>

              <article
                data-history-node-id="182"
                role="article"
                about="http://int.yourmedicaresolutions.com/contact-form-emtm-health-care-professionals-only"
                className="webform full clearfix"
              >
                <div className="content">
                  <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>
                      Health care professionals use this form to contact the
                      EMTM program at MedicareBlue Rx. All others use the
                      <Link to="/Contact/MedicareBlueRx">
                        customer service contact form.
                      </Link>
                    </p>
                  </div>

                  <div className="field field--name-webform field--type-webform field--label-hidden field__item">
                    <form
                      className="webform-submission-form webform-submission-add-form webform-submission-contact-form-for-emtm-health-car-form webform-submission-contact-form-for-emtm-health-car-add-form webform-submission-contact-form-for-emtm-health-car-node-182-form webform-submission-contact-form-for-emtm-health-car-node-182-add-form js-webform-details-toggle webform-details-toggle"
                      action="contact-form-emtm-health-care-professionals-only.html"
                      method="post"
                      id="webform-submission-contact-form-for-emtm-health-car-node-182-add-form"
                      accept-charset="UTF-8"
                    >
                      <div className="form-item js-form-item form-type-textfield js-form-type-textfield form-item-first-name js-form-item-first-name form-group">
                        <label
                          for="edit-first-name"
                          className="control-label js-form-required form-required"
                        >
                          First name
                        </label>

                        <input
                          pattern="^[a-zA-Z0-9 ]*$"
                          data-webform-pattern-error="Special characters are not allowed."
                          className="form-text required form-control"
                          data-msg-maxlength="First name field has a maximum length of 15."
                          data-msg-pattern="Special characters are not allowed."
                          type="text"
                          id="edit-first-name"
                          name="first_name"
                          value=""
                          size="60"
                          maxlength="15"
                          required="required"
                          aria-required="true"
                        />
                      </div>
                      <div className="form-item js-form-item form-type-textfield js-form-type-textfield form-item-last-name js-form-item-last-name form-group">
                        <label
                          for="edit-last-name"
                          className="control-label js-form-required form-required"
                        >
                          Last name
                        </label>

                        <input
                          pattern="^[a-zA-Z0-9 ]*$"
                          data-webform-pattern-error="Special characters are not allowed."
                          className="form-text required form-control"
                          data-msg-maxlength="Last name field has a maximum length of 35."
                          data-msg-pattern="Special characters are not allowed."
                          type="text"
                          id="edit-last-name"
                          name="last_name"
                          value=""
                          size="60"
                          maxlength="35"
                          required="required"
                          aria-required="true"
                        />
                      </div>
                      <div className="form-item js-form-item form-type-textfield js-form-type-textfield form-item-phone js-form-item-phone form-group">
                        <label
                          for="edit-phone"
                          className="control-label js-form-required form-required"
                        >
                          Phone
                        </label>

                        <input
                          data-inputmask-mask="(999) 999-9999"
                          className="js-webform-input-mask form-text required form-control"
                          pattern="^\(\d\d\d\) \d\d\d-\d\d\d\d$"
                          data-msg-maxlength="Phone field has a maximum length of 17."
                          data-msg-pattern="Phone does not meet the requirements."
                          type="text"
                          id="edit-phone"
                          name="phone"
                          value=""
                          size="60"
                          maxlength="17"
                          placeholder="(___) ___-____"
                          required="required"
                          aria-required="true"
                        />
                      </div>
                      <div className="form-item js-form-item form-type-email js-form-type-email form-item-email js-form-item-email form-group">
                        <label for="edit-email" className="control-label">
                          Email
                        </label>

                        <input
                          className="form-email form-control"
                          data-msg-email="Email does not contain a valid email."
                          data-msg-maxlength="Email field has a maximum length of 70."
                          type="email"
                          id="edit-email"
                          name="email"
                          value=""
                          size="60"
                          maxlength="70"
                        />
                      </div>
                      <div
                        className="form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                        id="edit-contact-method"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">
                            Preferred contact method
                          </div>
                        </div>

                        <div className="panel-body">
                          <fieldset
                            className="radios--wrapper fieldgroup form-composite webform-composite-hidden-title js-webform-type-radios webform-type-radios js-form-item form-item js-form-wrapper form-wrapper"
                            id="edit-preferred-contact-method--wrapper"
                          >
                            <legend>
                              <span className="visually-hidden fieldset-legend">
                                Preferred contact method
                              </span>
                            </legend>
                            <div className="fieldset-wrapper">
                              <div
                                id="edit-preferred-contact-method"
                                className="js-webform-radios webform-options-display-one-column"
                              >
                                <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-preferred-contact-method js-form-item-preferred-contact-method radio">
                                  <label
                                    for="edit-preferred-contact-method-phone"
                                    className="control-label option"
                                  >
                                    <input
                                      className="form-radio"
                                      type="radio"
                                      id="edit-preferred-contact-method-phone"
                                      name="preferred_contact_method"
                                      value="phone"
                                    />
                                    Phone
                                  </label>
                                </div>
                                <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-preferred-contact-method js-form-item-preferred-contact-method radio">
                                  <label
                                    for="edit-preferred-contact-method-email"
                                    className="control-label option"
                                  >
                                    <input
                                      className="form-radio"
                                      type="radio"
                                      id="edit-preferred-contact-method-email"
                                      name="preferred_contact_method"
                                      value="email"
                                    />
                                    Email
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className="form-item js-form-item form-type-textfield js-form-type-textfield form-item-subject js-form-item-subject form-group">
                        <label
                          for="edit-subject"
                          className="control-label js-form-required form-required"
                        >
                          Subject
                        </label>

                        <input
                          pattern="^[a-zA-Z0-9 ]*$"
                          data-webform-pattern-error="Special characters are not allowed."
                          className="form-text required form-control"
                          data-msg-maxlength="Subject field has a maximum length of 35."
                          data-msg-pattern="Special characters are not allowed."
                          type="text"
                          id="edit-subject"
                          name="subject"
                          value=""
                          size="60"
                          maxlength="35"
                          required="required"
                          aria-required="true"
                        />
                      </div>
                      <div className="form-item js-form-item form-type-textarea js-form-type-textarea form-item-message js-form-item-message form-group">
                        <label for="edit-message" className="control-label">
                          Message
                        </label>

                        <div className="form-textarea-wrapper">
                          <textarea
                            className="form-textarea form-control resize-vertical"
                            id="edit-message"
                            name="message"
                            rows="5"
                            cols="60"
                          ></textarea>
                        </div>
                      </div>
                      <input type="hidden" name="timestamp" value="14:27:16" />
                      <input
                        type="hidden"
                        name="conf_number"
                        value="no_confirmation"
                      />
                      <div
                        className="form-actions webform-actions form-group js-form-wrapper form-wrapper"
                        id="edit-actions"
                      >
                        <button
                          className="webform-button--submit button button--primary js-form-submit form-submit btn-primary btn"
                          type="submit"
                          id="edit-actions-submit"
                          name="op"
                          value="Submit"
                        >
                          Submit
                        </button>
                      </div>
                      <input
                        autocomplete="off"
                        type="hidden"
                        name="form_build_id"
                        value="form-DOJvDPQhI5CMDDzLeROsvKehI8rxHEIDMYOT8ZtHZ6U"
                      />
                      <input
                        type="hidden"
                        name="form_id"
                        value="webform_submission_contact_form_for_emtm_health_car_node_182_add_form"
                      />
                    </form>
                  </div>

                  <div className="field field--name-field-header-image field--type-image field--label-hidden field__item"></div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ContactEMTMHealthCarePro;
