import React, { useState } from "react";
import { Link } from "react-router-dom";
import bankInfo from "../../assets/files/inline-images/bank-info.png";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { Button } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import CSPhoneInput from "../Common/CSPhoneInput";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../state/actions/updateEnrollData";
import { verifyBankAccount } from "../../api/verificationApi";
import updateValidationData from "../../state/actions/updateValidationData";

const ChangePaymentOption = ({
  handleChange,
  handleNext,
  activeStep,
  steps,
  planType,
  setPlanType,
  //disableEFTOption,
  //setDisableEFTOption,
  //displayBankValidationMessage,
  //setDisplayBankValidationMessage,
  //counter,
  //setCounter,
}) => {
  const {
    register,
    formState: { errors, isValid },
    trigger,
    getValues,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const { state, actions } = useStateMachine({ updateEnrollData, updateValidationData });

  const handlePlanType = (e) => {
    actions.updateEnrollData({ paymentoption: "" });
    actions.updateEnrollData({ paymentOption: "" });
    let s = e.target.value;
    if (s === "group") {
      actions.updateEnrollData({ paymentoption: "EFT" });
      actions.updateEnrollData({ paymentOption: "EFT" });
    }

    console.log("Plan Type: " + s);
    setPlanType(s);
  };

  console.log("Plan Type: " + planType);
  console.log("Pymt Opt: " + state.enrollData.paymentOption);

  const [paymentOption, setPaymentOption] = useState("");

  const handlePaymentOption = (e) => {
    let s = e.target.value;
    actions.updateEnrollData({ paymentoption: s });
    actions.updateEnrollData({ paymentOption: s });
    setPaymentOption(s);

    if (s !== "EFT") {
      clearBankingInfo();
    }
  };

  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);
  const [displayPaymentOptionError, setDisplayPaymentOptionError] =
    useState(false);
  const [displayAccTypeError, setDisplayAccTypeError] = useState(false);

  const handleNextClick = async () => {
    const isValidForm = await trigger(); 


    let checkSetDisplayCheckOptionError = planType === "";
    setDisplayCheckOptionError(checkSetDisplayCheckOptionError);

    let checkSetDisplayPaymentOptionError = planType != "" && state.enrollData.paymentOption === "";
    setDisplayPaymentOptionError(checkSetDisplayPaymentOptionError);

    let checkDisplayAccTypeError = state.enrollData.paymentOption === "EFT" && state.enrollData.accttype === "";
    setDisplayAccTypeError(checkDisplayAccTypeError);
      
    if (
      !checkSetDisplayCheckOptionError &&
      !checkSetDisplayPaymentOptionError &&
      checkDisplayAccTypeError === false &&
      isValidForm
    ) {

      let verified = await verifyAccount();

      console.log('verified: ' + verified);

      if (verified) {
        console.log("Proceed to next screen...");
        handleNext();
      }
    }
  };

  //Account Verification

  const verifyAccount = async () => {
    let result = true;

    //setDisplayBankValidationMessage(false);
    actions.updateValidationData({displayBankValidationMessage : false});

    if (state.enrollData.paymentOption === "EFT") {
      
      result = false;

      // await setCounter(counter + 1);
      actions.updateValidationData({ validationCounter: state.validationData.validationCounter + 1})
      console.log('state.validationData.validationCounter increased to: ' + state.validationData.validationCounter);

      let b = {
        UniqueId: "",
        ServiceFlags: ["Verify"],
        BankAccountEntity: {
          RoutingNumber: state.enrollData.bankroutingnumber,
          AccountNumber: state.enrollData.bankacctnumber,
        },
      };

      console.log(b);
      
      try {
        result = await verifyBankAccount(b);
      }
      catch (error) {
        console.log('verifyBankAccoun error: ' + error)      
      }

      console.log("Verified: " + result + ", validationCounter: " + state.validationData.validationCounter);

      if (result === false) {
        if (state.validationData.validationCounter >= 3) {
          //setDisableEFTOption(true);
          actions.updateValidationData({disableEFTOption : true});

          //setDisplayBankValidationMessage(false);
          actions.updateValidationData({displayBankValidationMessage : false});

          clearBankingInfo();
        } else {
          //setDisplayBankValidationMessage(true);
          actions.updateValidationData({displayBankValidationMessage : true});
        }
        console.log('result: ' + result);  
      }
    }
    return result;
  };

  const clearBankingInfo = () => {
    actions.updateEnrollData({
      bankroutingnumber: "",
      confirm_bankroutingnumber: "",
      bankacctnumber: "",
      confirm_bankacctnumber: "",
      accountholdername: "",
      bankname: "",
      accttype: "",
    });
  };

  return (
    <div className="dialog-off-canvas-main-canvas">
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <article className="webform full clearfix">
                <div className="content">
                  <div className="field field--name-webform field--type-webform field--label-hidden field__item">
                    <form
                      className="webform-submission-form webform-submission-add-form webform-submission-mbrx-change-payment-option-form webform-submission-mbrx-change-payment-option-add-form webform-submission-mbrx-change-payment-option-node-167-form webform-submission-mbrx-change-payment-option-node-167-add-form js-webform-disable-autosubmit js-webform-details-toggle webform-details-toggle"
                      action="change-payment-option.html"
                      method="post"
                      id="webform-submission-mbrx-change-payment-option-node-167-add-form"
                      acceptCharset="UTF-8"
                    >
                      <div
                        data-webform-key="step_1_page"
                        className="js-form-wrapper form-wrapper form-group"
                        id="edit-step-1-page"
                      >
                        <div
                          id="edit-step-1-title"
                          className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                        >
                          <h1>Sign up for automatic premium payments</h1>

                          <p>
                          Never worry about having stamps or late payments when you set up automatic payments for your monthly premium. Choose an option that fits your needs and enroll online today. It may take up to two months to process your request.
                          </p>
                        </div>
                        <div
                          className="form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-personal-information"
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Provide your member information
                            </div>
                          </div>

                          <div className="panel-body">
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-row-1"
                            >
                              <div
                                className="
                                  col-sm-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-firstname
                                  js-form-item-firstname
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-firstname"
                                  className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                >
                                  First name
                                </label>

                                <input
                                  {...register("firstname", {
                                    required: "First name is required.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "First name field has a maximum length of 25.",
                                    },
                                    pattern: {
                                      value: /^[-a-zA-Z0-9 ]*$/,
                                      message:
                                        "Special characters are not allowed.",
                                    },
                                    validate: (value) => {
                                      return (
                                        !!value.trim() ||
                                        "First name is required."
                                      );
                                    },
                                  })}
                                  value={state.enrollData.firstname}
                                  onChange={handleChange("firstname")}
                                  pattern="^[-a-zA-Z0-9 ]*$"
                                  className="form-text required form-control"
                                  data-msg-maxlength="First name field has a maximum length of 25."
                                  data-msg-required="First name is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-firstname"
                                  size="60"
                                  maxLength="25"
                                  required="required"
                                  aria-required="true"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="firstname"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>

                              <div
                                className="
                                  col-sm-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-lastname
                                  js-form-item-lastname
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-lastname"
                                  className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                >
                                  Last name
                                </label>

                                <input
                                  {...register("lastname", {
                                    required: "Last name is required.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "Last name field has a maximum length of 25.",
                                    },
                                    pattern: {
                                      value: /^[-a-zA-Z0-9 ]*$/,
                                      message:
                                        "Special characters are not allowed.",
                                    },
                                    validate: (value) => {
                                      return (
                                        !!value.trim() ||
                                        "Last name is required."
                                      );
                                    },
                                  })}
                                  value={state.enrollData.lastname}
                                  onChange={handleChange("lastname")}
                                  pattern="^[-a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-lastname"
                                  className="form-text required form-control"
                                  data-msg-maxlength="Last name field has a maximum length of 25."
                                  data-msg-required="Last name is required."
                                  data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-lastname"
                                  size="60"
                                  maxLength="25"
                                  required="required"
                                  aria-required="true"
                                />
                                <div className="error">
                                  <ErrorMessage errors={errors} name="lastname">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-row-2"
                            >
                              {/* <div
                                className="
                                  col-sm-3 col-xs-12
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-dob
                                  js-form-item-dob
                                  form-group
                                "
                              >
                                {/* <label
                                  htmlFor="edit-dob"
                                  className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                >
                                  Birthdate
                                </label> */}
                              {/* <div className="input-group">
                                  <span className="input-group-addon">
                                    <div className="date_wrap_div">
                                      <DatePicker
                                        name="dob"
                                        onChange={handleBirthDate}
                                        onFocus={(e) => e.target.blur()}
                                        selected={birthDate}
                                        control={control}
                                        rules={{
                                          required: true,
                                        }}
                                        className="form-text
                                          required
                                          form-control hasDatepicker"
                                        placeholderText="DD/MM/YYYY"
                                        openToDate={d.setYear(
                                          d.getFullYear() - 65
                                        )}
                                        autoComplete="off"
                                      />
                                      <span className="input-group-addon"></span>
                                    </div>
                                  </span>
                                </div> */}
                              {/* <br />
                                <div
                                  className="error"
                                  {...(dobError === false && {
                                    style: { display: "none" },
                                  })}
                                >
                                  Birthdate required.
                                </div>
                                <div className="error">
                                  <ErrorMessage errors={errors} name="dob">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div> 
                              </div> */}
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-memberidnumber js-form-item-memberidnumber form-group">
                                <label
                                  htmlFor="edit-memberidnumber"
                                  className="control-label js-form-required form-required"
                                >
                                  Member ID
                                </label>

                                <input
                                  {...register("MemberIDNumber", {
                                    required:
                                      "MedicareBlue Rx member number (from your plan ID card) is required.",
                                    minLength: {
                                      value: 9,
                                      message: "Please enter 9 characters.",
                                    },
                                    maxLength: {
                                      value: 9,
                                      message:
                                        "MedicareBlue Rx member number (from your plan ID card) field has a maximum length of 9.",
                                    },
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message: "Valid Member ID is required",
                                    },
                                    // validate: () =>
                                    //    console.log("field validated"),
                                  })}
                                  value={state.enrollData.memberidnumber}
                                  onChange={handleChange("memberidnumber")}
                                  className="form-text required form-control"
                                  type="text"
                                  id="edit-memberidnumber"
                                  size="60"
                                  maxLength="9"
                                  required="required"
                                  aria-required="true"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="MemberIDNumber"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div
                                className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-homephone
                                  js-form-item-homephone
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-homephone"
                                  className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                >
                                  Phone number
                                </label>
                                <br />
                                <CSPhoneInput
                                  name="homephone"
                                  id="edit-homephone"
                                  value={state.enrollData.homephone}
                                  onChange={handleChange("homephone")}
                                  control={control}
                                  rules={{
                                    required: "Home phone number required.",
                                  }}
                                />
                                {/* <PhoneInput
                                  name="homephone"
                                  value={state.enrollData.homephone}
                                  onChange={handleHomePhone}
                                  country="US"
                                  id="homephone"
                                  control={control}
                                  rules={{
                                    required: "Home phone number required.",
                                    maxLength: {
                                      value: 14,
                                    },
                                  }}
                                  defaultValue={state.enrollData.homephone}
                                  maxLength="14"
                                  className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-homephone
                                    js-form-item-homephone
                                    form-group"
                                  style={{ border: "1px solid #ccc" }}
                                /> */}
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="homephone"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-email-row"
                            >
                              <div
                                className="
                                  col-md-6 col-sm-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-email
                                  js-form-item-email
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-email"
                                  className="control-label"
                                >
                                  Email address
                                </label>

                                <input
                                  {...register("email", {
                                    pattern: {
                                      value:
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message:
                                        "Please enter a valid email address.",
                                    },
                                  })}
                                  value={state.enrollData.email}
                                  onChange={handleChange("email")}
                                  data-drupal-selector="edit-email"
                                  className="form-text form-control"
                                  data-msg-maxlength="Email address field has a maximum length of 255."
                                  data-msg-required="Email address is required."
                                  type="email"
                                  id="edit-email"
                                  size="60"
                                  maxLength="255"
                                />
                                <div className="error">
                                  <ErrorMessage errors={errors} name="email">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div
                                className="
                                  col-md-6 col-sm-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-confirm-email
                                  js-form-item-confirm-email
                                  form-group
                                "
                              >
                                <label
                                  htmlFor="edit-confirm-email"
                                  className="control-label"
                                >
                                  Confirm email address
                                </label>

                                <input
                                  {...register("confirm_email", {
                                    pattern: {
                                      value:
                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message:
                                        "Please enter a valid email address.",
                                    },
                                    validate: () =>
                                      getValues("confirm_email") ===
                                      getValues("email"),
                                  })}
                                  value={state.enrollData.confirm_email}
                                  onChange={handleChange("confirm_email")}
                                  data-drupal-selector="edit-confirm-email"
                                  className="form-text form-control"
                                  data-msg-maxlength="Confirm email address field has a maximum length of 255."
                                  data-msg-required="Confirm email address is required."
                                  type="text"
                                  id="edit-confirm-email"
                                  size="60"
                                  maxLength="255"
                                />
                                {errors.confirm_email &&
                                  errors.confirm_email.type === "validate" && (
                                    <div className="error">
                                      The email addresses do not match.
                                    </div>
                                  )}
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="confirm_email"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-individual-group"
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Choose your plan type to get started
                            </div>
                          </div>

                          <div className="panel-body">
                            <fieldset
                              className="radios--wrapper fieldgroup form-composite webform-composite-hidden-title required js-webform-type-radios webform-type-radios js-form-item form-item js-form-wrapper form-wrapper"
                              id="edit-plantype--wrapper"
                            >
                              <legend>
                                <span className="visually-hidden fieldset-legend js-form-required form-required">
                                  individual or group
                                </span>
                              </legend>
                              <div className="fieldset-wrapper">
                                <div
                                  id="edit-plantype"
                                  className="js-webform-radios webform-options-display-one-column"
                                >
                                  <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-plantype js-form-item-plantype radio">
                                    <label
                                      htmlFor="edit-plantype-individual"
                                      className="control-label option"
                                    >
                                      <input
                                        onChange={handlePlanType}
                                        checked={planType === "individual"}
                                        className="form-radio"
                                        type="radio"
                                        id="edit-plantype-individual"
                                        name="plantype"
                                        value="individual"
                                      />
                                      Individual plan member
                                    </label>
                                  </div>
                                  <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-plantype js-form-item-plantype radio">
                                    <label
                                      htmlFor="edit-plantype-group"
                                      className="control-label option"
                                    >
                                      <input
                                        onChange={handlePlanType}
                                        checked={planType === "group"}
                                        className="form-radio"
                                        type="radio"
                                        id="edit-plantype-group"
                                        name="plantype"
                                        value="group"
                                      />
                                      Employer group plan member
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div
                          className="error"
                          {...(displayCheckOptionError !== true && {
                            style: { display: "none" },
                          })}
                        >
                          At least one item must be selected.
                        </div>
                        <div
                          className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          aria-describedby="edit-select-payment-options--description"
                          id="edit-select-payment-options"
                          {...(planType !== "individual" && {
                            style: { display: "none" },
                          })}
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Choose your preferred payment method
                            </div>
                          </div>

                          <div className="panel-body">
                            <p
                              id="edit-select-payment-options--description"
                              className="help-block"
                            >
                              There are three options available that can help
                              protect you from late payments.
                            </p>
                            <div
                              className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-paymentoption
                                      js-form-item-paymentoption
                                      radio
                                    "
                            >
                              <label
                                htmlFor="edit-paymentoption-eft"
                                className="control-label option"
                              >
                                <input
                                  onChange={handlePaymentOption}
                                  checked={
                                    state.enrollData.paymentOption === "EFT"
                                  }
                                  data-drupal-selector="edit-paymentoption-eft"
                                  className="form-radio"
                                  data-msg-required="Preferred payment method is required."
                                  type="radio"
                                  id="edit-paymentoption-eft"
                                  name="paymentoption"
                                  value="EFT"
                                  required="required"
                                  //aria-required="true"
                                />
                                Electronic funds transfer (EFT)
                              </label>
                            </div>
                            <div
                              className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-paymentoption
                                      js-form-item-paymentoption
                                      radio
                                    "
                            >
                              <label
                                htmlFor="edit-paymentoption-ssa"
                                className="control-label option"
                              >
                                <input
                                  onChange={handlePaymentOption}
                                  checked={
                                    state.enrollData.paymentOption === "SSA"
                                  }
                                  data-drupal-selector="edit-paymentoption-ssa"
                                  className="form-radio"
                                  data-msg-required="Preferred payment method is required."
                                  type="radio"
                                  id="edit-paymentoption-ssa"
                                  name="paymentoption"
                                  value="SSA"
                                  required="required"
                                  //aria-required="true"
                                />
                                Automatic deduction from your Social Security
                                benefit check
                              </label>
                            </div>
                            <div
                              className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-paymentoption
                                      js-form-item-paymentoption
                                      radio
                                    "
                            >
                              <label
                                htmlFor="edit-paymentoption-rrb"
                                className="control-label option"
                              >
                                <input
                                  onChange={handlePaymentOption}
                                  checked={
                                    state.enrollData.paymentOption === "RRB"
                                  }
                                  data-drupal-selector="edit-paymentoption-rrb"
                                  className="form-radio"
                                  data-msg-required="Preferred payment method is required."
                                  type="radio"
                                  id="edit-paymentoption-rrb"
                                  name="paymentoption"
                                  value="RRB"
                                  required="required"
                                  //aria-required="true"
                                />
                                Automatic deduction from your Railroad
                                Retirement Board (RRB) benefit check
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="error"
                          {...(displayPaymentOptionError !== true && {
                            style: { display: "none" },
                          })}
                        >
                          At least one item must be selected.
                        </div>

                        <div
                          className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          aria-describedby="edit-eft-info--description"
                          id="edit-eft-info"
                          {...(planType === "none" && {
                            style: { display: "none" },
                          })}
                        >
                          <div
                            className="
                            js-webform-states-hidden
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-eft-info"
                            aria-describedby="edit-eft-info--description"
                            data-msg-required="Electronic funds transfer (EFT) from your bank account each month (Required *) is required."
                            data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022paymentoption\u0022]":{"value":"EFT"}}}'
                            id="edit-eft-info"
                            {...(state.enrollData.paymentOption !== "EFT" && {
                              style: { display: "none" },
                            })}
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Electronic funds transfer (EFT) from your bank
                                account each month (Required *)
                              </div>
                            </div>

                            <div className="panel-body">
                              <p
                                id="edit-eft-info--description"
                                className="help-block"
                              >
                                EFT is a secure process that allows MedicareBlue Rx to automatically withdraw funds from your checking or savings account to pay your monthly premium.
                                <br />
                                <br />
                                Please pay your premiums billed to you on paper until your EFT is active. Any unpaid premiums due when EFT takes effect will be deducted at that time to bring your account up-to-date.
                              </p>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-acc-num"
                                data-msg-required="This field is required."
                                id="edit-row-acc-num"
                              >
                                <div
                                  className="
                                  col-md-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-accountholdername
                                  js-form-item-accountholdername
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-accountholdername"
                                    className="control-label form-required"
                                  >
                                    Account holder name
                                  </label>

                                  <input
                                    {...register("accountholdername", {
                                      validate: {
                                        required: (value) => {
                                          if (!value && state.enrollData.paymentOption === "EFT")
                                            return "Account holder name is required.";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 90,
                                        message:
                                          "Account holder name field has a maximum length of 90.",
                                      },
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.accountholdername}
                                    onChange={handleChange("accountholdername")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-accountholdername"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Account holder name field has a maximum length of 90."
                                    // data-msg-required="Account holder name is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-accountholdername"
                                    //name="accountholdername"
                                    //value=""
                                    size="60"
                                    maxLength="90"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="accountholdername"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-fin-ins"
                                data-msg-required="This field is required."
                                id="edit-row-fin-ins"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bankname
                                  js-form-item-bankname
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bankname"
                                    className="control-label form-required"
                                  >
                                    Financial institution
                                  </label>

                                  <input
                                    {...register("bankname", {
                                      validate: {
                                        required: (value) => {
                                          if (!value && state.enrollData.paymentOption === "EFT")
                                            return "Financial institution name is required.";
                                          return true;
                                        },
                                      },
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.bankname}
                                    onChange={handleChange("bankname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-bankname"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Financial institution field has a maximum length of 50."
                                    // data-msg-required="Financial institution is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-bankname"
                                    //name="bankname"
                                    //value=""
                                    size="60"
                                    maxLength="50"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="bankname"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <fieldset
                                  data-drupal-selector="edit-accttype"
                                  className="
                                  col-sm-6 col-md-6
                                  radios--wrapper
                                  fieldgroup
                                  form-composite
                                  webform-composite-visible-title
                                  js-webform-type-radios
                                  webform-type-radios
                                  js-form-item
                                  form-item
                                  js-form-wrapper
                                  form-wrapper
                                "
                                  data-msg-required="Account type is required."
                                  id="edit-accttype--wrapper"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022paymentoption\u0022]":{"value":"EFT"}}}'
                                >
                                  <legend>
                                    <span className="fieldset-legend form-required">
                                      Account type
                                    </span>
                                  </legend>
                                  <div className="fieldset-wrapper">
                                    <div
                                      id="edit-accttype"
                                      className="
                                      js-webform-radios
                                      webform-options-display-one-column
                                    "
                                    >
                                      <div
                                        className="
                                        form-item
                                        js-form-item
                                        form-type-radio
                                        js-form-type-radio
                                        form-item-accttype
                                        js-form-item-accttype
                                        radio
                                      "
                                      >
                                        <label
                                          htmlFor="edit-accttype-checking"
                                          className="control-label option"
                                        >
                                          <input
                                            {...register("acctype", {
                                              // validate: {
                                              //   required: () => {
                                              //     if (
                                              //       state.enrollData
                                              //         .accttype === "" &&
                                              //       paymentOption === "EFT"
                                              //     )
                                              //       return "Please select any account type.";
                                              //     return true;
                                              //   },
                                              // },
                                            })}
                                            onChange={handleChange("accttype")}
                                            checked={
                                              state.enrollData.accttype ===
                                              "Checking"
                                            }
                                            data-drupal-selector="edit-accttype-checking"
                                            className="form-radio"
                                            data-msg-required="Account type is required."
                                            type="radio"
                                            id="edit-accttype-checking"
                                            value="Checking"
                                            disabled={state.validationData.disableEFTOption}
                                          />
                                          Checking
                                        </label>
                                      </div>
                                      <div
                                        className="
                                        form-item
                                        js-form-item
                                        form-type-radio
                                        js-form-type-radio
                                        form-item-accttype
                                        js-form-item-accttype
                                        radio
                                      "
                                      >
                                        <label
                                          htmlFor="edit-accttype-savings"
                                          className="control-label option"
                                        >
                                          <input
                                            {...register("accttype", {
                                              // validate: {
                                              //   required: () => {
                                              //     if (
                                              //       state.enrollData
                                              //         .accttype === "" &&
                                              //       paymentOption === "EFT"
                                              //     )
                                              //       return "Please select any account type.";
                                              //     return true;
                                              //   },
                                              // },
                                            })}
                                            onChange={handleChange("accttype")}
                                            checked={
                                              state.enrollData.accttype ===
                                              "Savings"
                                            }
                                            data-drupal-selector="edit-accttype-savings"
                                            className="form-radio"
                                            data-msg-required="Account type is required."
                                            type="radio"
                                            id="edit-accttype-savings"
                                            value="Savings"
                                            disabled={state.validationData.disableEFTOption}
                                          />
                                          Savings
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="error"
                                    {...(displayAccTypeError !== true && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Please select an account type.
                                  </div>
                                </fieldset>
                                <div className="error">
                                  <ErrorMessage errors={errors} name="accttype">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-route-num"
                                data-msg-required="This field is required."
                                id="edit-row-route-num"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bankroutingnumber
                                  js-form-item-bankroutingnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bankroutingnumber"
                                    className="control-label form-required"
                                  >
                                    Bank routing number
                                  </label>

                                  <input
                                    {...register("bankroutingnumber", {
                                      validate: {
                                        required: (value) => {
                                          if (!value && state.enrollData.paymentOption === "EFT")
                                            return "Bank routing number is required.";
                                          return true;
                                        },
                                      },
                                      minLength: {
                                        value: 9,
                                        message:
                                          "Please enter a 9 digit bank routing number.",
                                      },
                                      maxLength: {
                                        value: 9,
                                        message:
                                          "Bank routing number field has a maximum length of 9.",
                                      },
                                      pattern: {
                                        value: /^[-0-9 ]*$/,
                                        message:
                                          "Please enter numeric values only.",
                                      },
                                    })}
                                    value={state.enrollData.bankroutingnumber}
                                    onChange={handleChange("bankroutingnumber")}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-bankroutingnumber"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Bank routing number field has a maximum length of 9."
                                    // data-msg-required="Bank routing number is required."
                                    // data-msg-pattern="Please enter numeric values only."
                                    type="text"
                                    id="edit-bankroutingnumber"
                                    //name="bankroutingnumber"
                                    //value=""
                                    size="60"
                                    maxLength="9"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="bankroutingnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-confirm-bankroutingnumber
                                  js-form-item-confirm-bankroutingnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-confirm-bankroutingnumber"
                                    className="control-label form-required"
                                  >
                                    Confirm bank routing number
                                  </label>

                                  <input
                                    {...register("confirm_bankroutingnumber", {
                                      validate: {
                                        required: (value) => {
                                          if (!value && paymentOption === "EFT")
                                            return "Bank routing number is required.";
                                          return true;
                                        },
                                      },
                                      minLength: {
                                        value: 9,
                                        message:
                                          "Please enter a 9 digit bank routing number.",
                                      },
                                      maxLength: {
                                        value: 9,
                                        message:
                                          "Bank routing number field has a maximum length of 9.",
                                      },
                                      pattern: {
                                        value: /^[-0-9 ]*$/,
                                        message:
                                          "Please enter numeric values only.",
                                      },
                                      validate: () =>
                                        getValues("bankroutingnumber") ===
                                        getValues("confirm_bankroutingnumber"),
                                    })}
                                    value={
                                      state.enrollData.confirm_bankroutingnumber
                                    }
                                    onChange={handleChange(
                                      "confirm_bankroutingnumber"
                                    )}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-confirm-bankroutingnumber"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Confirm bank routing number field has a maximum length of 9."
                                    // data-msg-required="Confirm bank routing number is required."
                                    // data-msg-pattern="Please enter numeric values only."
                                    type="text"
                                    id="edit-confirm-bankroutingnumber"
                                    //name="confirm_bankroutingnumber"
                                    // value={values.confirm_bankroutingnumber}
                                    size="60"
                                    maxLength="9"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="confirm_bankroutingnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                  {errors.confirm_bankroutingnumber &&
                                    errors.confirm_bankroutingnumber.type ===
                                      "validate" && (
                                      <div className="error">
                                        The routing numbers do not match.
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-bank-num"
                                data-msg-required="This field is required."
                                id="edit-row-bank-num"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bankacctnumber
                                  js-form-item-bankacctnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bankacctnumber"
                                    className="control-label form-required"
                                  >
                                    Bank account number
                                  </label>

                                  <input
                                    {...register("bankacctnumber", {
                                      validate: {
                                        required: (value) => {
                                          if (!value && state.enrollData.paymentOption === "EFT")
                                            return "Bank account number is required.";
                                          return true;
                                        },
                                      },
                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 5-17 digit bank account number.",
                                      },
                                      maxLength: {
                                        value: 17,
                                        message:
                                          "Bank account number field has a maximum length of 17.",
                                      },
                                      pattern: {
                                        value: /^[-0-9 ]*$/,
                                        message:
                                          "Please enter numeric values only.",
                                      },
                                    })}
                                    value={state.enrollData.bankacctnumber}
                                    onChange={handleChange("bankacctnumber")}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-bankacctnumber"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Bank account number field has a maximum length of 17."
                                    // data-msg-required="Bank account number is required."
                                    // data-msg-pattern="Please enter numeric values only."
                                    type="text"
                                    id="edit-bankacctnumber"
                                    //name="bankacctnumber"
                                    //value=""
                                    size="60"
                                    maxLength="17"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="bankacctnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-confirm-bankacctnumber
                                  js-form-item-confirm-bankacctnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-confirm-bankacctnumber"
                                    className="control-label form-required"
                                  >
                                    Confirm bank account number
                                  </label>

                                  <input
                                    {...register("confirm_bankacctnumber", {
                                      validate: {
                                        required: (value) => {
                                          if (!value && paymentOption === "EFT")
                                            return "Bank account number is required.";
                                          return true;
                                        },
                                      },

                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 5-17 digit bank account number.",
                                      },
                                      maxLength: {
                                        value: 17,
                                        message:
                                          "Bank account number field has a maximum length of 17.",
                                      },
                                      pattern: {
                                        value: /^[-0-9 ]*$/,
                                        message:
                                          "Please enter numeric values only.",
                                      },
                                      validate: () =>
                                        getValues("bankacctnumber") ===
                                        getValues("confirm_bankacctnumber"),
                                    })}
                                    value={
                                      state.enrollData.confirm_bankacctnumber
                                    }
                                    onChange={handleChange(
                                      "confirm_bankacctnumber"
                                    )}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-confirm-bankacctnumber"
                                    className="form-text form-control"
                                    type="text"
                                    id="edit-confirm-bankacctnumber"
                                    size="60"
                                    maxLength="17"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="confirm_bankacctnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                  {errors.confirm_bankacctnumber &&
                                    errors.confirm_bankacctnumber.type ===
                                      "validate" && (
                                      <div className="error">
                                        The account numbers do not match.
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div
                                id="edit-check-image"
                                className="
                                form-item
                                js-form-item
                                form-type-processed-text
                                js-form-type-processed-text
                                form-item-
                                js-form-item-
                                form-no-label form-group
                              "
                              >
                                <p>
                                  <img
                                    alt="bank info"
                                    data-entity-type="file"
                                    data-entity-uuid="f6717008-cc45-48a0-ada3-687d6fe1cac5"
                                    src={bankInfo}
                                  />
                                </p>
                              </div>
                              {/* Add verification messaging here...*/}
                              {state.validationData.displayBankValidationMessage && (
                                <div className="error">
                                  We couldn’t validate your information. Please
                                  review your information and enter it again.
                                </div>
                              )}
                              {state.validationData.disableEFTOption && (
                                <div className="error">
                                  We couldn’t validate your information. Please
                                  check with your financial organization to
                                  verify your account details. Your current
                                  payment option will stay the same.
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                            id="edit-rrb-payment-description"
                            {...(state.enrollData.paymentOption !== "RRB" && {
                              style: { display: "none" },
                            })}
                          >
                            <h2>
                              Railroad Retirement Board (RRB) deduction option
                            </h2>

                            <p>
                              You can choose automatic deduction from your RRB
                              benefit checks to pay for your monthly premium,
                              including late enrollment penalties you may owe.
                            </p>

                            <p>
                              If the RRB does not initially approve your
                              automatic deduction request, we will send you
                              paper bills and resubmit your request.
                            </p>

                            <ul>
                              <li>
                                Once approved, it can take two or more months
                                for the deduction to begin
                              </li>
                              <li>
                                Your current payment option will remain in place
                                until this change is processed
                              </li>
                              <li>
                                If you pay your premiums by mail, please
                                continue to do so until the deduction begins
                              </li>
                              <li>
                                If you do not pay your premium for the months
                                before the deduction takes effect, you may be
                                disenrolled from the plan
                              </li>
                              <li>
                                The RRB does not allow retroactive withholding
                                requests
                              </li>
                            </ul>

                            <p>
                              If you are assessed a Part D income-related
                              monthly adjustment amount (IRMAA), the Social
                              Security Administration will notify you. You will
                              be responsible for paying this extra amount. You
                              will either have the amount withheld from your RRB
                              check or be billed directly by Medicare. Do not
                              pay MedicareBlue Rx the Part D IRMAA.
                            </p>
                          </div>
                          <div
                            className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                            id="edit-ss-payment-description"
                            {...(state.enrollData.paymentOption !== "SSA" && {
                              style: { display: "none" },
                            })}
                          >
                            <h2>Social Security deduction option</h2>

                            <p>
                              You can choose automatic deduction from your
                              Social Security benefit checks to pay for your
                              monthly premium, including late enrollment
                              penalties you may owe.
                            </p>

                            <p>
                              If the Social Security Administration does not
                              initially approve your automatic deduction
                              request, we will send you paper bills and may
                              resubmit your request.
                            </p>

                            <ul>
                              <li>
                                Once approved, it can take two or more months
                                for the deduction to begin
                              </li>
                              <li>
                                Your current payment option will remain in place
                                until this change is processed
                              </li>
                              <li>
                                If you pay your premiums by mail, please
                                continue to do so until the deduction begins
                              </li>
                              <li>
                                If you do not pay your premium for the months
                                before the deduction takes effect, you may be
                                disenrolled from the plan
                              </li>
                              <li>
                                Social Security does not allow retroactive
                                withholding requests
                              </li>
                            </ul>

                            <p>
                              If you are assessed a Part D income-related
                              monthly adjustment amount (IRMAA), the Social
                              Security Administration will notify you. You will
                              be responsible for paying this extra amount. You
                              will either have the amount withheld from your
                              Social Security check or be billed directly by
                              Medicare. Do not pay MedicareBlue Rx the Part D
                              IRMAA.
                            </p>
                          </div>
                        </div>
                        <div
                          className="js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                          id="edit-auth-rep-sig"
                          {...(planType === "none" && {
                            style: { display: "none" },
                          })}
                        >
                          <div className="panel-heading">
                            <div className="panel-title">
                              Applicant or authorized representative signature
                            </div>
                          </div>

                          <div className="panel-body">
                            <div
                              className="js-form-wrapper js-webform-states-hidden"
                              {...(state.enrollData.paymentOption !== "EFT" && {
                                style: { display: "none" },
                              })}
                            >
                              <div
                                className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                                id="edit-eft-signature-text"
                              >
                                I understand that by typing my name below:
                                <ul>
                                  <li>
                                    I authorize the bank or savings institution
                                    to make payments to MedicareBlue Rx from the
                                    account entered
                                  </li>
                                  <li>
                                    I understand that I may discontinue
                                    automatic payments at any time by calling
                                    Customer Service
                                  </li>
                                  <li>
                                    I agree to maintain sufficient funds in the
                                    account to permit EFT deductions. If payment
                                    is returned for insufficient funds or any
                                    other reason, the EFT account will be
                                    cancelled, and I will receive a paper bill
                                    for the next billing cycle. The institution
                                    will have no financial liability, except due
                                    to an error by the institution or by the
                                    plan and may charge a fee for non-sufficient
                                    funds.
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="js-form-wrapper js-webform-states-hidden"
                              {...(state.enrollData.paymentOption !== "RRB" && {
                                style: { display: "none" },
                              })}
                            >
                              <div
                                className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                                id="edit-signature-text-rrb"
                              >
                                <p>
                                  I understand by providing my electronic
                                  signature, I am authorizing MedicareBlue Rx to
                                  change my premium payment method to a Railroad
                                  Retirement Board deduction.
                                </p>
                              </div>
                            </div>
                            <div
                              className="js-form-wrapper js-webform-states-hidden"
                              {...(state.enrollData.paymentOption !== "SSA" && {
                                style: { display: "none" },
                              })}
                            >
                              <div
                                className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                                id="edit-signature-text-for-ssa"
                              >
                                <p>
                                  I understand by providing my electronic
                                  signature, I am authorizing MedicareBlue Rx to
                                  change my premium payment method to a Social
                                  Security deduction.
                                </p>
                              </div>
                            </div>
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              id="edit-row-today-date"
                            >
                              <div className="col-md-6 col-sm-6 form-inline form-item js-form-item form-type-date js-form-type-date form-item-datesigned js-form-item-datesigned form-disabled form-group">
                                <label
                                  htmlFor="edit-datesigned"
                                  className="control-label"
                                >
                                  Today's date
                                </label>

                                <input
                                  {...register("datesigned", {
                                    min: moment().format("YYYY-MM-DD"),
                                  })}
                                  onChange={handleChange("datesigned")}
                                  type="text"
                                  data-drupal-date-format="m/d/Y"
                                  data-drupal-selector="edit-datesigned"
                                  disabled="disabled"
                                  className="form-text form-control hasDatepicker"
                                  data-msg-required="Today's date is required."
                                  id="edit-datesigned"
                                  name="datesigned"
                                  //value="06/16/2021"
                                  data-drupal-states='{"disabled":{"":{"":""}}}'
                                  placeholder="MM/DD/YYYY"
                                  autoComplete="chrome-off-21176153"
                                  defaultValue={moment().format("MM/DD/YYYY")}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div
                              className="row js-webform-states-hidden form-group js-form-wrapper form-wrapper"
                              id="edit-row-8"
                            >
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-signaturefirst js-form-item-signaturefirst form-group">
                                <label
                                  htmlFor="edit-signaturefirst"
                                  className="control-label form-required"
                                >
                                  First name
                                </label>

                                <input
                                  {...register("signaturefirst", {
                                    required: "First name is required.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "First name field has a maximum length of 25.",
                                    },
                                    pattern: {
                                      value: /^[-a-zA-Z0-9 ]*$/,
                                      message:
                                        "Special characters are not allowed.",
                                    },
                                  })}
                                  value={state.enrollData.signaturefirst}
                                  onChange={handleChange("signaturefirst")}
                                  pattern="^[-a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-signaturefirst"
                                  className="form-text required form-control"
                                  // data-msg-maxLength="Enrollee first name field has a maximum length of 25."
                                  // data-msg-required="Enrollee first name is required."
                                  // data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-signaturefirst"
                                  //name="signaturefirst"
                                  //value=""
                                  size="60"
                                  maxLength="25"
                                  required="required"
                                  aria-required="true"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="signaturefirst"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-signaturelast js-form-item-signaturelast form-group">
                                <label
                                  htmlFor="edit-signaturelast"
                                  className="control-label form-required"
                                >
                                  Last name
                                </label>

                                <input
                                  {...register("signaturelast", {
                                    required: "Last name is required.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "Last name field has a maximum length of 25.",
                                    },
                                    pattern: {
                                      value: /^[-a-zA-Z0-9 ]*$/,
                                      message:
                                        "Special characters are not allowed.",
                                    },
                                  })}
                                  value={state.enrollData.signaturelast}
                                  onChange={handleChange("signaturelast")}
                                  pattern="^[-a-zA-Z0-9 ]*$"
                                  data-webform-pattern-error="Special characters are not allowed."
                                  data-drupal-selector="edit-signaturelast"
                                  className="form-text required form-control"
                                  // data-msg-maxLength="Enrollee last name field has a maximum length of 25."
                                  // data-msg-required="Enrollee last name is required."
                                  // data-msg-pattern="Special characters are not allowed."
                                  type="text"
                                  id="edit-signaturelast"
                                  // name="signaturelast"
                                  // value=""
                                  size="60"
                                  maxLength="25"
                                  required="required"
                                  aria-required="true"
                                />
                                <div className="error">
                                  <ErrorMessage
                                    errors={errors}
                                    name="signaturelast"
                                  >
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                            <div
                              id="edit-contact-customer-service"
                              className="form-item js-form-item form-type-processed-text js-form-type-processed-text form-item- js-form-item- form-no-label form-group"
                            >
                              <h2>Change your billing preference</h2>

                              <p>
                                You can change your method of payment at any
                                time. Contact customer service for more
                                information:
                                <br />
                                Individuals call{" "}
                                <Link
                                  to="tel:1-888-832-0075"
                                  title="click to call"
                                >
                                  <strong>1-888-832-0075</strong>
                                </Link>
                                 (TTY: 
                                <Link to="tel:711" title="click to call">
                                  <strong>711</strong>
                                </Link>
                                ), 8 a.m. to 8 p.m., daily, local time .<br />
                                Employer group members call{" "}
                                <Link
                                  to="tel:1-877-838-3827"
                                  title="click to call"
                                >
                                  <strong>1-877-838-3827</strong>
                                </Link>
                                 (TTY: 
                                <Link to="tel:711" title="click to call">
                                  <strong>711</strong>
                                </Link>
                                ), 8 a.m. to 8 p.m., daily, local time.
                              </p>
                            </div>
                            <input type="hidden" name="signature" value="Y" />
                          </div>
                        </div>
                      </div>
                      <div
                        data-drupal-selector="edit-actions"
                        className="
                          form-actions
                          webform-actions
                          form-group
                          js-form-wrapper
                          form-wrapper
                        "
                        data-msg-required="Submit button(s) is required."
                        id="edit-actions"
                      >
                        <Button
                          className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handleNextClick}
                        >
                          {state.enrollData.activeStep === steps.length - 1
                            ? "Finish"
                            : state.enrollData.activeStep === steps.length - 2
                            ? "Submit"
                            : "Continue to " +
                              steps[state.enrollData.activeStep + 1]}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChangePaymentOption;
