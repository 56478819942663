import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import ConfirmDialog from "./ConfirmDialog";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import useContent from "../../services/contentful/content";

const AgentPortal = () => {
  const [content, isLoading] = useContent("4pJt0ThfMepFzc8QYdCLDB");
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const [AgentState, setAgentState] = useState();
  const [externalUrl, setExternalUrl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickToOpen = () => {
    setOpen(true);
  };

  // const handleToClose = () => {
  //   setOpen(false);
  //   setExternalUrl(null);
  // };

  const openExternalUrl = () => {
    if (externalUrl != null) window.open(externalUrl, "_blank");
    setExternalUrl(null);
  };

  const onSubmit = async (data) => {
    const isValidForm = await trigger();

    if (isValidForm) {
      if (data.AgentState === "Iowa") {
        setExternalUrl("https://ia.medicarebluerxagent.com");
      } else if (data.AgentState === "Minnesota") {
        setExternalUrl("https://mn.medicarebluerxagent.com");
      } else if (data.AgentState === "Montana") {
        setExternalUrl("https://mt.medicarebluerxagent.com");
      } else if (data.AgentState === "Nebraska") {
        setExternalUrl("https://ne.medicarebluerxagent.com");
      } else if (data.AgentState === "North Dakota") {
        setExternalUrl("https://nd.medicarebluerxagent.com");
      } else if (data.AgentState === "South Dakota") {
        setExternalUrl("https://sd.medicarebluerxagent.com");
      } else if (data.AgentState === "Wyoming") {
        setExternalUrl("https://wy.medicarebluerxagent.com");
      } else {
        setExternalUrl("https://www.bing.com");
      }

      handleClickToOpen();
    }
  };

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Agent Portal</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">Please select state</h1>

            <article className="standard full clearfix">
              <div className="content">
                <div className="select-wrapper">
                  <select
                    {...register(
                      "AgentState",
                      { required: "State selection is required." }
                      // {
                      //   validate: {
                      //     required: (value) => {
                      //       if (
                      //         !value
                      //       )
                      //         return "State selection is required.";
                      //       return true;
                      //     },
                      //   },
                      // }
                    )}
                    value={AgentState}
                    //onChange = {handleStateChange}
                    onChange={(event) => setAgentState(event.target.value)}
                    className="form-select form-control"
                    id="edit-agentstate"
                    name="AgentState"
                  >
                    <option value="" selected="selected">
                      - Select -
                    </option>
                    <option value="Iowa">Iowa</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                </div>
                <div className="error">
                  <ErrorMessage errors={errors} name="AgentState">
                    {({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p key={type}>{message}</p>
                      ))
                    }
                  </ErrorMessage>
                </div>
              </div>
              {/* <div className="content">
                <br />
                <div>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.copy}
                  />
                </div>
              </div> */}

              <div
                className="js-webform-states-hidden form-actions webform-actions form-group js-form-wrapper form-wrapper"
                id="edit-actions"
              >
                <Button
                  className="
                                  webform-button--next
                                  button
                                  js-form-submit
                                  form-submit
                                  btn-default btn"
                  onClick={handleSubmit(onSubmit)}
                  style={{ marginTop: "20px" }}
                  {...(!AgentState && {
                    style: { display: "none" },
                  })}
                >
                  Go to {AgentState}
                </Button>
              </div>
              <div>
                <br />
                If you experience any issues with registration, logging into the
                portal, or have other questions, you can call the broker help
                desk at <b>1-866-849-2498</b>.
                <br />
                <h2>Frequently asked questions about the agent portal</h2>
                <br />
                <b>Who can access the portal?</b>
                <br />
                Only agents who have completed their MedicareBlue Rx onboarding
                and certification training can access this portal.
                <br />
                <br />
                <b>Can I complete enrollments through this portal?</b>
                <br />
                No. Online enrollments must take place on
                YourMedicareSolutions.com.
                <br />
                <br />
                <b>
                  I just finished an enrollment. When will the member’s
                  information appear in the portal?
                </b>
                <br />
                It can take up to 10 days for the enrollment process to be
                completed. Member information is not loaded into the portal
                until CMS has accepted the member’s enrollment.
                <br />
                <br />
                <b>
                  Who should I contact if I have an urgent need for member
                  information, and can’t find it in the agent portal?
                </b>
                <br />
                Contact the MedicareBlue Rx broker help desk for assistance at&nbsp;
                <b>1-866-849-2498</b>.
                <br />
                <br />
                <b>How do I reset my password?</b>
                <br />
                If you need to reset your password, click on “forgot password”
                on the Agent Portal login screen.
                <br />
              </div>
            </article>
          </div>
        </section>
      </div>

      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        onConfirm={openExternalUrl}
        confirmText="CONTINUE TO NEW SITE"
        cancelText="Cancel"
      >
        <h2>You are now leaving YourMedicareSolutions.com</h2>
        Thank you for visiting<p></p>
      </ConfirmDialog>
    </div>
  );
};

export default AgentPortal;
