import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import enrollData from "../../state/data/enrollData";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import moment from "moment";
import ChangeDemographicInfo from "./ChangeDemographicInfo";
import ReviewDemographicChange from "./ReviewDemographicChange";
import ChangeDemographicConfirmation from "./ChangeDemographicConfirmation";
import clearEnrollData from "../../state/actions/clearEnrollData";
import { withRouter } from "react-router-dom"

const DemographicChange = ({history}) => {
  const useStyles = makeStyles((theme) => ({
    iconContainer: {
      // define styles for icon container
      transform: "scale(2)",
    },
    alternativeLabel: {
      fontSize: "16px",
    },
  }));

  const [backButtonDisabled, setBackButtonDisabled] = useState(false);

  const classes = useStyles();

  const {
    register,
    formState: { errors, isValid, isDirty },
    trigger,
    getValues,
    control,
  } = useForm({
    mode: "all",
  });

  const [phoneOrEmail, setPhoneOrEmail] = useState(false);
  const [permAddress, setPermAddress] = useState(false);
  const [mailAddress, setMailAddress] = useState(false);
  const [billingAddress, setBillingAddress] = useState(false);

  const [planType, setPlanType] = useState("none");

  const handlePlanType = (e) => {
    let s = e.target.value;
    setPlanType(s);
  };

  // console.log(planType);

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(enrollData);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    history.push(window.location.pathname);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    history.push(window.location.pathname);
    window.scrollTo(0, 0);
  };

  const handleJumpTo = (stepIndex) => {
    setActiveStep(stepIndex);
    history.push(window.location.pathname);
    window.scrollTo(0, 0);
  };

  const handleChange = (input) => (e) => {
    setFormValues({ ...formValues, [input]: e.target.value });
  };

  const formName = "DemographicChange";

  const setDefaultValues = useCallback(() => {
    if (formValues.activeForm !== formName) {
      clearEnrollData();
    }

    setFormValues({
      ...formValues,
      recversion: "2024-01",
      transactiontype: "DEMOGR",
      signature: "Y",
      datesigned: moment().format("MM/DD/YYYY"),
      activeForm: formName,
    });
  }, [formValues]);

  const partADate = {
    month: "",
    day: "",
    year: "",
  };

  const [partADateString, setPartADateString] = useState({ partADate });

  const partBDate = {
    month: "",
    day: "",
    year: "",
  };

  const [partBDateString, setPartBDateString] = useState({ partBDate });

  const reqEffDate = {
    month: "",
    day: "",
    year: "",
  };

  const [aep, setAep] = useState(false);

  const [reqEffDateString, setReqEffDateString] = useState({ reqEffDate });

  const sepOtherDate2 = {
    month: "",
    day: "",
    year: "",
  };

  const [sepOtherDate2String, setSepOtherDate2String] = useState({
    sepOtherDate2,
  });

  const sepOtherDate3 = {
    month: "",
    day: "",
    year: "",
  };

  const [sepOtherDate3String, setSepOtherDate3String] = useState({
    sepOtherDate3,
  });

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [diffAddress, setDiffAddress] = useState(false);

  const [newToMedicare, setNewToMedicare] = useState(false);

  const [svcAreaMove, setSvcAreaMove] = useState(false);

  const [ltc, setLtc] = useState(false);

  const [return2us, setReturn2Us] = useState(false);

  const [lostCoverage, setLostCoverage] = useState(false);

  const [disenrlSnp, setDisenrlSnp] = useState(false);

  const [nonRenewal, setNonRenewal] = useState(false);

  const [lostPdpPartB, setLostPdpPartB] = useState(false);

  const [maOep, setMaOep] = useState(false);

  const [leavingEmpCoverage, setLeavingEmpCoverage] = useState(false);

  const [leavingMa, setLeavingMa] = useState(false);

  const [extraHelp, setExtraHelp] = useState(false);

  const [medicarePremCoverage, setMedicarePremCoverage] = useState(false);

  const [medicaid, setMedicaid] = useState(false);

  const [lawfulPresence, setLawfulPresence] = useState(false);

  const [chooseDifferent, setChooseDifferent] = useState(false);

  const [emergency, setEmergency] = useState(false);

  const [incarceration, setIncarceration] = useState(false);

  const [other, setOther] = useState(false);

  const [preparer, setPreparer] = useState("");

  const [callCenterAssist, setCallCenterAssist] = useState(false);

  const [birthDate, setBirthDate] = useState(null);

  
    ///////////////////////////////////
    // Handle Back and Forward buttons
    ///////////////////////////////////
    const [locationHistory, setLocationHistory] = useState({});
  
    useEffect(() => {
  
      if (history.action === 'PUSH') {
  
        setLocationHistory(locationHistory => ({ ...locationHistory, [history.location.key]: activeStep }))
  
      }
      else if (history.action === "POP") {
  
        if (!backButtonDisabled) {
  
          var stepNumber = ((history.location.key) ? locationHistory[history.location.key] : 0) ?? 0;
  
          if (stepNumber >= 0) {
            setActiveStep(stepNumber);  
          }
        }
  
        window.scrollTo(0, 0);
  
      }
    }, [history.location])

  function getSteps() {
    return ["Change Demographic Info", "Review Info", "Confirmation"];
  }

  const steps = getSteps();

  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ChangeDemographicInfo
            values={formValues}
            handleChange={handleChange}
            setFormValues={setFormValues}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            phoneOrEmail={phoneOrEmail}
            setPhoneOrEmail={setPhoneOrEmail}
            permAddress={permAddress}
            setPermAddress={setPermAddress}
            mailAddress={mailAddress}
            setMailAddress={setMailAddress}
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
            birthDate={birthDate}
            setBirthDate={setBirthDate}
            planType = {planType}
            setPlanType = {setPlanType} 
          />
        );
      case 1:
        return (
          <ReviewDemographicChange
            values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleJumpTo={handleJumpTo}
            activeStep={activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            diffAddress={diffAddress}
            newToMedicare={newToMedicare}
            svcAreaMove={svcAreaMove}
            ltc={ltc}
            return2us={return2us}
            lostCoverage={lostCoverage}
            disenrlSnp={disenrlSnp}
            Ks
            nonRenewal={nonRenewal}
            lostPdpPartB={lostPdpPartB}
            maOep={maOep}
            leavingEmpCoverage={leavingEmpCoverage}
            leavingMa={leavingMa}
            extraHelp={extraHelp}
            medicarePremCoverage={medicarePremCoverage}
            medicaid={medicaid}
            lawfulPresence={lawfulPresence}
            chooseDifferent={chooseDifferent}
            emergency={emergency}
            incarceration={incarceration}
            other={other}
            callCenterAssist={callCenterAssist}
            preparer={preparer}
            setFormValues={setFormValues}
            setBackButtonDisabled={setBackButtonDisabled}
          />
        );
      case 2:
        return (
          <ChangeDemographicConfirmation
            values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleJumpTo={handleJumpTo}
            activeStep={activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            diffAddress={diffAddress}
            newToMedicare={newToMedicare}
            svcAreaMove={svcAreaMove}
            ltc={ltc}
            return2us={return2us}
            lostCoverage={lostCoverage}
            disenrlSnp={disenrlSnp}
            Ks
            nonRenewal={nonRenewal}
            lostPdpPartB={lostPdpPartB}
            maOep={maOep}
            leavingEmpCoverage={leavingEmpCoverage}
            leavingMa={leavingMa}
            extraHelp={extraHelp}
            medicarePremCoverage={medicarePremCoverage}
            medicaid={medicaid}
            lawfulPresence={lawfulPresence}
            chooseDifferent={chooseDifferent}
            emergency={emergency}
            incarceration={incarceration}
            other={other}
            callCenterAssist={callCenterAssist}
            preparer={preparer}
            setFormValues={setFormValues}
          />
        );

      default:
        return "Unknown Step";
    }
  }

  useEffect(() => {
    setDefaultValues();
  }, []);

  return (
    <div className="dialog-off-canvas-main-canvas">
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <section
                id="block-clearstonebreadcrumbs"
                className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
              >
                <div className="breadcrumb-wrap">
                  <div className="region--breadcrumb nst-1">
                    <section
                      id="block-ttheme-breadcrumbs"
                      className="block-system block-system-breadcrumb-block"
                    >
                      <div role="navigation">
                        <ol className="breadcrumb">
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>MBRx update contact information</li>
                        </ol>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
              <br />

              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      classes={{
                        iconContainer: classes.iconContainer,
                        alternativeLabel: classes.alternativeLabel,
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div>{getStepsContent(activeStep)}</div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};


//////////////////////////////////////////////////////////////
// Wrap component withRouter to have access to history object
//////////////////////////////////////////////////////////////
const DemographicChangeWithRouter = withRouter(DemographicChange);

export default DemographicChangeWithRouter;

