import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import clearContactData from "../../state/actions/clearContactData";

const ContactConfirmation = ({ location }) => {
  //const { confNum } = (props.location && props.location.state) || {};

  const { actions } = useStateMachine({
    clearContactData,
  });

  // console.log(location.state.confNum);
  useEffect(() => () => actions.clearContactData(), []);

  return (
    <>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-8 col-md-9 internal-page order-md-2">
            <div className="region region-content">
              <section
                id="block-clearstonebreadcrumbs"
                className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
              >
                <div className="breadcrumb-wrap">
                  <div className="region--breadcrumb nst-1">
                    <section
                      id="block-ttheme-breadcrumbs"
                      className="block-system block-system-breadcrumb-block"
                    >
                      <div role="navigation">
                        <ol className="breadcrumb">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>CONTACT FORM ONLINE</li>
                        </ol>
                      </div>
                    </section>
                  </div>
                </div>
                <br />
                <div className="webform-confirmation">
                  <div className="webform-confirmation__message">
                    Thank you for your submission.
                    <h3>Confirmation Number: {location.state.confNum}</h3>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ContactConfirmation;
