import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Button } from "@material-ui/core";
import WhatsNext from "./WhatsNext";
import moment from "moment";
import updateEnrollData from "../../state/actions/updateEnrollData";
import clearEnrollData from "../../state/actions/clearEnrollData";
import { submitConfirmation } from "../../api/confirmationApi";
import { ErrorMessage } from "@hookform/error-message";

const ChangeDemographicConfirmation = ({ values, confNum }) => {
  const { actions } = useStateMachine({
    updateEnrollData,
    clearEnrollData,
  });

  const {
    register,
    formState: { errors },
    getValues,
  } = useForm();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [emailSent, setEmailSent] = useState(false);

  const handleEmailSent = async (e) => {
    e.preventDefault();
    setTimeout(() => setEmailSent(!emailSent), 3000); //<--setTimeout is to simulate the email submission. This will be replaced with call/response to API

    let confRequest = { conf: values.confirmationno, email: email };

    let s = await submitConfirmation(confRequest);

    // console.log("Email conf response: " + s);

    setEmailSent(true);

    // if (s.confirmationno !== "") {

    // } else {
    // }
  };
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => () => actions.clearEnrollData(), []);

  return (
    <div>
      <div
        data-webform-key="step_5"
        data-drupal-selector="edit-step-5"
        className="js-form-wrapper form-wrapper form-group"
        data-msg-required="Step 5 is required."
        id="edit-step-5--2"
      >
        <div
          id="edit-step-5-title"
          className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                        webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                      "
        >
          <div className="webform-confirmation">
            <div className="webform-confirmation__message">
              <div className="row">
                <div className="col-sm-12">
                  <h3>
                    Your confirmation number is {" " + values.confirmationno}
                  </h3>
                  <p>
                    Congratulations, you have completed the online enrollment
                    for MedicareBlue Rx.
                  </p>
                  <p className="leftarrowheading">
                    Here's what to expect next.
                  </p>
                  <ul>
                    <li>
                      {" "}
                      You will receive several mailings over the next few weeks.
                    </li>
                    {/* begin post enroll call center script markup */}
                    <li>
                      <button
                        type="button"
                        onClick={handleOpen}
                        className="btn btn-link btn-anchor btn-lg"
                      >
                        See complete details on what to expect next
                      </button>
                      .
                    </li>

                    <WhatsNext
                      open={open}
                      handleOpen={handleOpen}
                      handleClose={handleClose}
                      confNum={confNum}
                    />
                    {/* begin post enroll call center script markup 2018 full path */}
                    <li>
                      If you have any questions or concerns about MedicareBlue
                      Rx{" "}
                      <a
                        href="/contact/medicareblue-rx"
                        data-tooltip="You will not lose your place on this confirmation page by clicking this link."
                        target="_blank"
                      >
                        contact customer service
                      </a>
                      .
                    </li>
                  </ul>
                  <p className="leftarrowheading">
                    Please write down the confirmation number above.
                  </p>
                  <ul>
                    <li>
                      You can also send it by email (other information on this
                      page will not be included in the email).
                    </li>
                    <li>You can print this page for your records.</li>
                  </ul>
                  <p></p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <form
                    id="confirmation-form"
                    className="email-confirmation form-inline"
                    novalidate="novalidate"
                  >
                    <div>
                      <input type="hidden" name="planname" value="2" />
                    </div>
                    <div className="form-group">
                      <input
                        {...register("confirmationEmail", {
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email address.",
                          },
                        })}
                        value={email}
                        onChange={handleEmail}
                        className="form-control"
                        type="email"
                        id="uemail"
                        maxLength="255"
                      />
                      <div className="error">
                        <ErrorMessage errors={errors} name="confirmationEmail">
                          {({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                              <p key={type}>{message}</p>
                            ))
                          }
                        </ErrorMessage>
                      </div>
                    </div>{" "}
                    <div className="send-confirmation">
                      <button
                        onClick={handleEmailSent}
                        className="btn btn-default"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                  <div
                    className="error"
                    {...(!emailSent && {
                      style: { display: "none" },
                    })}
                  >
                    Email request has been sent.
                  </div>
                </div>
                <div className="col-sm-6">
                  <button onClick={handlePrint} className="btn btn-default">
                    Print this Page
                  </button>
                </div>
              </div>
              <h3>Date and time of enrollment submission</h3>
              {values.dor}
              <div>
                <p>The above is shown in Central time</p>
              </div>
              <h3>Your information</h3>
            </div>
            <br />

            {/*Row 1*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Personal Information*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Personal information
                  </div>
                </div>

                <div className="panel-body">
                  <div
                    {...(values.firstname === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">First name: </label>{" "}
                    {values.firstname}
                    <br />
                  </div>
                  <div
                    {...(values.lastname === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Last name:</label>{" "}
                    {values.lastname}
                    <br />
                  </div>
                  <div
                    {...(values.dob === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Date of birth:</label>{" "}
                    {values.dob}
                    <br />
                  </div>
                </div>
              </div>

              {/*Phone email changes*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Phone email changes
                  </div>
                </div>

                <div className="panel-body">
                  <div
                    {...(values.homephone === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Home phone:</label>{" "}
                    {values.homephone}
                    <br />
                  </div>
                  <div
                    {...(values.altphone === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Alternate phone:</label>{" "}
                    {values.altphone}
                    <br />
                  </div>
                  <div
                    {...(values.email === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Email address</label>{" "}
                    {values.email}
                    <br />
                  </div>
                </div>
              </div>
            </div>

            {/*Row 2*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Permanent address change*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Permanent residence address change
                  </div>
                </div>

                <div className="panel-body">
                  <div
                    {...(values.permst1 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Address:</label>{" "}
                    {values.permst1}
                    <br />
                  </div>
                  <div
                    {...(values.permst2 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Address 2</label>{" "}
                    {values.permst2}
                    <br />
                  </div>
                  <div
                    {...(values.permcity === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">City:</label>{" "}
                    {values.permcity}
                    <br />
                  </div>
                  <div
                    {...(values.permstate === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">State:</label>{" "}
                    {values.permstate}
                    <br />
                  </div>
                  <div
                    {...(values.permzip5 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Zip code (xxxxx):</label>{" "}
                    {values.permzip5}
                    <br />
                  </div>
                  <div
                    {...(values.permzip4 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Zip+4 (xxxx)</label>{" "}
                    {values.permzip4}
                    <br />
                  </div>
                </div>
              </div>

              
              

              {/*Mailing address change*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Mailing residence address change
                  </div>
                </div>

                <div className="panel-body">
                  <div
                    {...(values.mailst1 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Mailing Address:</label>{" "}
                    {values.mailst1}
                    <br />
                  </div>
                  <div
                    {...(values.mailst2 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Mailing Address 2:</label>{" "}
                    {values.mailst2}
                    <br />
                  </div>
                  <div
                    {...(values.mailcity === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Mailing City:</label>{" "}
                    {values.mailcity}
                    <br />
                  </div>
                  <div
                    {...(values.mailst === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Mailing State:</label>{" "}
                    {values.mailst}
                    <br />
                  </div>
                  <div
                    {...(values.mailzip5 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Mailing Zip Code (xxxxx):
                    </label>{" "}
                    {values.mailzip5}
                    <br />
                  </div>
                  <div
                    {...(values.mailzip4 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Mailing Zip+4:</label>{" "}
                    {values.mailzip4}
                    <br />
                  </div>
                </div>
              </div>

            </div>

            {/*Row 3*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >

              {/*Billing address change*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-billing-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Billing address change
                  </div>
                </div>

                <div className="panel-body">
                  <div
                    {...(values.billingaddress1 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Billing Address:</label>{" "}
                    {values.billingaddress1}
                    <br />
                  </div>
                  <div
                    {...(values.billingaddress2 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Billing Address 2:</label>{" "}
                    {values.billingaddress2}
                    <br />
                  </div>
                  <div
                    {...(values.billingcity === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Billing City:</label>{" "}
                    {values.billingcity}
                    <br />
                  </div>
                  <div
                    {...(values.billingst === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Billing State:</label>{" "}
                    {values.billingst}
                    <br />
                  </div>
                  <div
                    {...(values.billingzip5 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Billing Zip Code (xxxxx):
                    </label>{" "}
                    {values.billingzip5}
                    <br />
                  </div>
                  <div
                    {...(values.billingzip4 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Billing Zip+4:</label>{" "}
                    {values.billingzip4}
                    <br />
                  </div>
                </div>
              </div>

            

              {/*Applicant/Auth Rep Signature*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-auth-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                  Authorized signature
                  </div>
                </div>

                <div className="panel-body">
                  <div                  >
                    <label className="control-label">Today's date:</label>{" "}
                    {moment().format("MM/DD/YYYY")}
                    <br />
                  </div>
                  
                  <div
                    {...(values.signaturefirst === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">First name:</label>{" "}
                    {values.signaturefirst}
                    <br />
                  </div>

                  <div
                    {...(values.signaturelast === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Last name:</label>{" "}
                    {values.signaturelast}
                    <br />
                  </div>

                  <div>
                    <label className="control-label">Signature confirm:</label>{" "}
                    {values.signaturefirst.length +
                                        values.signaturelast.length >
                                      0
                                        ? "Y"
                                        : "N"}
                    <br />
                  </div>

                  <div
                    {...(values.demographicseffectivedate === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Request effective date for changes to take effect:</label>{" "}
                    {values.demographicseffectivedate &&  moment(values.demographicseffectivedate).format("MM/DD/YYYY")} 
                    <br />
                  </div>

                </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ChangeDemographicConfirmation;
