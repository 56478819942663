import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-8 col-md-9 internal-page order-md-2">
            <div className="region region-content">
              <section
                id="block-clearstonebreadcrumbs"
                className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
              >
                <div className="breadcrumb-wrap">
                  <div className="region--breadcrumb nst-1">
                    <section
                      id="block-ttheme-breadcrumbs"
                      className="block-system block-system-breadcrumb-block"
                    >
                      <div role="navigation">
                        <ol className="breadcrumb">
                          <li>
                            <a href="/">Home</a>
                          </li>
                        </ol>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
              <h1 className="page-header">Page not found</h1>
              The requested page could not be found.
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
