import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const TermsOfUse = () => {
  const[content, isLoading] = useContent("3WlB0mU144lKmLiT6Q9jz2");

  if (isLoading) return <p>Loading...</p>;
  
  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Terms of Use</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header"><ReactMarkdown rehypePlugins={[rehypeRaw]} children={content.headline} /></h1>

            <article className="page full clearfix">
              <div className="content">
                <div>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content.copy} />
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TermsOfUse;
