import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const FindMyPharmacyGroup2022 = () => {
  const [content, isLoading] = useContent("6OnQKkBRllRcZXEjVEMRSj");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Find My Pharmacy MedicareBlue Group 2022</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h2>
              <ReactMarkdown children={content.title} />
            </h2>

            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={content.tool}
            />
            <br />

            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={content.disclaimer}
            />

            {/* <h1 className="page-header">Find my pharmacy - Group plans 2020</h1> */}

            {/* <article
              data-history-node-id="204"
              role="article"
              about="/find-my-pharmacy-medicareblue-group-2020"
              typeof="schema:WebPage"
              className="page full clearfix"
            >
              <span
                property="schema:name"
                content="Find My Pharmacy MedicareBlue Group 2020"
                className="hidden"
              ></span>

              <div className="content">
                <div
                  property="schema:text"
                  className="field field--name-body field--type-text-with-summary field--label-hidden field__item"
                >
                  <iframe
                    id="frameTool"
                    width="100%"
                    height="800px"
                    frameborder="0"
                    src="https://www.medicareplanrx.com/jccf/clearstone_egwp_pharmacy_20.html"
                  ></iframe>
                </div>

                <div className="field field--name-field-content-section field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div className="paragraph paragraph--type--content-section paragraph--view-mode--default"></div>
                  </div>
                </div>

                <div className="field field--name-field-add-accordion field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div id="accordion-204" className="form-group">
                      <h3 className="accordion-title">
                        <Link
                          id="about-pharmacy-networks"
                          to="/Pharmacy/FindMyPharmacyGroup2020#about-pharmacy-networks"
                        >
                          About pharmacy networks
                        </Link>
                      </h3>
                      <div
                        className="accordion-description"
                        data-quickedit-entity-id="paragraph/574"
                      >
                        <div className="field field--name-field-description-standard-page field--type-text-long field--label-above">
                          <div className="field__label">
                            Description Standard Page
                          </div>
                          <div className="field__item">
                            <p>
                              This pharmacy locator tool provides a list of
                              Group MedicareBlue Rx’s network pharmacies. To get
                              a complete description of your prescription
                              coverage, including how to fill your
                              prescriptions, please review the Evidence of
                              Coverage and Group MedicareBlue Rx’s formulary.
                            </p>

                            <p>
                              We call the pharmacies on this list our “network
                              pharmacies” because we have made arrangements with
                              them to provide prescription drugs to plan
                              members. In most cases, your prescriptions are
                              covered under Group MedicareBlue Rx only if they
                              are filled at a network pharmacy or through our
                              mail order pharmacy service. Once you go to one
                              pharmacy, you are not required to continue going
                              to the same pharmacy to fill your prescription but
                              can switch to any other of our network pharmacies.
                              We will fill prescriptions at non-network
                              pharmacies under certain circumstances, as
                              described in your Evidence of Coverage.
                            </p>

                            <p>
                              You can get prescription drugs shipped to your
                              home through our network mail order delivery
                              program
                              <Link
                                to="Members/MailOrderPrescriptions"
                                style={{ textDecoration: "underline" }}
                                title="visit the mail order prescriptions page for details"
                              >
                                mail order delivery program
                              </Link>
                              .
                            </p>

                            <p>
                              If you have questions, please
                              <Link
                                to="EmployerGroupPlans/GroupContactInfo"
                                style={{ textDecoration: "underline" }}
                                title="various ways to contact Group MedicareBlue Rx are located here"
                              >
                                contact us
                              </Link>
                              .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article> */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default FindMyPharmacyGroup2022;
