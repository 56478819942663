import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Contact = () => {
  const [content, isLoading] = useContent("9cZ7O6r99wOvwL6tTPBPc");

  if (isLoading) return <p>Loading...</p>;

  // console.log(content);

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>{content.ModuleHeading}</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">Contact MedicareBlue Rx</h1>

            <article className="page full clearfix">
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  {/* begin copy */}

                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.bodyText}
                  />

                  {/* begin link boxes */}
                  <div className="flex-container contactbox">
                    <div style={{ marginLeft: 0 }}>
                      <Link to="/Contact/contact-form-online">
                        <img
                          src={
                            content.contentModules[0].fields.icon.fields.file
                              .url
                          }
                          alt="icon"
                        />
                      </Link>
                      <h3>
                        <Link to="/Contact/contact-form-online">
                          {content.contentModules[0].fields.heading}
                        </Link>
                      </h3>

                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.contentModules[0].fields.shortBodyText
                        }
                      />

                      {/*<p style="padding:10% 0 0 23%"><Link className="btn btn-default" to="/contact/contact-form-online">Contact form</Link></p>*/}
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <Link to="/mbrx-change-payment-option">
                        <img
                          src={
                            content.contentModules[1].fields.icon.fields.file
                              .url
                          }
                          alt="icon"
                        />
                      </Link>
                      <h3>
                        <Link to="/mbrx-change-payment-option">
                          {content.contentModules[1].fields.heading}
                        </Link>
                      </h3>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.contentModules[1].fields.shortBodyText
                        }
                      />
                      <br />
                      <br />
                      &nbsp;&nbsp;
                      {/*<p style="margin-left:10.5%;margin-top:-7px"><Link className="btn btn-default" to="/mbrx-change-payment-option">Change payment form</Link></p>*/}
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <Link to="/mbrx-update-contact-information">
                        <img
                          src={
                            content.contentModules[2].fields.icon.fields.file
                              .url
                          }
                          alt="icon"
                        />
                      </Link>
                      <h3>
                        <Link to="/mbrx-update-contact-information">
                          {content.contentModules[2].fields.heading}
                        </Link>
                      </h3>
                      <p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.contentModules[2].fields.shortBodyText
                          }
                        />
                        <br />
                        <br />
                        &nbsp;&nbsp;
                      </p>
                    </div>
                  </div>
                  {/* end link boxes */}
                  {/* begin left column */}
                  <article className="clayout">
                    <section className="clayout">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.bodyText2}
                      />
                    </section>
                    {/* begin right column */}
                    <section className="clayout">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.bodyText3}
                      />
                    </section>
                  </article>
                  {/* begin disenrollment section */}
                  <article className="callout clayout">
                    <section className="clayout">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.bodyText4}
                      />
                    </section>
                    <section className="clayout">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.bodyText5}
                      />
                    </section>
                  </article>
                  {/* end disenrollment section */}
                  {/* end copy */}
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
