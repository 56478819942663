import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const GroupPlanResearchTools = () => {
  const [content, isLoading] = useContent("6tZxmxSr3ozwXKzgqIdbAu");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/GroupBenefits">Employer group plans</Link>
                        </li>
                        <li>Group plan research tools</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content.ModuleHeading}
              />
            </h1>

            <article
              data-history-node-id="237"
              role="article"
              about="/employer-group-plans/group-plan-research-tools"
              typeof="schema:WebPage"
              className="page full clearfix"
            >
              <span
                property="schema:name"
                content="Group plan research tools"
                className="hidden"
              ></span>

              <div className="content">
                <div
                  property="schema:text"
                  className="field field--name-body field--type-text-with-summary field--label-hidden field__item"
                >
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.bodyText}
                  />

                  <div className="flex-container">
                    <div>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[0].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[0].fields.heading}</h4>

                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.contentModules[0].fields.shortBodyText
                        }
                      />
                    </div>

                    <div>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[1].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[1].fields.heading}</h4>

                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.contentModules[1].fields.shortBodyText
                        }
                      />
                    </div>

                    <div>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[2].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[2].fields.heading}</h4>

                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.contentModules[2].fields.shortBodyText
                        }
                      />
                    </div>

                    <div>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[3].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[3].fields.heading}</h4>

                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.contentModules[3].fields.shortBodyText
                        }
                      />
                    </div>
                    <div>
                      <img
                        alt="icon"
                        src={
                          content.contentModules[4].fields.icon.fields.file.url
                        }
                      />
                      <h4>{content.contentModules[4].fields.heading}</h4>

                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={
                          content.contentModules[4].fields.shortBodyText
                        }
                      />
                    </div>
                  </div>
                  <ReactMarkdown children={content.bodyText2} />
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default GroupPlanResearchTools;
