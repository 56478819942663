import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
// import { createTheme } from "@material-ui/core/styles";
// import { ThemeProvider } from '@material-ui/styles';

// const theme = createTheme({
//   components: {
//     // Name of the component
//     MuiDialogContent: {
//       styleOverrides: {
//         // Name of the slot
//         root: {
//           // Some CSS
//           padding: '36px',          
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         // Name of the slot
//         root: {
//           // Some CSS
//           fontSize: '10rem',
//           backgroundColor: '#202020',
//         },
//       },
//     },
//   },
// });

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, confirmText, cancelText } = props;
  return (
    // <ThemeProvider theme={theme}>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">
        <Box  display="flex" alignItems="center">
                <Box flexGrow={1} >{title}</Box>
                <Box >                
                    {/* <IconButton style={{backgroundColor: "gray"}}  onClick={() => setOpen(false)} > */}
                    <IconButton onClick={() => setOpen(false)} >
                    CLOSE &nbsp; <CloseIcon  />
                    </IconButton>                
                </Box>
          </Box>
      </DialogTitle>
      <DialogContent style={{paddingBottom: '68px'}} >{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          style={{background: '#fff', lineHeight: '15px', fontSize: '15px'  }}
        >
          {cancelText}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          style={{background: '#0070C0', color: '#fff', lineHeight: '15px', fontSize: '15px'}}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
    // </ThemeProvider> 
  );
};

export default ConfirmDialog;

