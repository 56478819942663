import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";

const About = () => {
  const [content, isLoading] = useContent("3p0Gk0uSB0A0iK7v3MRwYQ");

  if (isLoading) return <p>Loading...</p>;

  // console.log(content);

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>About Us</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown children={content.headline} />
            </h1>

            <article
              data-history-node-id="150"
              role="article"
              about="/about-us"
              typeof="schema:WebPage"
              className="page full clearfix"
            >
              <span
                property="schema:name"
                content="About Us"
                className="hidden"
              ></span>

              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <ReactMarkdown children={content.copy} />
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
