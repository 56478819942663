import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const PartDPlansEmployerAndUnionGroups = () => {
  const [content, isLoading] = useContent("5VX9sdI6rIliSVPqvNRkNn");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/GroupBenefits">
                            {" "}
                            Employer group plans{" "}
                          </Link>
                        </li>
                        <li>Part D plans for employer and union groups</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content.headline}
              />
            </h1>

            <article
              data-history-node-id="222"
              role="article"
              about="/employer-group-plans/part-d-plans-employer-and-union-groups"
              typeof="schema:WebPage"
              className="page full clearfix"
            >
              <span
                property="schema:name"
                content="Part D plans for employer and union groups"
                className="hidden"
              ></span>

              <div className="content">
                <div>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.copy}
                  />
                </div>

                <div className="field field--name-field-content-section field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    {" "}
                    <div className="paragraph paragraph--type--content-section paragraph--view-mode--default"></div>
                  </div>
                </div>

                <div className="field field--name-field-add-accordion field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div id="accordion-222" className="form-group"></div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>

        {/* <aside
          className="col-sm-4 col-md-3 side-section order-md-1"
          role="complementary"
        >
          <div className="region region-sidebar-second">
            <ul className="menu menu--main nav navbar-nav">
              <li className="active active-trail first med-group-benefit-solutions">
                <Link
                  to="/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups"
                  className="active-trail is-active"
                >
                  Group benefit solutions
                </Link>
              </li>
              <li className="med-group-resources">
                <Link to="/EmployerGroupPlans/GroupResources">
                  Group resources
                </Link>
              </li>
              <li className="med-2020-group-documents">
                <Link to="/EmployerGroupPlans/EmployerGroupDocs2022">
                  2022 Group documents
                </Link>
              </li>
              <li className="med-group-coverage-tools">
                <Link to="/EmployerGroupPlans/GroupPlanResearchTools">
                  Group coverage tools
                </Link>
              </li>
              <li className="med-group-contact-information">
                <Link to="/employer-group-plans/group-contact-information">
                  Group contact information
                </Link>
              </li>
              <li className="last med-fraud,-waste-and-abuse-information">
                <Link to="/EmployerGroupPlans/FWA">
                  Fraud, waste and abuse information
                </Link>
              </li>
            </ul>
          </div>
        </aside> */}
      </div>
    </div>
  );
};

export default PartDPlansEmployerAndUnionGroups;
