import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const FWA = () => {
  const [content, isLoading] = useContent("6p9FLrFQ5UermwrC2e2pad");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/GroupBenefits">Employer group plans</Link>
                        </li>
                        <li>Fraud, waste and abuse information</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <article className="page full clearfix">
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <h1 className="page-header">
                    <ReactMarkdown children={content.headline} />
                  </h1>

                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.copy}
                  />
                </div>
              </div>
            </article>
          </div>
        </section>

        {/* <aside className="col-sm-4 col-md-3 side-section order-md-1">
          <div className="region region-sidebar-second">
            <ul className="menu menu--main nav navbar-nav">
              <li className="first med-group-benefit-solutions">
                <Link to="/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups">
                  Group benefit solutions
                </Link>
              </li>
              <li className="med-group-resources">
                <Link to="/EmployerGroupPlans/GroupResources">
                  Group resources
                </Link>
              </li>
              <li className="med-2021-group-documents">
                <Link to="/EmployerGroupPlans/EmployerGroupDocs2022">
                  2022 Group documents
                </Link>
              </li>
              <li className="med-group-coverage-tools">
                <Link to="/EmployerGroupPlans/GroupPlanResearchTools">
                  Group coverage tools
                </Link>
              </li>
              <li className="med-group-contact-information">
                <Link to="/employer-group-plans/group-contact-information">
                  Group contact information
                </Link>
              </li>
              <li className="active active-trail last med-fraud,-waste-and-abuse-information">
                <Link
                  to="/EmployerGroupPlans/FWA"
                  className="active-trail is-active"
                >
                  Fraud, waste and abuse information
                </Link>
              </li>
            </ul>
          </div>
        </aside> */}
      </div>
    </div>
  );
};

export default FWA;
