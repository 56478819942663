import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";

const Step4_2024 = ({ handleNext, handlePrevious, activeStep, steps }) => {
  const { state, actions } = useStateMachine({ updateEnrollData });

  // console.log(state.enrollData);

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "all" });

  const handleAcceptTerms = () => {
    actions.updateEnrollData({ acceptTerms: !state.enrollData.acceptTerms });
  };

  let p1 = activeStep === 0 ? true : false;

  const handleNextClick = () => {
    handleNext();
  };

  // console.log(errors);
  return (
    <div>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <article className="webform full clearfix">
                <div className="content">
                  <div className="content enroll-auth-cb colorbox-inline">
                    <p>
                      <big>
                        <strong>
                          <span style={{ color: "red" }}>STOP</span> Please read
                          enrollment authorization and important information
                          before proceeding
                        </strong>
                      </big>
                    </p>

                    <h2>
                      Enrollment authorization: By completing this enrollment
                      application, I agree to the following
                    </h2>

                    <ol>
                      <li>
                        I must keep Part A or Part B to stay in MedicareBlue Rx.
                      </li>
                      <li>
                        By joining this Medicare prescription drug plan, I
                        acknowledge that MedicareBlue Rx will share my
                        information with Medicare, who may use it to track my
                        enrollment, to make payments, and for other purposes
                        allowed by federal law that authorize the collection of
                        this information.
                      </li>
                      <li>
                        Your response to this form is voluntary. However,
                        failure to respond may affect enrollment in the plan.
                      </li>
                      <li>
                        I understand that I can be enrolled in only one Part D plan at a time - and that the enrollment in this plan will automatically end my enrollment in another Part D plan.
                      </li>
                      <li>
                        The information on this enrollment form is correct to
                        the best of my knowledge. I understand that if I
                        intentionally provide false information on this form, I
                        will be disenrolled from the plan.
                      </li>
                    </ol>

                    <h2>Privacy Act Statement</h2>

                    <p>
                      The Centers for Medicare &amp; Medicaid Services (CMS)
                      collects information from Medicare plans to track
                      beneficiary enrollment in Medicare Advantage (MA) or
                      Prescription Drug Plans (PDP), improve care, and for the
                      payment of Medicare benefits. Sections 1851 and 1860D-1 of
                      the Social Security Act and 42 CFR §§ 422.50, 422.60,
                      423.30 and 423.32 authorize the collection of this
                      information. CMS may use, disclose and exchange enrollment
                      data from Medicare beneficiaries as specified in the
                      System of Records Notice (SORN) “Medicare Advantage
                      Prescription Drug (MARx)”, System No. 09-70-0588. Your
                      response to this form is voluntary. However, failure to
                      respond may affect enrollment in the plan.
                    </p>
                    <label
                      //htmlFor="accept-terms"
                      className="control-label option js-form-required form-required"
                      //onClick={handleAcceptTerms}
                    >
                      <input
                        {...register("accept_terms")}
                        value={state.enrollData.acceptTerms}
                        onChange={handleAcceptTerms}
                        className="form-checkbox"
                        data-msg-required="Check this only if mailing address is different than permanent resident address is required."
                        type="checkbox"
                        id="edit-different-mailing-address"
                        //defaultValue="0"
                        checked={state.enrollData.acceptTerms}
                      />{" "}
                      By selecting the checkbox, I acknowledge that I have read and understand the terms description in the Enrollment Authorization.
                    </label>
                  </div>
                </div>
                <Button
                  className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                  onClick={handlePrevious}
                  //disabled={p1}
                  {...(p1 && {
                    style: { display: "none" },
                  })}
                >
                  Previous
                </Button>

                <Button
                  className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                  onClick={handleNextClick}
                  disabled={!state.enrollData.acceptTerms}
                >
                  {activeStep === steps.length - 1
                    ? "Finish"
                    : activeStep === steps.length - 2
                    ? "Submit"
                    : "Continue to " + steps[activeStep + 1].toString()}
                </Button>
              </article>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Step4_2024;
