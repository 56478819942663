import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import CSPhoneInput from "../../../Common/CSPhoneInput";
import CSDateInput from "../../../Common/CSDateInput";


const Step1_2024 = ({
  handleChange,
  handleNext,
  handlePrevious,
  activeStep,
  steps,
  //birthDate,
  //setBirthDate,
}) => {
  const { state, actions } = useStateMachine({ updateEnrollData });

  //const [birthDate, setBirthDate] = useState(state.enrollData.dob ? new Date(state.enrollData.dob.split('-')) : null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    getValues,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const handleDiffAddress = () => {

    
    let d = !state.enrollData.diffAddress;    
    actions.updateEnrollData({ diffAddress: d });

    if (d == false) {
      // clear any billing address data
      actions.updateEnrollData({ billingaddress1: "", billingaddress2: "", billingcity: "", billingst: "", billingzip5: "", billingzip4: "" });
    }
  };


  const handlePermState = (input) => (e) => {
    actions.updateEnrollData({ permstate: e.target.value });
    if (e.target.value !== "MT") {
      actions.updateEnrollData({ seppap: " ", pap: false });    
    }
    //setValue("StateOfDirectory", ""); // sync useForm form validation
  };

  // console.log(errors);
  console.log('state.enrollData.dob:' + state.enrollData.dob);

  let p1 = activeStep === 0 ? true : false;

  //const [dobError, setDOBError] = useState(false);

  //let bdErr = false;

  const hasMailingAddress = () => {
    return state.enrollData.mailst1.length > 0 
    || state.enrollData.mailst2.length > 0 
    || state.enrollData.mailcity.length > 0 
    || state.enrollData.mailst.length > 0 
    || state.enrollData.mailzip5.length > 0 
    || state.enrollData.mailzip4.length > 0 
}

  const handleNextClick = async () => {
    const isValidForm = await trigger();  

    // if (!birthDate) {
    //   bdErr = true;
    //   setDOBError(true);
    // } else {
    //   bdErr = false;
    //   setDOBError(false);
    // }

    // console.log(
    //   "DOB Error: " + dobError + ", DOB: " + state.enrollData.dob.length
    // );

    if (isValidForm === true) { // && bdErr === false) {
      handleNext();
    }
  };

  // const handleHomePhone = (e) => {
  //   actions.updateEnrollData({ homephone: e.replace("+1", "") });
  // };

  // const handleAltPhone = (e) => {
  //   actions.updateEnrollData({ altphone: e.replace("+1", "") });
  // };

  //const minDate = "1911-" + moment().format("MM-DD");
  // console.log(minDate);

  //const defaultDate = moment().subtract(65, "years").format("YYYY-MM-DD");

  // console.log(defaultDate);

  //var d = new Date();

  // const handleBirthDate = (e) => {
  //   let date = moment(e).format("YYYY-MM-DD");
  //   setBirthDate(e);
  //   actions.updateEnrollData({ dob: date });
  // };

  // console.log("Birthdate: " + state.enrollData.dob);

  //const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', { minYear :1800, maxYear :2999 });

  return (
    <>
      <div
        className="dialog-off-canvas-main-canvas"
        data-off-canvas-main-canvas=""
      >
        <div
          role="main"
          className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
        >
          <div className="row d-md-flex">
            <section className="col-sm-12 internal-page">
              <div className="region region-content">
                <article className="webform full clearfix">
                  <div className="content">
                    <div>
                      <p>
                        <span className="form-required"> </span> indicates
                        required information
                      </p>
                    </div>

                    <div>
                      <form
                        // onSubmit={handleSubmit(() =>
                        // // console.log("form submitted")
                        // )}
                        className="
                        webform-submission-form
                        webform-submission-add-form                        
                        webform-details-toggle
                      "
                      >
                        <div className="js-form-wrapper form-wrapper form-group">
                          <div>
                            <h1>Enrollee and Plan Information</h1>
                          </div>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Please provide your Medicare insurance
                                information
                              </div>
                            </div>
                            <div className="panel-body">
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-med-id-row"
                                data-msg-required="This field is required."
                                id="edit-med-id-row"
                              >
                                <div
                                  className="
                                  col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-medicareclaimnum
                                  js-form-item-medicareclaimnum
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-medicareclaimnum"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Medicare number (without hyphens)
                                  </label>

                                  <input
                                    {...register("medicareclaimnum", {
                                      required:
                                        "Medicare number (without hyphens) is required.",
                                      maxLength: {
                                        value: 11,
                                        message:
                                          "Medicare number (without hyphens) field has a maximum length of 11.",
                                      },
                                      pattern: {
                                        value:
                                          /^^[1-9]{1}[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz]{1}[0-9]{1}-?[^SLOIBZsloibz|^0-9]{1}[^SLOIBZsloibz|^0-9]{1}[0-9]{1}[0-9]{1}/,
                                        message:
                                          "Enter your Medicare number exactly as it appears on your Medicare card without hyphens",
                                      },
                                      // validate: () =>
                                      // console.log("field validated"),
                                    })}
                                    type="text"
                                    value={state.enrollData.medicareclaimnum}
                                    onChange={handleChange("medicareclaimnum")}
                                    //onBlur={() => console.log(errors)}
                                    className="form-text required form-control"
                                    id="edit-medicareclaimnum"
                                    size="5"
                                    aria-required="true"
                                    autoComplete={"off"}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="medicareclaimnum"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-enroll-in-plan"
                            data-msg-required="Enroll me in the plan below is required."
                            id="edit-enroll-in-plan"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Enroll me in the plan below
                              </div>
                            </div>
                            <div className="panel-body">
                              <fieldset
                                data-drupal-selector="edit-planname"
                                className="
                                radios--wrapper
                                fieldgroup
                                form-composite
                                webform-composite-visible-title
                                required
                                js-webform-type-radios
                                webform-type-radios
                                js-form-item
                                form-item
                                js-form-wrapper
                                form-wrapper
                              "
                                data-msg-required="Choose a plan is required."
                                id="edit-planname--wrapper"
                              >
                                <legend>
                                  <span
                                    className="
                                    fieldset-legend
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Choose a plan
                                  </span>
                                </legend>
                                <div className="fieldset-wrapper">
                                  <div
                                    id="edit-planname"
                                    className="
                                    js-webform-radios
                                    webform-options-display-one-column
                                  "
                                  >
                                                                        <div
                                      className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-planname
                                      js-form-item-planname
                                      radio
                                    "
                                    >
                                      <label
                                        htmlFor="edit-planname-medicareblue-rx-select"
                                        className="control-label option"
                                      >
                                        <input
                                          {...register("planname", {
                                            required:
                                              "Choose a plan is required.",
                                          })}
                                          onChange={handleChange("planname")}
                                          className="form-radio"
                                          data-msg-required="Choose a plan is required."
                                          type="radio"
                                          id="edit-planname-medicareblue-rx-select"
                                          value="MedicareBlue Rx Select"
                                          required="required"
                                          //aria-required="true"
                                          name="planname"
                                          checked={
                                            state.enrollData.planname ===
                                            "MedicareBlue Rx Select"
                                          }
                                        />
                                        MedicareBlue Rx (PDP) Select
                                      </label>
                                    </div>
                                    <div
                                      className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-planname
                                      js-form-item-planname
                                      radio
                                    "
                                    >
                                      <label
                                        htmlFor="edit-planname-medicareblue-rx-standard"
                                        className="control-label option"
                                      >
                                        <input
                                          {...register("planname", {
                                            required:
                                              "Choose a plan is required.",
                                          })}
                                          onChange={handleChange("planname")}
                                          className="form-radio"
                                          data-msg-required="Choose a plan is required."
                                          type="radio"
                                          id="edit-planname-medicareblue-rx-standard"
                                          value="MedicareBlue Rx Standard"
                                          required="required"
                                          //aria-required="true"
                                          name="planname"
                                          checked={
                                            state.enrollData.planname ===
                                            "MedicareBlue Rx Standard"
                                          }
                                        />
                                        MedicareBlue Rx (PDP) Standard
                                      </label>
                                    </div>
                                    <div
                                      className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-planname
                                      js-form-item-planname
                                      radio
                                    "
                                    >
                                      <label
                                        htmlFor="edit-planname-medicareblue-rx-premier"
                                        className="control-label option"
                                      >
                                        <input
                                          {...register("planname", {
                                            required:
                                              "Choose a plan is required.",
                                          })}
                                          onChange={handleChange("planname")}
                                          className="form-radio"
                                          data-msg-required="Choose a plan is required."
                                          type="radio"
                                          id="edit-planname-medicareblue-rx-premier"
                                          value="MedicareBlue Rx Premier"
                                          required="required"
                                          //aria-required="true"
                                          name="planname"
                                          checked={
                                            state.enrollData.planname ===
                                            "MedicareBlue Rx Premier"
                                          }
                                        />
                                        MedicareBlue Rx (PDP) Premier
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <div className="error">
                                <ErrorMessage errors={errors} name="planname">
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(
                                      ([type, message]) => (
                                        <p key={type}>{message}</p>
                                      )
                                    )
                                  }
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-personal-information"
                            data-msg-required="Enrollee information is required."
                            id="edit-personal-information"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Enrollee information
                              </div>
                            </div>

                            <div className="panel-body">
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                id="edit-enroll-1-row"
                              >
                                <div
                                  className="
                                  col-sm-5 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-firstname
                                  js-form-item-firstname
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-firstname"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    First name
                                  </label>

                                  <input
                                    {...register("firstname", {
                                      required: "First name is required.",
                                      maxLength: {
                                        value: 25,
                                        message:
                                          "First name field has a maximum length of 25.",
                                      },
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                      validate: (value) => {
                                        return (
                                          !!value.trim() ||
                                          "First name is required."
                                        );
                                      },
                                    })}
                                    value={state.enrollData.firstname}
                                    onChange={handleChange("firstname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-firstname"
                                    className="form-text required form-control"
                                    data-msg-maxlength="First name field has a maximum length of 25."
                                    data-msg-required="First name is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-firstname"
                                    size="60"
                                    maxLength="25"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="firstname"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-2 col-md-2
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-mi
                                  js-form-item-mi
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-mi"
                                    className="control-label"
                                  >
                                    Middle initial
                                  </label>

                                  <input
                                    {...register("mi", {                                      
                                        pattern: {
                                          value: /^[-a-zA-Z0-9 ]*$/,
                                          message:
                                            "Special characters are not allowed.",
                                        }
                                    })}
                                    value={state.enrollData.mi}
                                    onChange={handleChange("mi")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-mi"
                                    className="form-text form-control"
                                    data-msg-maxlength="Middle initial	 field has a maximum length of 1."
                                    data-msg-required="Middle initial	 is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-mi"
                                    size="60"
                                    maxLength="1"
                                  />
                                  <div className="error">
                                    <ErrorMessage errors={errors} name="mi">
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-5 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-lastname
                                  js-form-item-lastname
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-lastname"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Last name
                                  </label>

                                  <input
                                    {...register("lastname", {
                                        required: "Last name is required.",
                                        maxLength: {
                                          value: 25,
                                          message:
                                            "First name field has a maximum length of 25.",
                                        },
                                        pattern: {
                                          value: /^[-a-zA-Z0-9 ]*$/,
                                          message:
                                            "Special characters are not allowed.",
                                        },
                                        validate: (value) => {
                                          return (
                                            !!value.trim() ||
                                            "Last name is required."
                                          );
                                        }
                                    })}
                                    value={state.enrollData.lastname}
                                    onChange={handleChange("lastname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-lastname"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Last name field has a maximum length of 25."
                                    data-msg-required="Last name is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-lastname"
                                    size="60"
                                    maxLength="25"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="lastname"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-enroll-3-row"
                                data-msg-required="This field is required."
                                id="edit-enroll-3-row"
                              >
                                <div
                                  className="
                                  col-sm-7 col-xs-12
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-dob
                                  js-form-item-dob
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-dob"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Birthdate
                                  </label>
                                  <div className="input-group">
                                    <span className="input-group-addon">
                                      <div className="date_wrap_div">                                      
                                        {/* <DatePicker
                                          name="dob"
                                          onChange={handleBirthDate}
                                          //onFocus={(e) => e.target.blur()}
                                          selected={birthDate}
                                          control={control}
                                          rules={{
                                            required: true,
                                          }}
                                          className="form-text
                                          required
                                          form-control hasDatepicker"
                                          placeholderText="MM/DD/YYYY"
                                          openToDate={d.setYear(
                                            d.getFullYear() - 65
                                          )}
                                          autoComplete="off"
                                          customInput={
                                            <MaskedInput
                                            pipe={autoCorrectedDatePipe}
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            keepCharPositions= {false}
                                            guide = {true}
                                            />                                          
                    
                                        }
                                        /> */}
                                        {/* <CSDateInput
                                                  name="dob"
                                                  rules={{ 
                                                    validate: {
                                                      requiredValidation:  value => { if (!value) return "Birthdate required."; return true }
                                                    }
                                                  }}  
                                                  control={control}
                                                  valueFormat={"YYYY-MM-DD"}
                                                  defaultValue={state.enrollData.dob}  // needed for initial form validation 
                                                  value={state.enrollData.dob}
                                                  onChange={handleChange("dob")}
                                                  className="form-text
                                                  required
                                                  form-control"                                             
                                          /> */}
                                          <CSDateInput
                                            name="dob"
                                            rules={{
                                              validate: { 
                                                requiredValidation:  value => { if (!value) return "Birthdate required."; return true },
                                              }
                                            }}  
                                            control={control}                                            
                                            value={state.enrollData.dob}
                                            onChange={handleChange("dob")}
                                            className="form-text
                                            required
                                            form-control"                                             
                                        />


                                      </div>
                                    </span>
                                  </div>
                                  <br />
                                  <div className="error">
                                              <ErrorMessage errors={errors} name="dob">
                                                {({ messages }) =>
                                                  messages &&
                                                  Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                  ))
                                                }
                                              </ErrorMessage>
                                  </div>
                                  {/* <div
                                    className="error"
                                    {...(dobError === false && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Birthdate required.
                                  </div>
                                  <div className="error">
                                    <ErrorMessage errors={errors} name="dob">
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div> */}
                                </div>
                                <div
                                  className="
                                  col-sm-5 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-select
                                  js-form-type-select
                                  form-item-sex
                                  js-form-item-sex
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-sex"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Gender
                                  </label>

                                  <div className="select-wrapper">
                                    <select
                                      {...register("sex", {
                                        required: "Gender required.",
                                      })}
                                      value={state.enrollData.sex}
                                      onChange={handleChange("sex")}
                                      className="form-select required form-control"
                                      id="edit-sex"
                                      required="required"
                                      aria-required="true"
                                    >
                                      <option value="">- Select -</option>
                                      <option value="M">Male</option>
                                      <option value="F">Female</option>
                                    </select>
                                    <div className="error">
                                      <ErrorMessage errors={errors} name="sex">
                                        {({ messages }) =>
                                          messages &&
                                          Object.entries(messages).map(
                                            ([type, message]) => (
                                              <p key={type}>{message}</p>
                                            )
                                          )
                                        }
                                      </ErrorMessage>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-permanent-resident-address"
                            data-msg-required="Permanent resident address: (Do NOT enter a P.O. Box) is required."
                            id="edit-permanent-resident-address"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Permanent resident address: (Don't enter a P.O.
                                Box)
                              </div>
                            </div>

                            <div className="panel-body">
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-1"
                                data-msg-required="This field is required."
                                id="edit-row-1"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permst1
                                  js-form-item-permst1
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permst1"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Street address
                                  </label>

                                  <input
                                    {...register("permst1", {
                                      required: "Street address is required.",
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permst1}
                                    onChange={handleChange("permst1")}
                                    pattern="^[-/a-zA-Z0-9 -]*$"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Street address field has a maximum length of 30."
                                    data-msg-required="Street address is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permst1"
                                    size="60"
                                    maxLength="30"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permst1"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permst2
                                  js-form-item-permst2
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permst2"
                                    className="control-label"
                                  >
                                    Address 2
                                  </label>

                                  <input
                                    {...register("permst2", {
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permst2}
                                    onChange={handleChange("permst2")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    className="form-text form-control"
                                    data-msg-maxlength="Address 2 field has a maximum length of 30."
                                    data-msg-required="Address 2 is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permst2"
                                    size="60"
                                    maxLength="30"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permst2"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-2"
                                data-msg-required="This field is required."
                                id="edit-row-2"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permcity
                                  js-form-item-permcity
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permcity"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    City
                                  </label>

                                  <input
                                    {...register("permcity", {
                                      required: "City is required.",
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "City field has a maximum length of 25.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permcity}
                                    onChange={handleChange("permcity")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    className="form-text required form-control"
                                    data-msg-maxlength="City field has a maximum length of 25."
                                    data-msg-required="City is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-permcity"
                                    size="60"
                                    maxLength="25"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permcity"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-select
                                  js-form-type-select
                                  form-item-permstate
                                  js-form-item-permstate
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permstate"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    State
                                  </label>

                                  <div className="select-wrapper">
                                    <select
                                      {...register("permstate", {
                                        required: "State is required.",
                                      })}
                                      value={state.enrollData.permstate}
                                      onChange={handlePermState()}
                                      data-drupal-selector="edit-permstate"
                                      className="form-select required form-control"
                                      data-msg-required="State is required."
                                      id="edit-permstate"
                                      required="required"
                                      aria-required="true"
                                    >
                                      <option value="">- Select -</option>
                                      <option value="IA">Iowa</option>
                                      <option value="MN">Minnesota</option>
                                      <option value="MT">Montana</option>
                                      <option value="NE">Nebraska</option>
                                      <option value="ND">North Dakota</option>
                                      <option value="SD">South Dakota</option>
                                      <option value="WY">Wyoming</option>
                                    </select>
                                    <div className="error">
                                      <ErrorMessage
                                        errors={errors}
                                        name="permstate"
                                      >
                                        {({ messages }) =>
                                          messages &&
                                          Object.entries(messages).map(
                                            ([type, message]) => (
                                              <p key={type}>{message}</p>
                                            )
                                          )
                                        }
                                      </ErrorMessage>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-3"
                                data-msg-required="This field is required."
                                id="edit-row-3"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permzip5
                                  js-form-item-permzip5
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permzip5"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Zip code (xxxxx)
                                  </label>

                                  <input
                                    {...register("permzip5", {
                                      required: "Zip code (xxxxx) is required.",
                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 5 digit zip code.",
                                      },
                                      maxLength: {
                                        value: 5,
                                        message:
                                          "Zip code (xxxxx) field has a maximum length of 5.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permzip5}
                                    onChange={handleChange("permzip5")}
                                    data-drupal-selector="edit-permzip5"
                                    className="form-text required form-control"
                                    data-msg-maxlength="Zip code (xxxxx) field has a maximum length of 5."
                                    data-msg-required="Zip code (xxxxx) is required."
                                    type="text"
                                    id="edit-permzip5"
                                    size="60"
                                    maxLength="5"
                                    required="required"
                                    aria-required="true"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permzip5"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>

                                <div
                                  className="
                                  col-sm-6 col-md-6 col-xs-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-permzip4
                                  js-form-item-permzip4
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-permzip4"
                                    className="control-label"
                                  >
                                    Zip+4 (xxxx)
                                  </label>

                                  <input
                                    {...register("permzip4", {
                                      minLength: {
                                        value: 4,
                                        message:
                                          "Please enter a 4 digit zip+4 code.",
                                      },
                                      maxLength: {
                                        value: 4,
                                        message:
                                          "Zip code (xxxx) field has a maximum length of 4.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.permzip4}
                                    onChange={handleChange("permzip4")}
                                    data-drupal-selector="edit-permzip4"
                                    className="form-text form-control"
                                    data-msg-maxlength="Zip+4 (xxxx) field has a maximum length of 4."
                                    type="text"
                                    id="edit-permzip4"
                                    size="60"
                                    maxLength="4"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="permzip4"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-4"
                                data-msg-required="This field is required."
                                id="edit-row-4"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-homephone
                                  js-form-item-homephone
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-homephone"
                                    className="
                                    control-label
                                    js-form-required
                                    form-required
                                  "
                                  >
                                    Phone number
                                  </label>
                                  <br />
                                  <CSPhoneInput
                                   name="homephone"
                                   id="edit-homephone"
                                   value={state.enrollData.homephone}
                                   onChange={handleChange("homephone")}
                                   control={control}
                                   rules = {{required: "Home phone number required."}}
                                   />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="homephone"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-altphone
                                  js-form-item-altphone
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-altphone"
                                    className="control-label"
                                  >
                                    Alternate phone number
                                  </label>
                                  <br />
                                  <CSPhoneInput
                                   name="altphone"
                                   id="edit-altphone"
                                   value={state.enrollData.altphone}
                                   onChange={handleChange("altphone")}
                                   control={control}
                                   //rules = {{required: "Alternate phone number required."}}
                                   />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="altphone"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-email-container"
                                data-msg-required="This field is required."
                                id="edit-row-email-container"
                              >
                                <div
                                  className="
                                  col-md-6 col-sm-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-email
                                  js-form-item-email
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-email"
                                    className="control-label"
                                  >
                                    Email address
                                  </label>

                                  <input
                                    {...register("email", {
                                      pattern: {
                                        value:
                                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message:
                                          "Please enter a valid email address.",
                                      },
                                    })}
                                    value={state.enrollData.email}
                                    onChange={handleChange("email")}
                                    data-drupal-selector="edit-email"
                                    className="form-text form-control"
                                    data-msg-maxlength="Email address field has a maximum length of 255."
                                    data-msg-required="Email address is required."
                                    type="email"
                                    id="edit-email"
                                    size="60"
                                    maxLength="255"
                                  />
                                  <div className="error">
                                    <ErrorMessage errors={errors} name="email">
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-md-6 col-sm-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-confirm-email
                                  js-form-item-confirm-email
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-confirm-email"
                                    className="control-label"
                                  >
                                    Confirm email address
                                  </label>

                                  <input
                                    {...register("confirm_email", {
                                      pattern: {
                                        value:
                                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message:
                                          "Please enter a valid email address.",
                                      },
                                      validate: () =>
                                        getValues("confirm_email") ===
                                        getValues("email"),
                                    })}
                                    value={state.enrollData.confirm_email}
                                    onChange={handleChange("confirm_email")}
                                    data-drupal-selector="edit-confirm-email"
                                    className="form-text form-control"
                                    data-msg-maxlength="Confirm email address field has a maximum length of 255."
                                    data-msg-required="Confirm email address is required."
                                    type="text"
                                    id="edit-confirm-email"
                                    size="60"
                                    maxLength="255"
                                  />
                                  {errors.confirm_email &&
                                    errors.confirm_email.type ===
                                      "validate" && (
                                      <div className="error">
                                        The email addresses do not match.
                                      </div>
                                    )}
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="confirm_email"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="                            
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                              Mailing address (complete if different from the permanent residence address; enter complete information; P.O. Box allowed)
                              </div>
                            </div>

                            <div className="panel-body">
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-8"
                                data-msg-required="This field is required."
                                id="edit-row-8"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-dst1
                                  js-form-item-dst1
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-dst1"
                                    className="control-label"
                                  >
                                    Mailing address
                                  </label>

                                  <input
                                    {...register("mailst1", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value && hasMailingAddress()
                                          )
                                            return "Mailing address is required.";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Mailing address field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.mailst1}
                                    onChange={handleChange("mailst1")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-dst1"
                                    className="form-text form-control"
                                    data-msg-maxlength="Mailing address field has a maximum length of 30."
                                    data-msg-required="Mailing address is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-dst1"
                                    size="60"
                                    maxLength="30"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="mailst1"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-dst2
                                  js-form-item-dst2
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-dst2"
                                    className="control-label"
                                  >
                                    Mailing address 2
                                  </label>

                                  <input
                                    {...register("mailst2", {
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address 2 field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.mailst2}
                                    onChange={handleChange("mailst2")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-dst2"
                                    className="form-text form-control"
                                    data-msg-maxlength="Mailing address 2 field has a maximum length of 30."
                                    data-msg-required="Mailing address 2 is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-dst2"
                                    size="60"
                                    maxLength="30"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="mailst2"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-9"
                                data-msg-required="This field is required."
                                id="edit-row-9"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-dcity
                                  js-form-item-dcity
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-dcity"
                                    className="control-label"
                                  >
                                    Mailing city
                                  </label>

                                  <input
                                    {...register("mailcity", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value && hasMailingAddress()
                                          )
                                            return "Mailing city is required.";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "City field has a maximum length of 25.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.mailcity}
                                    onChange={handleChange("mailcity")}
                                    pattern="^[a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-dcity"
                                    className="form-text form-control"
                                    data-msg-maxlength="Mailing city field has a maximum length of 30."
                                    data-msg-required="Mailing city is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-dcity"
                                    size="60"
                                    maxLength="30"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="mailcity"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-select
                                  js-form-type-select
                                  form-item-dst
                                  js-form-item-dst
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-dst"
                                    className="control-label"
                                  >
                                    Mailing state
                                  </label>

                                  <div className="select-wrapper">
                                    <select
                                      {...register("mailst", {
                                        validate: {
                                          required: (value) => {
                                            if (
                                              !value && hasMailingAddress()
                                            )
                                              return "Mailing state is required.";
                                            return true;
                                          },
                                        },
                                      })}
                                      value={state.enrollData.mailst}
                                      onChange={handleChange("mailst")}
                                      data-drupal-selector="edit-dst"
                                      className="form-select form-control"
                                      data-msg-required="Mailing state is required."
                                      id="edit-dst"
                                      data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                    >
                                      <option value="">- Select -</option>
                                      <option value="AL">Alabama</option>
                                      <option value="AK">Alaska</option>
                                      <option value="AZ">Arizona</option>
                                      <option value="AR">Arkansas</option>
                                      <option value="CA">California</option>
                                      <option value="CO">Colorado</option>
                                      <option value="CT">Connecticut</option>
                                      <option value="DE">Delaware</option>
                                      <option value="DC">
                                        District of Columbia
                                      </option>
                                      <option value="FL">Florida</option>
                                      <option value="GA">Georgia</option>
                                      <option value="HI">Hawaii</option>
                                      <option value="ID">Idaho</option>
                                      <option value="IL">Illinois</option>
                                      <option value="IN">Indiana</option>
                                      <option value="IA">Iowa</option>
                                      <option value="KS">Kansas</option>
                                      <option value="KY">Kentucky</option>
                                      <option value="LA">Louisiana</option>
                                      <option value="ME">Maine</option>
                                      <option value="MD">Maryland</option>
                                      <option value="MA">Massachusetts</option>
                                      <option value="MI">Michigan</option>
                                      <option value="MN">Minnesota</option>
                                      <option value="MS">Mississippi</option>
                                      <option value="MO">Missouri</option>
                                      <option value="MT">Montana</option>
                                      <option value="NE">Nebraska</option>
                                      <option value="NV">Nevada</option>
                                      <option value="NH">New Hampshire</option>
                                      <option value="NJ">New Jersey</option>
                                      <option value="NM">New Mexico</option>
                                      <option value="NY">New York</option>
                                      <option value="NC">North Carolina</option>
                                      <option value="ND">North Dakota</option>
                                      <option value="OH">Ohio</option>
                                      <option value="OK">Oklahoma</option>
                                      <option value="OR">Oregon</option>
                                      <option value="PA">Pennsylvania</option>
                                      <option value="RI">Rhode Island</option>
                                      <option value="SC">South Carolina</option>
                                      <option value="SD">South Dakota</option>
                                      <option value="TN">Tennessee</option>
                                      <option value="TX">Texas</option>
                                      <option value="UT">Utah</option>
                                      <option value="VT">Vermont</option>
                                      <option value="VA">Virginia</option>
                                      <option value="WA">Washington</option>
                                      <option value="WV">West Virginia</option>
                                      <option value="WI">Wisconsin</option>
                                      <option value="WY">Wyoming</option>
                                    </select>
                                    <div className="error">
                                      <ErrorMessage
                                        errors={errors}
                                        name="mailst"
                                      >
                                        {({ messages }) =>
                                          messages &&
                                          Object.entries(messages).map(
                                            ([type, message]) => (
                                              <p key={type}>{message}</p>
                                            )
                                          )
                                        }
                                      </ErrorMessage>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-dzip5
                                  js-form-item-dzip5
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-dzip5"
                                    className="control-label"
                                  >
                                    Mailing zip code (xxxxx)
                                  </label>

                                  <input
                                    {...register("mailzip5", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value && hasMailingAddress()
                                          )
                                            return "Mailing zip code (xxxxx) is required.";
                                          return true;
                                        },
                                      },
                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 5 digit zip code.",
                                      },
                                      maxLength: {
                                        value: 5,
                                        message:
                                          "Mailing zip code (xxxxx) field has a maximum length of 5.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.mailzip5}
                                    onChange={handleChange("mailzip5")}
                                    data-drupal-selector="edit-dzip5"
                                    className="form-text form-control"
                                    data-msg-maxlength="Mailing zip code (xxxxx) field has a maximum length of 5."
                                    data-msg-required="Mailing zip code (xxxxx) is required."
                                    type="text"
                                    id="edit-dzip5"
                                    size="60"
                                    maxLength="5"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="mailzip5"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-dzip4
                                  js-form-item-dzip4
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-dzip4"
                                    className="control-label"
                                  >
                                    Mailing zip+4
                                  </label>

                                  <input
                                    {...register("mailzip4", {
                                      minLength: {
                                        value: 4,
                                        message:
                                          "Please enter a 4 digit zip+4 code.",
                                      },
                                      maxLength: {
                                        value: 4,
                                        message:
                                          "Mailing zip+4 (xxxx) field has a maximum length of 4.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.mailzip4}
                                    onChange={handleChange("mailzip4")}
                                    data-drupal-selector="edit-dzip4"
                                    className="form-text form-control"
                                    data-msg-maxlength="Mailing zip+4 field has a maximum length of 4."
                                    data-msg-required="Mailing zip+4 is required."
                                    type="text"
                                    id="edit-dzip4"
                                    size="60"
                                    maxLength="4"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="mailzip4"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-checkbox
                            js-form-type-checkbox
                            form-item-different-billing-address
                            js-form-item-different-billing-address
                            checkbox
                          "
                          >
                            <label
                              htmlFor="edit-different-billing-address"
                              className="control-label option"
                            >
                              <input
                                {...register("different_billing_address")}
                                value={state.enrollData.diffAddress}
                                onChange={handleDiffAddress}
                                className="form-checkbox"
                                data-msg-required="Check this if billing address is different than permanent or mailing address (e.g., third-party bill payer) is required."
                                type="checkbox"
                                id="edit-different-billing-address"
                                checked={state.enrollData.diffAddress}
                              />
                              Check this if billing address is different than permanent or mailing address (e.g., third-party bill payer)
                            </label>
                          </div>
                          <div
                            className="
                            js-webform-states-hidden
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            {...(!state.enrollData.diffAddress && {
                              style: { display: "none" },
                            })}
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Billing address (enter complete information;
                                P.O. Box allowed)
                              </div>
                            </div>

                            <div className="panel-body">
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-10"
                                data-msg-required="This field is required."
                                id="edit-row-10"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-baddress1
                                  js-form-item-baddress1
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-baddress1"
                                    className="control-label"
                                  >
                                    Billing address
                                  </label>

                                  <input
                                    {...register("billingaddress1", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            state.enrollData.diffAddress
                                          )
                                            return "Street address is required.";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.billingaddress1}
                                    onChange={handleChange("billingaddress1")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-baddress1"
                                    className="form-text form-control"
                                    data-msg-maxlength="Billing address field has a maximum length of 30."
                                    data-msg-required="Billing address is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-baddress1"
                                    size="60"
                                    maxLength="30"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingaddress1"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-baddress2
                                  js-form-item-baddress2
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-baddress2"
                                    className="control-label"
                                  >
                                    Billing address 2
                                  </label>

                                  <input
                                    {...register("billingaddress2", {
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Street address 2 field has a maximum length of 30.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.billingaddress2}
                                    onChange={handleChange("billingaddress2")}
                                    pattern="^[-/a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-baddress2"
                                    className="form-text form-control"
                                    data-msg-maxlength="Billing address 2 field has a maximum length of 30."
                                    data-msg-required="Billing address 2 is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-baddress2"
                                    size="60"
                                    maxLength="30"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingaddress2"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-11"
                                data-msg-required="This field is required."
                                id="edit-row-11"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bcity
                                  js-form-item-bcity
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bcity"
                                    className="control-label"
                                  >
                                    Billing city
                                  </label>

                                  <input
                                    {...register("billingcity", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            state.enrollData.diffAddress
                                          )
                                            return "City is required..";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "City field has a maximum length of 25.",
                                      },
                                      pattern: {
                                        value: /^[-/a-zA-Z0-9 -]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.billingcity}
                                    onChange={handleChange("billingcity")}
                                    pattern="^[a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-bcity"
                                    className="form-text form-control"
                                    data-msg-maxlength="Billing city field has a maximum length of 30."
                                    data-msg-required="Billing city is required."
                                    data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-bcity"
                                    size="60"
                                    maxLength="30"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingcity"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-select
                                  js-form-type-select
                                  form-item-bst
                                  js-form-item-bst
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bst"
                                    className="control-label"
                                  >
                                    Billing state
                                  </label>

                                  <div className="select-wrapper">
                                    <select
                                      {...register("billingst", {
                                        validate: {
                                          required: (value) => {
                                            if (
                                              !value &&
                                              state.enrollData.diffAddress
                                            )
                                              return "State is required.";
                                            return true;
                                          },
                                        },
                                      })}
                                      value={state.enrollData.billingst}
                                      onChange={handleChange("billingst")}
                                      data-drupal-selector="edit-bst"
                                      className="form-select form-control"
                                      data-msg-required="Billing state is required."
                                      id="edit-bst"
                                      data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                    >
                                      <option value="">- Select -</option>
                                      <option value="AL">Alabama</option>
                                      <option value="AK">Alaska</option>
                                      <option value="AZ">Arizona</option>
                                      <option value="AR">Arkansas</option>
                                      <option value="CA">California</option>
                                      <option value="CO">Colorado</option>
                                      <option value="CT">Connecticut</option>
                                      <option value="DE">Delaware</option>
                                      <option value="DC">
                                        District of Columbia
                                      </option>
                                      <option value="FL">Florida</option>
                                      <option value="GA">Georgia</option>
                                      <option value="HI">Hawaii</option>
                                      <option value="ID">Idaho</option>
                                      <option value="IL">Illinois</option>
                                      <option value="IN">Indiana</option>
                                      <option value="IA">Iowa</option>
                                      <option value="KS">Kansas</option>
                                      <option value="KY">Kentucky</option>
                                      <option value="LA">Louisiana</option>
                                      <option value="ME">Maine</option>
                                      <option value="MD">Maryland</option>
                                      <option value="MA">Massachusetts</option>
                                      <option value="MI">Michigan</option>
                                      <option value="MN">Minnesota</option>
                                      <option value="MS">Mississippi</option>
                                      <option value="MO">Missouri</option>
                                      <option value="MT">Montana</option>
                                      <option value="NE">Nebraska</option>
                                      <option value="NV">Nevada</option>
                                      <option value="NH">New Hampshire</option>
                                      <option value="NJ">New Jersey</option>
                                      <option value="NM">New Mexico</option>
                                      <option value="NY">New York</option>
                                      <option value="NC">North Carolina</option>
                                      <option value="ND">North Dakota</option>
                                      <option value="OH">Ohio</option>
                                      <option value="OK">Oklahoma</option>
                                      <option value="OR">Oregon</option>
                                      <option value="PA">Pennsylvania</option>
                                      <option value="RI">Rhode Island</option>
                                      <option value="SC">South Carolina</option>
                                      <option value="SD">South Dakota</option>
                                      <option value="TN">Tennessee</option>
                                      <option value="TX">Texas</option>
                                      <option value="UT">Utah</option>
                                      <option value="VT">Vermont</option>
                                      <option value="VA">Virginia</option>
                                      <option value="WA">Washington</option>
                                      <option value="WV">West Virginia</option>
                                      <option value="WI">Wisconsin</option>
                                      <option value="WY">Wyoming</option>
                                    </select>
                                    <div className="error">
                                      <ErrorMessage
                                        errors={errors}
                                        name="billingst"
                                      >
                                        {({ messages }) =>
                                          messages &&
                                          Object.entries(messages).map(
                                            ([type, message]) => (
                                              <p key={type}>{message}</p>
                                            )
                                          )
                                        }
                                      </ErrorMessage>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bzip5
                                  js-form-item-bzip5
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bzip5"
                                    className="control-label"
                                  >
                                    Billing zip code (xxxxx)
                                  </label>

                                  <input
                                    {...register("billingzip5", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            state.enrollData.diffAddress
                                          )
                                            return "Zip code (xxxxx) is required.";
                                          return true;
                                        },
                                      },
                                      minLength: {
                                        value: 5,
                                        message:
                                          "Please enter a 5 digit zip code.",
                                      },
                                      maxLength: {
                                        value: 5,
                                        message:
                                          "Zip code (xxxxx) field has a maximum length of 5.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.billingzip5}
                                    onChange={handleChange("billingzip5")}
                                    data-drupal-selector="edit-bzip5"
                                    className="form-text form-control"
                                    data-msg-maxlength="Billing zip code (xxxxx) field has a maximum length of 5."
                                    data-msg-required="Billing zip code (xxxxx) is required."
                                    type="text"
                                    id="edit-bzip5"
                                    size="60"
                                    maxLength="5"
                                    data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022different_mailing_address\u0022]":{"checked":true}}}'
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingzip5"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bzip4
                                  js-form-item-bzip4
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bzip4"
                                    className="control-label"
                                  >
                                    Mailing zip+4
                                  </label>

                                  <input
                                    {...register("billingzip4", {
                                      minLength: {
                                        value: 4,
                                        message:
                                          "Please enter a 4 digit zip+4 code.",
                                      },
                                      maxLength: {
                                        value: 4,
                                        message:
                                          "Zip+4 (xxxx) field has a maximum length of 4.",
                                      },
                                      pattern: {
                                        value: /^[-/0-9 -]*$/,
                                        message:
                                          "Only numeric characters are allowed.",
                                      },
                                    })}
                                    value={state.enrollData.billingzip4}
                                    onChange={handleChange("billingzip4")}
                                    data-drupal-selector="edit-bzip4"
                                    className="form-text form-control"
                                    data-msg-maxlength="Billing zip+4 field has a maximum length of 4."
                                    data-msg-required="Billing zip+4 is required."
                                    type="text"
                                    id="edit-bzip4"
                                    size="60"
                                    maxLength="4"
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="billingzip4"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            id="edit-google-ua-85545403-2"
                            className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                          ></div> */}
                        </div>

                        <div
                          data-drupal-selector="edit-actions"
                          className="
                          form-actions
                          webform-actions
                          form-group
                          js-form-wrapper
                          form-wrapper
                        "
                          data-msg-required="Submit button(s) is required."
                          id="edit-actions"
                        >
                          {/* <button
                            className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn
                          "
                            data-drupal-selector="edit-actions-wizard-next"
                            data-msg-required="This field is required."
                            type="submit"
                            id="edit-actions-wizard-next"
                            name="op"
                            value="Continue to Step 2"
                          >
                            Continue to Step 2
                          </button> */}

                          <Button
                            className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                            onClick={handlePrevious}
                            //disabled={p1}
                            {...(p1 && {
                              style: { display: "none" },
                            })}
                          >
                            Previous
                          </Button>

                          <Button
                            className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                            onClick={handleNextClick}
                            //disabled={!isDirty || !isValid}
                          >
                            {activeStep === steps.length - 1
                              ? "Finish"
                              : activeStep === steps.length - 2
                              ? "Submit"
                              : "Continue to " +
                                steps[activeStep + 1].toString()}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1_2024;
