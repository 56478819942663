import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import bankInfo from "../../../../assets/files/inline-images/bank-info.png";
import { Button } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import { verifyBankAccount } from "../../../../api/verificationApi";
import updateValidationData from "../../../../state/actions/updateValidationData";

const Step3_2025 = ({
  //values,
  handleChange,
  //setFormValues,
  handleNext,
  handlePrevious,
  activeStep,
  acceptTerms,
  steps,
  //disableEFTOption,
  //setDisableEFTOption,
  //displayBankValidationMessage,
  //setDisplayBankValidationMessage,
  //counter,
  //setCounter,
  
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    trigger,
    getValues,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const { state, actions } = useStateMachine({ updateEnrollData, updateValidationData });

  // console.log(state.enrollData);

  const handlePaymentOption = (e) => {
    let s = e.target.value;
    actions.updateEnrollData({ paymentoption: s, paymentOption: s });
    // console.log(state.enrollData.paymentOption);
  };

  let p1 = activeStep === 0 ? true : false;

  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);

  const handleNextClick = async () => {
    const isValidForm = await trigger(); 

    setDisplayCheckOptionError(false);

    var acctTypeSelect =
      state.enrollData.accttype === "" &&
      state.enrollData.paymentoption === "EFT"
        ? false
        : true;


    if (acctTypeSelect 
      && isValidForm) {  
      //&& a === true) {


      let verified = await verifyAccount();
      console.log('verified: ' + verified);

      if (verified) {
        console.log("Proceed to next screen...");
        handleNext();
      }
    } else {
      if (!acctTypeSelect) {
        setDisplayCheckOptionError(true);
      }
    }
  };

  //Account Verification

  const verifyAccount = async () => {
    let result = true;

    //setDisplayBankValidationMessage(false);    
    actions.updateValidationData({ displayBankValidationMessage: false})

    

    if (state.enrollData.paymentoption === "EFT") {

      result = false;

      //await setCounter(counter + 1);
      actions.updateValidationData({ validationCounter: state.validationData.validationCounter + 1})

      let b = {
        UniqueId: "",
        ServiceFlags: ["Verify"],
        BankAccountEntity: {
          RoutingNumber: state.enrollData.bankroutingnumber,
          AccountNumber: state.enrollData.bankacctnumber,
        },
      };  
      console.log(b);

      try {

        result = await verifyBankAccount(b);

      }
      catch (error) {
        console.log('verifyBankAccoun error: ' + error)      
      }    

      console.log("Verified: " + result + ", Count: " + state.validationData.validationCounter);

      if (result === false) {
        if (state.validationData.validationCounter >= 3) {
          //setDisableEFTOption(true);
          actions.updateValidationData({disableEFTOption : true});

          //setDisplayBankValidationMessage(false);
          actions.updateValidationData({displayBankValidationMessage : false});

          clearBankingInfo();
        } else {
          //setDisplayBankValidationMessage(true);
          actions.updateValidationData({displayBankValidationMessage : true});
        }
      }
    }
    return result;
  };

  const clearBankingInfo = () => {
    actions.updateEnrollData({
      bankroutingnumber: "",
      confirm_bankroutingnumber: "",
      bankacctnumber: "",
      confirm_bankacctnumber: "",
      accountholdername: "",
      bankname: "",
      accttype: "",
    });
  };

  return (
    <>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <article className="webform full clearfix">
                <div className="content">
                  <div
                    className="
                      field
                      field--name-body
                      field--type-text-with-summary
                      field--label-hidden
                      field__item
                    "
                  >
                    <p>
                      <span className="form-required"> </span> indicates
                      required information
                    </p>
                  </div>

                  <div
                    className="
                      field
                      field--name-webform
                      field--type-webform
                      field--label-hidden
                      field__item
                    "
                  >
                    <form
                      className="
                        webform-submission-form
                        webform-submission-add-form
                        webform-submission-mbrx-enroll-2021-form
                        webform-submission-mbrx-enroll-2021-add-form
                        webform-submission-mbrx-enroll-2021-node-234-form
                        webform-submission-mbrx-enroll-2021-node-234-add-form
                        js-webform-disable-autosubmit js-webform-details-toggle
                        webform-details-toggle
                      "
                      data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
                      data-msg-required="This field is required."
                      action="/mbrx-enroll-2021"
                      method="post"
                      id="webform-submission-mbrx-enroll-2021-node-234-add-form"
                      acceptCharset="UTF-8"
                      noValidate="novalidate"
                      data-drupal-form-fields="edit-step-1,edit-step-2,edit-paymentoption-paper,edit-paymentoption-eft,edit-paymentoption-ssa,edit-paymentoption-rrb,edit-accountholdername,edit-bankname,edit-accttype-checking,edit-accttype-savings,edit-bankroutingnumber,edit-confirm-bankroutingnumber,edit-bankacctnumber,edit-confirm-bankacctnumber,edit-actions-wizard-prev,edit-actions-wizard-next"
                    >
                      <div
                        data-webform-key="step_3"
                        data-drupal-selector="edit-step-3"
                        className="js-form-wrapper form-wrapper form-group"
                        data-msg-required="Step 3 is required."
                        id="edit-step-3--2"
                      >
                        <div
                          id="edit-step-3-title"
                          className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                        >
                          <h1>Payment Options</h1>
                        </div>
                        <div
                          className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                          data-drupal-selector="edit-select-payment-options"
                          aria-describedby="edit-select-payment-options--description"
                          data-msg-required="Please select your premium payment option is required."
                          id="edit-select-payment-options"
                        >
                          <div className="panel-heading">
                            <div className="panel-title collapse-link">
                              Please select your premium payment option
                            </div>
                          </div>

                          <div className="panel-body">
                            <p
                              id="edit-select-payment-options--description"
                              className="help-block"
                            >
                              You can pay your monthly plan premium (including
                              any late enrollment penalty that you currently
                              have or may owe) by mail or electronic funds
                              transfer (EFT) each month. You can also choose to
                              pay your premium by having it automatically taken
                              out of your Social Security or Railroad Retirement
                              Board (RRB) benefit each month.
                            </p>
                            <p
                              id="edit-select-payment-options--description2"
                              className="help-block"
                            >  
                            {/* <legend> */}
                            <div className="fieldset-legend js-form-required" style={{ fontSize: '20px' }} >
                            <b>
                              Part D Income Related Monthly Adjustment Amount (IRMAA)
                            </b>
                            </div>
                            {/* </legend>     */}


                              If you have to pay a Part D income-related monthly
                              adjustment amount (IRMAA), you must pay&nbsp;this
                              extra amount in addition to your plan premium. The
                              amount is usually taken out of&nbsp;your Social
                              Security benefit or you may get a bill from
                              Medicare (or the RRB). Do NOT pay MedicareBlue Rx
                              the Part D IRMAA.
                            </p>  
                            <fieldset
                              data-drupal-selector="edit-paymentoption"
                              className="
                                radios--wrapper
                                fieldgroup
                                form-composite
                                webform-composite-visible-title
                                required
                                js-webform-type-radios
                                webform-type-radios
                                js-form-item
                                form-item
                                js-form-wrapper
                                form-wrapper
                              "
                              data-msg-required="Preferred payment method is required."
                              id="edit-paymentoption--wrapper"
                            >
                              <legend>
                                <span
                                  className="
                                    fieldset-legend
                                    js-form-required
                                    form-required
                                  "
                                >
                                  <b>Preferred payment method</b>
                                </span>
                              </legend>
                              <div className="fieldset-wrapper">
                                <div
                                  id="edit-paymentoption"
                                  className="
                                    js-webform-radios
                                    webform-options-display-one-column
                                  "
                                >
                                  <div
                                    className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-paymentoption
                                      js-form-item-paymentoption
                                      radio
                                    "
                                  >
                                    <label
                                      htmlFor="edit-paymentoption-paper"
                                      className="control-label option"
                                    >
                                      <input
                                        {...register("paymentoption", {
                                          required:
                                            "Payment option selection is required.",
                                        })}
                                        onChange={handlePaymentOption}
                                        checked={
                                          state.enrollData.paymentoption ===
                                          "Paper"
                                        }
                                        data-drupal-selector="edit-paymentoption-paper"
                                        className="form-radio"
                                        data-msg-required="Preferred payment method is required."
                                        type="radio"
                                        id="edit-paymentoption-paper"
                                        name="paymentoption"
                                        value="Paper"
                                        required="required"
                                        //aria-required="true"
                                      />
                                      Get a paper bill
                                    </label>
                                  </div>
                                  <div
                                    className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-paymentoption
                                      js-form-item-paymentoption
                                      radio
                                    "
                                  >
                                    <label
                                      htmlFor="edit-paymentoption-eft"
                                      className="control-label option"
                                    >
                                      <input
                                        {...register("paymentoption", {
                                          required:
                                            "Payment option selection is required.",
                                        })}
                                        onChange={handlePaymentOption}
                                        checked={
                                          state.enrollData.paymentoption ===
                                          "EFT"
                                        }
                                        data-drupal-selector="edit-paymentoption-eft"
                                        className="form-radio"
                                        data-msg-required="Preferred payment method is required."
                                        type="radio"
                                        id="edit-paymentoption-eft"
                                        name="paymentoption"
                                        value="EFT"
                                        required="required"
                                        //aria-required="true"
                                      />
                                      Electronic funds transfer (EFT)
                                    </label>
                                  </div>
                                  <div
                                    className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-paymentoption
                                      js-form-item-paymentoption
                                      radio
                                    "
                                  >
                                    <label
                                      htmlFor="edit-paymentoption-ssa"
                                      className="control-label option"
                                    >
                                      <input
                                        {...register("paymentoption", {
                                          required:
                                            "Payment option selection is required.",
                                        })}
                                        onChange={handlePaymentOption}
                                        checked={
                                          state.enrollData.paymentoption ===
                                          "SSA"
                                        }
                                        data-drupal-selector="edit-paymentoption-ssa"
                                        className="form-radio"
                                        data-msg-required="Preferred payment method is required."
                                        type="radio"
                                        id="edit-paymentoption-ssa"
                                        name="paymentoption"
                                        value="SSA"
                                        required="required"
                                        //aria-required="true"
                                      />
                                      Automatic deduction from your Social
                                      Security benefit check
                                    </label>
                                  </div>
                                  <div
                                    className="
                                      form-item
                                      js-form-item
                                      form-type-radio
                                      js-form-type-radio
                                      form-item-paymentoption
                                      js-form-item-paymentoption
                                      radio
                                    "
                                  >
                                    <label
                                      htmlFor="edit-paymentoption-rrb"
                                      className="control-label option"
                                    >
                                      <input
                                        {...register("paymentoption", {
                                          required:
                                            "Payment option selection is required.",
                                        })}
                                        onChange={handlePaymentOption}
                                        checked={
                                          state.enrollData.paymentoption ===
                                          "RRB"
                                        }
                                        data-drupal-selector="edit-paymentoption-rrb"
                                        className="form-radio"
                                        data-msg-required="Preferred payment method is required."
                                        type="radio"
                                        id="edit-paymentoption-rrb"
                                        name="paymentoption"
                                        value="RRB"
                                        required="required"
                                        //aria-required="true"
                                      />
                                      Automatic deduction from your Railroad
                                      Retirement Board (RRB) benefit check
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="paymentoption"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        {/* <div className="js-form-wrapper">
                          <div
                            className="
                              form-item
                              js-form-item
                              form-type-processed-text
                              js-form-type-processed-text
                              form-item-
                              js-form-item-
                              form-no-label form-group
                            "
                            id="edit-ss-and-rbb-payment-description"
                            {...(state.enrollData.paymentOption !== "RRB" &&
                              state.enrollData.paymentOption !== "SSA" && {
                                style: { display: "none" },
                              })}
                          >
                            <h2>
                              Part D Income Related Monthly Adjustment Amount
                              (IRMAA)
                            </h2>

                            <p>
                              If you have to pay a Part D income-related monthly
                              adjustment amount (IRMAA), you must pay&nbsp;this
                              extra amount in addition to your plan premium. The
                              amount is usually taken out of&nbsp;your Social
                              Security benefit or you may get a bill from
                              Medicare (or the RRB). Do NOT pay MedicareBlue Rx
                              the Part D IRMAA.
                            </p>
                          </div>
                        </div> */}

                        <div
                          {...(state.enrollData.paymentOption !== "EFT" && {
                            style: { display: "none" },
                          })}
                        >
                          <div
                            className="
                            js-webform-states-hidden
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            data-drupal-selector="edit-eft-info"
                            aria-describedby="edit-eft-info--description"
                            data-msg-required="Electronic funds transfer (EFT) from your bank account each month (Required *) is required."
                            data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022paymentoption\u0022]":{"value":"EFT"}}}'
                            id="edit-eft-info"
                          >
                            <div className="panel-heading">
                              <div className="panel-title collapse-link">
                                Electronic funds transfer (EFT) from your bank
                                account each month (Required *)
                              </div>
                            </div>

                            <div className="panel-body">
                              <p
                                id="edit-eft-info--description"
                                className="help-block"
                              >
                                It may take up to two months to process your
                                request. Please pay your premiums billed to you
                                on paper until your EFT is active. Any unpaid
                                premiums due when EFT takes effect will be
                                deducted at that time to bring your account
                                up-to-date.
                              </p>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-acc-num"
                                data-msg-required="This field is required."
                                id="edit-row-acc-num"
                              >
                                <div
                                  className="
                                  col-md-12
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-accountholdername
                                  js-form-item-accountholdername
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-accountholdername"
                                    className="control-label form-required"
                                  >
                                    Account holder name
                                  </label>

                                  <input
                                    {...register("accountholdername", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            state.enrollData.paymentOption ===
                                              "EFT"
                                          )
                                            return "Account holder name is required.";
                                          return true;
                                        },
                                      },
                                      maxLength: {
                                        value: 90,
                                        message:
                                          "Account holder name field has a maximum length of 90.",
                                      },
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.accountholdername}
                                    onChange={handleChange("accountholdername")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-accountholdername"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Account holder name field has a maximum length of 90."
                                    // data-msg-required="Account holder name is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-accountholdername"
                                    //name="accountholdername"
                                    //value=""
                                    size="60"
                                    maxLength="90"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="accountholdername"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-fin-ins"
                                data-msg-required="This field is required."
                                id="edit-row-fin-ins"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bankname
                                  js-form-item-bankname
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bankname"
                                    className="control-label form-required"
                                  >
                                    Financial institution
                                  </label>

                                  <input
                                    {...register("bankname", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            state.enrollData.paymentOption ===
                                              "EFT"
                                          )
                                            return "Financial institution name is required.";
                                          return true;
                                        },
                                      },
                                      pattern: {
                                        value: /^[-a-zA-Z0-9 ]*$/,
                                        message:
                                          "Special characters are not allowed.",
                                      },
                                    })}
                                    value={state.enrollData.bankname}
                                    onChange={handleChange("bankname")}
                                    pattern="^[-a-zA-Z0-9 ]*$"
                                    data-webform-pattern-error="Special characters are not allowed."
                                    data-drupal-selector="edit-bankname"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Financial institution field has a maximum length of 50."
                                    // data-msg-required="Financial institution is required."
                                    // data-msg-pattern="Special characters are not allowed."
                                    type="text"
                                    id="edit-bankname"
                                    //name="bankname"
                                    //value=""
                                    size="60"
                                    maxLength="50"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="bankname"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <fieldset
                                  data-drupal-selector="edit-accttype"
                                  className="
                                  col-sm-6 col-md-6
                                  radios--wrapper
                                  fieldgroup
                                  form-composite
                                  webform-composite-visible-title
                                  js-webform-type-radios
                                  webform-type-radios
                                  js-form-item
                                  form-item
                                  js-form-wrapper
                                  form-wrapper
                                "
                                  data-msg-required="Account type is required."
                                  id="edit-accttype--wrapper"
                                  data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022paymentoption\u0022]":{"value":"EFT"}}}'
                                >
                                  <label
                                    htmlFor="edit-bankname"
                                    className="control-label form-required"
                                  >
                                    Account type
                                  </label>

                                  <div className="fieldset-wrapper">
                                    <div
                                      id="edit-accttype"
                                      className="
                                      js-webform-radios
                                      webform-options-display-one-column
                                    "
                                    >
                                      <div
                                        className="
                                        form-item
                                        js-form-item
                                        form-type-radio
                                        js-form-type-radio
                                        form-item-accttype
                                        js-form-item-accttype
                                        radio
                                      "
                                      >
                                        <label
                                          htmlFor="edit-accttype-checking"
                                          className="control-label option"
                                        >
                                          <input
                                            {...register("acctype", {
                                              validate: {
                                                required: (value) => {
                                                  if (
                                                    state.enrollData
                                                      .paymentoption === "" &&
                                                    state.enrollData
                                                      .paymentOption === "EFT"
                                                  )
                                                    return "Please select any account type.";
                                                  return true;
                                                },
                                              },
                                            })}
                                            onChange={handleChange("accttype")}
                                            checked={
                                              state.enrollData.accttype ===
                                              "Checking"
                                            }
                                            data-drupal-selector="edit-accttype-checking"
                                            className="form-radio"
                                            data-msg-required="Account type is required."
                                            type="radio"
                                            id="edit-accttype-checking"
                                            value="Checking"
                                            disabled={state.validationData.disableEFTOption}
                                          />
                                          Checking
                                        </label>
                                      </div>
                                      <div
                                        className="
                                        form-item
                                        js-form-item
                                        form-type-radio
                                        js-form-type-radio
                                        form-item-accttype
                                        js-form-item-accttype
                                        radio
                                      "
                                      >
                                        <label
                                          htmlFor="edit-accttype-savings"
                                          className="control-label option"
                                        >
                                          <input
                                            {...register("acctype", {
                                              validate: {
                                                required: (checked) => {
                                                  if (
                                                    state.enrollData
                                                      .paymentoption === "" &&
                                                    state.enrollData
                                                      .paymentOption === "EFT"
                                                  )
                                                    return "Please select any account type.";
                                                  return true;
                                                },
                                              },
                                            })}
                                            onChange={handleChange("accttype")}
                                            checked={
                                              state.enrollData.accttype ===
                                              "Savings"
                                            }
                                            data-drupal-selector="edit-accttype-savings"
                                            className="form-radio"
                                            data-msg-required="Account type is required."
                                            type="radio"
                                            id="edit-accttype-savings"
                                            value="Savings"
                                            disabled={state.validationData.disableEFTOption}
                                          />
                                          Savings
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="error"
                                    {...(displayCheckOptionError !== true && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Please select any account type.
                                  </div>
                                </fieldset>

                                <div className="error">
                                  <ErrorMessage errors={errors} name="accttype">
                                    {({ messages }) =>
                                      messages &&
                                      Object.entries(messages).map(
                                        ([type, message]) => (
                                          <p key={type}>{message}</p>
                                        )
                                      )
                                    }
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-route-num"
                                data-msg-required="This field is required."
                                id="edit-row-route-num"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bankroutingnumber
                                  js-form-item-bankroutingnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bankroutingnumber"
                                    className="control-label form-required"
                                  >
                                    Bank routing number
                                  </label>

                                  <input
                                    {...register("bankroutingnumber", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            state.enrollData.paymentOption ===
                                              "EFT"
                                          )
                                            return "Bank routing number is required.";
                                          return true;
                                        },
                                      },
                                      minLength: {
                                        value: 9,
                                        message:
                                          "Please enter a 9 digit bank routing number.",
                                      },
                                      maxLength: {
                                        value: 9,
                                        message:
                                          "Bank routing number field has a maximum length of 9.",
                                      },
                                      pattern: {
                                        value: /^[-0-9 ]*$/,
                                        message:
                                          "Please enter numeric values only.",
                                      },
                                    })}
                                    value={state.enrollData.bankroutingnumber}
                                    onChange={handleChange("bankroutingnumber")}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-bankroutingnumber"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Bank routing number field has a maximum length of 9."
                                    // data-msg-required="Bank routing number is required."
                                    // data-msg-pattern="Please enter numeric values only."
                                    type="text"
                                    id="edit-bankroutingnumber"
                                    //name="bankroutingnumber"
                                    //value=""
                                    size="60"
                                    maxLength="9"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="bankroutingnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-confirm-bankroutingnumber
                                  js-form-item-confirm-bankroutingnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-confirm-bankroutingnumber"
                                    className="control-label form-required"
                                  >
                                    Confirm bank routing number
                                  </label>

                                  <input
                                    {...(state.enrollData.paymentOption ===
                                      "EFT" && {
                                      ...register("confirm_bankroutingnumber", {
                                        required:
                                          "Bank routing number is required.",

                                        minLength: {
                                          value: 9,
                                          message:
                                            "Please enter a 9 digit bank routing number.",
                                        },
                                        maxLength: {
                                          value: 9,
                                          message:
                                            "Bank routing number field has a maximum length of 9.",
                                        },
                                        pattern: {
                                          value: /^[-0-9 ]*$/,
                                          message:
                                            "Please enter numeric values only.",
                                        },
                                        validate: () =>
                                          getValues("bankroutingnumber") ===
                                          getValues(
                                            "confirm_bankroutingnumber"
                                          ),
                                      }),
                                    })}
                                    value={
                                      state.enrollData.confirm_bankroutingnumber
                                    }
                                    onChange={handleChange(
                                      "confirm_bankroutingnumber"
                                    )}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-confirm-bankroutingnumber"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Confirm bank routing number field has a maximum length of 9."
                                    // data-msg-required="Confirm bank routing number is required."
                                    // data-msg-pattern="Please enter numeric values only."
                                    type="text"
                                    id="edit-confirm-bankroutingnumber"
                                    //name="confirm_bankroutingnumber"
                                    //value=""
                                    size="60"
                                    maxLength="9"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="confirm_bankroutingnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                  {errors.confirm_bankroutingnumber &&
                                    errors.confirm_bankroutingnumber.type ===
                                      "validate" && (
                                      <div className="error">
                                        The routing numbers do not match.
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div
                                className="
                                row
                                form-group
                                js-form-wrapper
                                form-wrapper
                              "
                                data-drupal-selector="edit-row-bank-num"
                                data-msg-required="This field is required."
                                id="edit-row-bank-num"
                              >
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-bankacctnumber
                                  js-form-item-bankacctnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-bankacctnumber"
                                    className="control-label form-required"
                                  >
                                    Bank account number
                                  </label>

                                  <input
                                    {...(state.enrollData.paymentOption ===
                                      "EFT" && {
                                      ...register("bankacctnumber", {
                                        required:
                                          "Bank account number is required.",

                                        minLength: {
                                          value: 5,
                                          message:
                                            "Please enter a 5-17 digit bank account number.",
                                        },
                                        maxLength: {
                                          value: 17,
                                          message:
                                            "Bank account number field has a maximum length of 17.",
                                        },
                                        pattern: {
                                          value: /^[-0-9 ]*$/,
                                          message:
                                            "Please enter numeric values only.",
                                        },
                                      }),
                                    })}
                                    value={state.enrollData.bankacctnumber}
                                    onChange={handleChange("bankacctnumber")}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-bankacctnumber"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Bank account number field has a maximum length of 17."
                                    // data-msg-required="Bank account number is required."
                                    // data-msg-pattern="Please enter numeric values only."
                                    type="text"
                                    id="edit-bankacctnumber"
                                    //name="bankacctnumber"
                                    //value=""
                                    size="60"
                                    maxLength="17"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="bankacctnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div
                                  className="
                                  col-sm-6 col-md-6
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-confirm-bankacctnumber
                                  js-form-item-confirm-bankacctnumber
                                  form-group
                                "
                                >
                                  <label
                                    htmlFor="edit-confirm-bankacctnumber"
                                    className="control-label form-required"
                                  >
                                    Confirm bank account number
                                  </label>

                                  <input
                                    {...(state.enrollData.paymentOption ===
                                      "EFT" && {
                                      ...register("confirm_bankacctnumber", {
                                        required:
                                          "Bank account number is required.",

                                        validate: () =>
                                          getValues("bankacctnumber") ===
                                          getValues("confirm_bankacctnumber"),

                                        minLength: {
                                          value: 5,
                                          message:
                                            "Please enter a 5-17 digit bank account number.",
                                        },
                                        maxLength: {
                                          value: 17,
                                          message:
                                            "Bank account number field has a maximum length of 17.",
                                        },
                                        pattern: {
                                          value: /^[-0-9 ]*$/,
                                          message:
                                            "Please enter numeric values only.",
                                        },
                                      }),
                                    })}
                                    value={
                                      state.enrollData.confirm_bankacctnumber
                                    }
                                    onChange={handleChange(
                                      "confirm_bankacctnumber"
                                    )}
                                    pattern="\d+"
                                    data-webform-pattern-error="Please enter numeric values only."
                                    data-drupal-selector="edit-confirm-bankacctnumber"
                                    className="form-text form-control"
                                    // data-msg-maxLength="Confirm bank account number field has a maximum length of 17."
                                    // data-msg-required="Confirm bank account number is required."
                                    // data-msg-pattern="Please enter numeric values only."
                                    type="text"
                                    id="edit-confirm-bankacctnumber"
                                    //name="confirm_bankacctnumber"
                                    //value=""
                                    size="60"
                                    maxLength="17"
                                    disabled={state.validationData.disableEFTOption}
                                  />
                                  <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="confirm_bankacctnumber"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                                  {errors.confirm_bankacctnumber &&
                                    errors.confirm_bankacctnumber.type ===
                                      "validate" && (
                                      <div className="error">
                                        The account numbers do not match.
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div
                                id="edit-check-image"
                                className="
                                form-item
                                js-form-item
                                form-type-processed-text
                                js-form-type-processed-text
                                form-item-
                                js-form-item-
                                form-no-label form-group
                              "
                              >
                                <p>
                                  <img
                                    alt="bank info"
                                    data-entity-type="file"
                                    data-entity-uuid="f6717008-cc45-48a0-ada3-687d6fe1cac5"
                                    src={bankInfo}
                                  />
                                </p>
                              </div>
                              {/* Add verification messaging here...*/}
                              {state.validationData.displayBankValidationMessage === true && (
                                <div className="error">
                                  We couldn’t validate your information. Please
                                  review your information and enter it again.
                                </div>
                              )}
                              {state.validationData.disableEFTOption && (
                                <div className="error">
                                  We couldn’t validate your information. Please
                                  choose another payment option from the list.
                                  Select Social Security, Railroad Retirement
                                  Board deduction, or paper bill.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          id="edit-payment-info"
                          className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                        >

                          {/* <legend>
                            <div className="fieldset-legend js-form-required " >
                            <b>
                              Other options
                            </b>
                            </div> */}
                            
                          <h2>Other options</h2>

                          <p>
                            For EFT election at a later time contact customer
                            service at{" "}
                            <strong>
                              <a href="tel:1-888-832-0075">1-888-832-0075</a>
                            </strong>{" "}
                            (TTY:{" "}
                            <strong>
                              <a href="711">711</a>
                            </strong>
                            ) 8 a.m. to 8 p.m., daily, local time. The{" "}
                            <a href="/documents/IndividualPlanDocs2025">
                              EFT form is also available on the documents page
                            </a>
                            . Sign and date the form and send it to the plan.
                            You can also{" "}
                            <a href="/mbrx-change-payment-option">
                              change your payment option online
                            </a>{" "}
                            at any time.
                          </p>
                          {/* </legend> */}
                        </div>
                      </div>

                      <div
                        data-drupal-selector="edit-actions"
                        className="
                          form-actions
                          webform-actions
                          form-group
                          js-form-wrapper
                          form-wrapper
                        "
                        data-msg-required="Submit button(s) is required."
                        id="edit-actions"
                      >
                        <Button
                          className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handlePrevious}
                          //disabled={p1}
                          {...(p1 && {
                            style: { display: "none" },
                          })}
                        >
                          Previous
                        </Button>

                        <Button
                          className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handleNextClick}
                          disabled={activeStep === 5 && !acceptTerms && isValid}
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : activeStep === steps.length - 2
                            ? "Submit"
                            : "Continue to " + steps[activeStep + 1].toString()}
                        </Button>
                        {/* <div
                          className="error"
                          {...(displayCheckOptionError !== true && {
                            style: { display: "none" },
                          })}
                        >
                          At least one item must be selected.
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Step3_2025;
