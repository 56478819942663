import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const WhatsNext = ({ open, handleOpen, handleClose, confNum }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>
              Congratulations, you have completed the online enrollment for
              MedicareBlue Rx.
            </h2>
            <br />
            Your confirmation number is {" " + confNum}
            <br />
            <h3>Here's what to expect next.</h3>
            <ol>
              <li>Your application will be submitted to Medicare.</li>
              <li>
                An acknowledgement letter will be mailed within 10 calendar days
                of application submission. This document states we received your
                application.
              </li>
              <li>
                If you worked with an Agent, a verification letter is mailed
                within 15 calendar days of application submission. This letter
                will have information about MedicareBlue Rx and your enrollment.
              </li>
              <li>
                A confirmation letter is mailed within 10 days of when Medicare
                approves your enrollment.
              </li>
              <li>
                An ID card, will be mailed to you after Medicare confirms your
                enrollment into MedicareBlue Rx.
              </li>
              <li>
                You will receive a MedicareBlue Rx new member Welcome Kit.
              </li>
            </ol>
            <br />
            <p>
              Please note that benefits, formulary, pharmacy network, premium
              and/or copays/coinsurance may change annually. You will be
              notified of any changes in the Annual Notice of Change which you
              will receive by September 30th.
            </p>
            <p>
              If you have any questions or concerns or wish to file a complaint about MedicareBlue Rx please
              contact customer service toll-free at{" "}
              <a href="tel:1-888-832-0075">
                <strong>1-888-832-0075</strong>
              </a>
              . TTY users should call{" "}
              <a href="tel:711">
                <strong>711</strong>
              </a>
              . We're open 8 a.m. to 8 p.m., Central and Mountain Time, Monday to Friday, and 7 days a week from October 1st through March 31st.
            </p>
            <Button
              className="
              webform-button--next
              button
              js-form-submit
              form-submit
              btn-default btn"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default WhatsNext;
