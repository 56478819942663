import React from "react";
import { Link } from "react-router-dom";

const PlanTools = () => {
  return (
    <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
      <h2>
        <Link to="/pharmacy-tools-landing">Plan tools</Link>
      </h2>
      <p>
        Search the drug list, estimate your annual costs and find a pharmacy
        near you with our{" "}
        <Link
          to="/pharmacy-tools-landing"
          title="This will take you to the plan tools page"
        >
          online tools.
        </Link>
      </p>
    </div>
  );
};

export default PlanTools;
