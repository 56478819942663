import { handleResponse, handleError } from "./apiUtils";
const url = process.env.REACT_APP_CONFIRM_API_URL;
const env = process.env.REACT_APP_ENV;

export async function submitConfirmation(confirmation) {

  // 4/8/24 - disable API call for non-production environments
  if (env === 'dev' || env === 'stage') {
    console.log('Verification disabled for environment: ' + env);
    return true;
  }

  try {
    let response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(confirmation),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
