import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";

const DrugPricingTool2021 = () => {
  const [content, isLoading] = useContent("4vgaUisOsZB1ZtQOO6i1Jg");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Drug Pricing Tool MedicareBlue 2021</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">Estimate your costs for 2021</h1>

            <article className="page full clearfix">
              <span
                property="schema:name"
                content="Drug Pricing Tool MedicareBlue 2021"
                className="hidden"
              ></span>

              <div className="content">
                <div
                  property="schema:text"
                  className="field field--name-body field--type-text-with-summary field--label-hidden field__item"
                >
                  <div style={{ width: "10%", float: "right" }}>
                    <Link
                      to="/enroll/2021-enroll-now"
                      className="btn btn-default"
                    >
                      Enroll Now
                    </Link>
                  </div>
                  <br />
                  <table style={{ width: "100%" }} className="t3">
                    <tr>
                      <th scope="col" style={{ textTransform: "none" }}>
                        Plan
                      </th>
                      <th scope="col" style={{ textTransform: "none" }}>
                        Monthly premium
                      </th>
                      <th scope="col" style={{ textTransform: "none" }}>
                        Annual deductible
                      </th>
                    </tr>
                    <tr>
                      <td>MedicareBlue Rx Standard</td>
                      <td>$66.40</td>
                      <td>
                        $0 for drugs on Tiers 1 & 2 &mdash; $445 for drugs on
                        Tiers 3-5
                      </td>
                    </tr>
                    <tr>
                      <td>MedicareBlue Rx Premier</td>
                      <td>$104.70</td>
                      <td>$0 for drugs on all Tiers</td>
                    </tr>
                  </table>

                  <h2>
                    Drug price quotes are estimates and may differ from what you
                    pay at your pharmacy
                  </h2>
                  <iframe
                    id="frameTool"
                    width="100%"
                    height="800px"
                    frameborder="0"
                    src="https://www.medicareplanrx.com/MedicationPricingTool/planInfo.do?lang=en&formularySearch=false&clientId=114&regionId=25&year=2021"
                    scrolling="yes"
                    title="Pricing Tool"
                  ></iframe>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DrugPricingTool2021;
