import React from "react";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import PropTypes from 'prop-types';

const removeCountryCode = (e, onChange) => {
    var data = e ? e.replace("+1", "") : "";
    onChange({target: { value: data}});
};

export default function CSPhoneInput({ name, id, value, onChange, control, rules }) {
//this.removeCountryCode = this.removeCountryCode.bind(this);
return (
<PhoneInput
    name={name}
    id={id}
    value={value.length > 0 ? "+1" + value : ''}
    defaultValue={value.length > 0 ? "+1" + value : ''}            
    // onChange={(e) => removeCountryCode(e, {onChange})}
    onChange={(e) => removeCountryCode(e, onChange)}
    country="US"
    control={control}
    rules={{              
        minLength: {
            value: 12,
            message:
                "Please verify that your phone number is correct.",
        },
        ...rules               
    }}            
    maxLength="14"
    onKeyPress={(e) => {
        if (e.key === "1" && (!{value})) {
            e.preventDefault();
        }
    }}
    onKeyUp={(e) => {
        if (e.target.value == "1") {
            e.target.value = "";
            e.preventDefault();
        }
    }}
    className="
        col-sm-12 col-md-12
        form-item
        js-form-item
        form-type-textfield
        js-form-type-textfield
        form-item-homephone
        js-form-item-homephone
        form-group"
    style={{ border: "1px solid #ccc" }}
/>
)
}

CSPhoneInput.propTypes = {
//name: PropTypes.arrayOf(PropTypes.string).isRequired,
name: PropTypes.string.isRequired,
id: PropTypes.string.isRequired,
value: PropTypes.string,
onChange: PropTypes.func.isRequired,
control: PropTypes.object.isRequired
}

/////////////////////////////////////////
// class implementation of CSPhoneInput
/////////////////////////////////////////
// export default class CSPhoneInput extends Component {
//     constructor(props) {
//         super(props);
//         this.removeCountryCode = this.removeCountryCode.bind(this);
//     }

//     removeCountryCode = (e) => {
//         var data = e ? e.replace("+1", "") : e;
//         (this.props.onChange)({target: { value: data}});
//     };

//     render() {
//         return (
//             <PhoneInput
//                 name={this.props.name}
//                 id={this.props.id}
//                 value={this.props.value}
//                 defaultValue={this.props.value}
//                 onChange={(e) => this.removeCountryCode(e)}
//                 country="US"
//                 control={this.props.control}
//                 rules={{
//                     minLength: {
//                         value: 12,
//                         message:
//                             "Phone number must have a minimum length of 10.",
//                     },
//                     ...this.props.rules
//                 }}
//                 maxLength="14"
//                 onKeyPress={(e) => {
//                     if (e.key === "1" && (!this.props.value)) {
//                         e.preventDefault();
//                     }
//                 }}
//                 onKeyUp={(e) => {
//                     if (e.target.value == "1") {
//                         e.target.value = "";
//                         e.preventDefault();
//                     }
//                 }}
//                 className="
//                 col-sm-12 col-md-12
//                 form-item
//                 js-form-item
//                 form-type-textfield
//                 js-form-type-textfield
//                 form-item-homephone
//                 js-form-item-homephone
//                 form-group"
//                 style={{ border: "1px solid #ccc" }}
//             />
//         );
//     }
// }

