const searchMap = [
  { entryId: "3p0Gk0uSB0A0iK7v3MRwYQ", route: "/About" },
  {
    entryId: "UcnFsk5ihuwAP0iAwYGNy",
    route: "/compare-plans/about-star-ratings",
  },
  { entryId: "7o5unCXiSCCfAhy0i3rd8m", route: "/StarRatings" },
  // { entryId: "1KtYVPe0Hhz4eff8RcV5u0", route: "/Enroll/2021-change-plan" },
  { entryId: "5iV4mT8I7QZ8Or7QTVxww6", route: "/Enroll/2022-change-plan" },
  { entryId: "5iV4mT8I7QZ8Or7QTVxww6", route: "/compare-plans" },
  { entryId: "5iV4mT8I7QZ8Or7QTVxww6", route: "/Compare" },
  { entryId: "9cZ7O6r99wOvwL6tTPBPc", route: "/contact/medicareblue-rx" },
  {
    entryId: "2USLxOYUWeLhMljiTFR8eH",
    route: "/Contact/ContactEMTMHealthCarePro",
  },
  {
    entryId: "4PSqu3Cp1Rb7WDTQt7C57o",
    route: "/Members/coverage-determinations",
  },
  { entryId: "5i25FrNSkTWZ6M5U1fTz1h", route: "/Members/DrugClaims" },
  { entryId: "6X4FycIDkiDfa2t4KD8qx9", route: "/Members/drug-information" },
  // {
  //   entryId: "4vgaUisOsZB1ZtQOO6i1Jg",
  //   route: "/DrugPricingTool/DrugPricingTool2021",
  // },
  {
    entryId: "1O21i5gpbvf3wEXWxE0XPA",
    route: "/DrugPricingTool/DrugPricingTool2022",
  },
  {
    entryId: "1GR5p2VCGKL7TAPicheTtP",
    route: "/PartDBasics/EligibilityRequirements",
  },
  // {
  //   entryId: "1LEhCOxtjeyBCaeXzqqA49",
  //   route: "/EmployerGroupPlans/EmployerGroupDocs2021",
  // },
  {
    entryId: "6DVdSDQmTfHC7GTaGF3HmU",
    route: "/EmployerGroupPlans/EmployerGroupDocs2022",
  },
  { entryId: "6DVdSDQmTfHC7GTaGF3HmU", route: "/GroupDocuments" },
  { entryId: "RZbMYynXLvgnBqbUzuEOn", route: "/Members/emtm-program" },
  { entryId: "3W3TvWhTOWlBcdzR0H0GLB", route: "/Members/emtm-providers" },
  { entryId: "5Whhds7wa9Nt7Pn1AYsXi9", route: "/enroll-0" },
  // { entryId: "3PVGmMWFsZ3t9Ou8XVgWEo", route: "/enroll/2021-enroll-now" },
  { entryId: "1c7TJYgvijUC5NpYqHvFf7", route: "/enroll/2022-enroll-now" },
  {
    entryId: "6V6O9BdZhzrkvKpeb9CbRC",
    route: "/part-d-basics/frequently-asked-questions",
  },
  {
    entryId: "6Dn50diNsKqSRkQNVuda5X",
    route: "/PartDBasics/FinancialAssistance",
  },
  // { entryId: "4vVFX3e5Z5wcI2fmaOf1Zw", route: "/GroupPharmacy2021" },
  { entryId: "6OnQKkBRllRcZXEjVEMRSj", route: "/GroupPharmacy2022" },
  // {
  //   entryId: "msB4SEw35wgxsc2vrF5kZ",
  //   route: "/Pharmacy/FindMyPharmacyPremier2021",
  // },
  {
    entryId: "2NdKnotEYWTYf8X3gYLTzD",
    route: "/Pharmacy/FindMyPharmacyPremier2022",
  },
  // {
  //   entryId: "t5NDkOHfmHyaqNHpNzqA6",
  //   route: "/Pharmacy/FindMyPharmacyStandard2021",
  // },
  {
    entryId: "5OhsbSff4DGvAeu2w0pZDh",
    route: "/Pharmacy/FindMyPharmacyStandard2022",
  },
  { entryId: "6p9FLrFQ5UermwrC2e2pad", route: "/EmployerGroupPlans/FWA" },
  { entryId: "3Mcv3CcC9LLsbwzdhG4H04", route: "/PartDBasics/Glossary" },
  { entryId: "1xtBvtuImmvt35OLMua1R6", route: "/GroupBenefits" },
  { entryId: "1xtBvtuImmvt35OLMua1R6", route: "/GroupPlans" },
  {
    entryId: "USzIB0BoIaKp2NczaOvEZ",
    route: "/employer-group-plans/group-contact-information",
  },
  {
    entryId: "6tZxmxSr3ozwXKzgqIdbAu",
    route: "/EmployerGroupPlans/GroupPlanResearchTools",
  },
  { entryId: "6tZxmxSr3ozwXKzgqIdbAu", route: "/GroupPharmacy" },
  {
    entryId: "qrZLENZv9OiR3Yr2NJbrd",
    route: "/EmployerGroupPlans/GroupResources",
  },
  { entryId: "2USLxOYUWeLhMljiTFR8eH", route: "/" },
  // {
  //   entryId: "5EU1FMmEEL9TGRhG3QpcDe",
  //   route: "/Documents/IndividualPlanDocs2021",
  // },
  {
    entryId: "5t1qNxajZH4LwhkohkSzIE",
    route: "/Documents/IndividualPlanDocs2022",
  },
  { entryId: "5t1qNxajZH4LwhkohkSzIE", route: "/Documents" },
  {
    entryId: "1R2p1RPi9zxo0rTbfqXShL",
    route: "/Members/MailOrderPrescriptions",
  },
  { entryId: "3xP59cK7p2RHNqVUmtsgkG", route: "/Learn/MedicareBlueFWA" },
  {
    entryId: "549eH4mJm0EZIBykjsqM3V",
    route: "/Members/medication-therapy-management-program",
  },
  { entryId: "549eH4mJm0EZIBykjsqM3V", route: "/MTM" },
  { entryId: "59D1gd94uY3S8TgRC6ScIz", route: "/Members/MemberDiscounts" },
  { entryId: "34gjKLMCdhXa824QDIs8pg", route: "/Members" },
  {
    entryId: "37bVLiq0KoGK4QBkIk95eM",
    route: "/Members/OrderMemberIdCard",
  },
  {
    entryId: "1h46JzmJSbaDReNab4f0nt",
    route: "/Compare/OtherMedicarePlans",
  },
  { entryId: "6C5lKw1w5UJkgrqVyeU04", route: "/PartDBasics" },
  {
    entryId: "5VX9sdI6rIliSVPqvNRkNn",
    route: "/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups",
  },
  { entryId: "JEAWqkqARwAMORfXdA02k", route: "/Members/PaymentInfo" },
  { entryId: "1DoywUM2BUk2cCVBrRPtn3", route: "/Members/PharmacyInfo" },
  { entryId: "4YVKtuXpuWSdTOLNSzzPlB", route: "/pharmacy-tools-landing" },
  { entryId: "4YVKtuXpuWSdTOLNSzzPlB", route: "/PharmacyTools" },
  { entryId: "4YVKtuXpuWSdTOLNSzzPlB", route: "/PlanTools" },
  { entryId: "4YVKtuXpuWSdTOLNSzzPlB", route: "/Drugs" },
  { entryId: "4YVKtuXpuWSdTOLNSzzPlB", route: "/Pharmacy" },
  // { entryId: "5E1PDOGua7jm7ICoaNY1Ub", route: "/Compare/PlanCompare2021" },
  { entryId: "4V4gJxP9FKUpBqAWp5EJAz", route: "/Compare/PlanCompare2022" },
  {
    entryId: "6p3KhORt9zC3sWcUoLmdLV",
    route: "/Members/PlanTransitionSupply",
  },
  { entryId: "3iRuctNz3g52JN5R3xK7oZ", route: "/Privacy" },
  { entryId: "1LT97gsinu17jvrWot1E1N", route: "/Members/SubmitClaim" },
  { entryId: "3WlB0mU144lKmLiT6Q9jz2", route: "/TermsOfUse" },
  { entryId: "5BToDkUGz8oJ1fhXY1v9ao", route: "/PartDBasics/WhenToEnroll" },
];

export default searchMap;
