import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import extLinkIcon from "../../assets/files/inline-images/external_link.jpg";

const IndividualPlanDocs2024 = () => {
  const [content, isLoading] = useContent("PyXc1BJMq0Ce6g27XsZ0V");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/Documents/IndividualPlanDocs2022">
                            {" "}
                            Documents{" "}
                          </Link>
                        </li>
                        <li>2024 Individual plan documents</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content.headline}
              />
            </h1>

            <article className="standard full clearfix">
              <div className="content">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={content.copy}
                />
              </div>
            </article>
          </div>
        </section>

        {/* <aside className="col-sm-4 col-md-3 side-section order-md-1">
          <div className="region region-sidebar-second">
            <ul className="menu menu--main nav navbar-nav">
              <li className="med-2020-plan-comparison">
                <Link to="/Compare/PlanCompare2024">2024 Plan comparison</Link>
              </li>
              <li className="med-2020-plan-comparison">
                <Link to="/Compare/PlanCompare2022">2022 Plan comparison</Link>
              </li>
              <li className="med-coverage-and-pricing-tools">
                <Link to="/pharmacy-tools-landing">
                  Coverage and pricing tools
                </Link>
              </li>
              <li className="med-other-medicare-plans">
                <Link to="/Compare/OtherMedicarePlans">
                  Other Medicare plans
                </Link>
              </li>
              <li className="last med-star-rating">
                <Link to="/compare-plans/about-star-ratings">Star Rating</Link>
              </li>
            </ul>
          </div>
        </aside> */}
      </div>
    </div>
  );
};

export default IndividualPlanDocs2024;
