import ReactGA4 from "react-ga4";
import { get_domain_from_url } from "./utils";

export function sendGAExternalLinkEvent (url) {

    let domain = get_domain_from_url(url)

    ReactGA4.event({
        category: 'External Links',
        action: domain,
        label: url,
    });
  };

