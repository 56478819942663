import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PDF from "../../assets/files/S5743_Notice_of_Rights_pre_enroll-508.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = {
  background: "rgb(230, 230, 230)",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  margin: "0px",
};

const S5743NoticeOfRightsPreEnroll508 = () => {
  const [numPages, setNumPages] = useState(2);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div style={style}>
      <Document file={PDF}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
};

export default S5743NoticeOfRightsPreEnroll508;
