import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import CSDateInput from "../../../Common/CSDateInput";

const Step2_2024 = ({
  handleChange,
  handleNext,
  handlePrevious,
  activeStep,
  steps,
}) => {
  const { state, actions } = useStateMachine({ updateEnrollData });

  // console.log(state.enrollData);

  const { 
    register, 
    control,
    formState: { errors }, 
    trigger
  } = useForm({    
  mode: "all",
  reValidateMode: "onBlur",
});

  //console.log('state.enrollData.sepltcdate:' + state.enrollData.sepltcdate);

  const handleAep = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ aep: s, isAep: !state.enrollData.isAep });
  };


  const handleLtc = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ sepltc: s, ltc: !state.enrollData.ltc });
  };

  const handleExtraHelp = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepextrahelp: s,
      extraHelp: !state.enrollData.extraHelp,
    });
  };

  const handleMedicarePremCoverage = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepmedicarepremcoverage: s,
      medicarePremCoverage: !state.enrollData.medicarePremCoverage,
    });
  };

  const handleMedicaid = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepmedicaid: s,
      medicaid: !state.enrollData.medicaid,
    });
  };

  const handleEmergency = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepemergency: s,
      emergency: state.enrollData.emergency,
    });
  };

  const handleOther = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ sepother: s, other: !state.enrollData.other });
  };

  const handleSeppap = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ seppap: s, pap: !state.enrollData.pap });
  };

  let p1 = activeStep === 0 ? true : false;

  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);

  const handleNextClick = async () => {
    
    const isValidForm = await trigger();  


    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    
    var checkedOne = Array.prototype.slice
      .call(checkboxes)
      .some((x) => x.checked);


    if (isValidForm && checkedOne) { // workaround - isValid isn't working as expected - see more info here - https://github.com/react-hook-form/react-hook-form/issues/2755
    //if (isValid && checkedOne) {
      handleNext();
    } else {
      if (!checkedOne) setDisplayCheckOptionError(true);
    }

     //console.log(errors);
  };

  //console.log('sepltcdate:' + state.enrollData.sepltcdate);

  return (
    <>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <div data-drupal-messages-fallback="" className="hidden"></div>
              <article className="webform full clearfix">
                <div className="content">
                  <div
                    className="
                      field
                      field--name-body
                      field--type-text-with-summary
                      field--label-hidden
                      field__item
                    "
                  >
                    <p>
                      <span className="form-required"> </span> indicates
                      required information
                    </p>
                  </div>

                  <div
                    className="
                      field
                      field--name-webform
                      field--type-webform
                      field--label-hidden
                      field__item
                    "
                  >
                    <form
                      className="
                        webform-submission-form
                        webform-submission-add-form
                        webform-submission-mbrx-enroll-2021-form
                        webform-submission-mbrx-enroll-2021-add-form
                        webform-submission-mbrx-enroll-2021-node-234-form
                        webform-submission-mbrx-enroll-2021-node-234-add-form
                        js-webform-disable-autosubmit js-webform-details-toggle
                        webform-details-toggle
                      "
                      data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
                      data-msg-required="This field is required."
                      action="/mbrx-enroll-2021"
                      method="post"
                      id="webform-submission-mbrx-enroll-2021-node-234-add-form"
                      acceptCharset="UTF-8"
                      noValidate="noValidate"
                      data-drupal-form-fields="edit-step-1,edit-aep,edit-sepnewtomedicare,edit-effectivedate-month,edit-effectivedate-day,edit-effectivedate-year,edit-sepnewpartbdate-month,edit-sepnewpartbdate-day,edit-sepnewpartbdate-year,edit-sepserviceareamove,edit-sepserviceareamovedate,edit-sepotherdate-month,edit-sepotherdate-day,edit-sepotherdate-year,edit-sepltc,edit-sepltcdate,edit-sepreturn2us,edit-sepreturn2usdate,edit-seplostcoverage,edit-seplostcoveragedate,edit-sepotherdate3-month,edit-sepotherdate3-day,edit-sepotherdate3-year,edit-sepdisenrlsnp,edit-sepdisenrlsnpdate,edit-sepnonrenewal,edit-sepnonrenewaldate,edit-seplostpdppartb,edit-seplostpdppartbdate,edit-sepmaoep,edit-sepleavingempcoverage,edit-sepleavingempcoveragedate,edit-sepotherdate2-month,edit-sepotherdate2-day,edit-sepotherdate2-year,edit-sepleavingma,edit-sepleavingmadate,edit-sepdisenrollmcp,edit-sepdisenrollmcpdate,edit-sepextrahelp,edit-sepextrahelpdate,edit-sepmedicarepremcoverage,edit-sepmedicaid,edit-sepmedicaiddate,edit-seplawfulpresence,edit-seplawfulpresencedate,edit-sepchoosedifferent,edit-sepchoosedifferentdate,edit-sepemergency,edit-sepincarceration,edit-sepincarcerationdate,edit-sepother,edit-sepothertxt,edit-actions-wizard-prev,edit-actions-wizard-next"
                    >
                      <div
                        id="edit-step-2-title"
                        className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                      >
                        <h1>Enrollment Details</h1>
                      </div>
                      <div
                        id="edit-step-2-instructions"
                        className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                      >
                        <p>
                          <strong>
                            Typically, you may enroll in a Medicare prescription
                            drug plan only during the annual enrollment period
                            from October 15 through December 7 of each year.
                          </strong>{" "}
                          Additionally, there are exceptions that may allow you
                          to enroll in a Medicare prescription drug plan outside
                          of the annual enrollment period. Please read the
                          following statements carefully and check the box if
                          the statement applies to you. By checking any of the
                          following boxes you are certifying that, to the best
                          of your knowledge, you are eligible for an enrollment
                          period. If we later determine that this information is
                          incorrect, you may be disenrolled.
                        </p>

                        <p>
                          <strong>
                            Note: A choice of effective dates is only allowed in
                            the enrollment situations identified below.
                          </strong>{" "}
                          In all other cases, or if you do not specify an
                          effective date, your effective date will be the first
                          of the month after your form is received by the plan.
                        </p>
                      </div>
                      <br />
                      {/* AEP */}
                      {/* <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-aep
                                js-form-item-aep
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-aep"
                          className="control-label option"
                        >
                          <input
                            {...register("aep")}
                            value={state.enrollData.aep}
                            onChange={handleAep}
                            data-drupal-selector="edit-aep"
                            className="form-checkbox"
                            data-msg-required="I am enrolling during the annual enrollment period, October 15 through December 7, for a January 1, 2024 effective date. (Note: The enrollment application must be received by December 7 for the enrollment to be effective on January 1.) is required."
                            type="checkbox"
                            id="edit-aep"
                            {...(state.enrollData.isAep && { checked: true })}
                          />
                          I am enrolling during the annual enrollment period,
                          October 15 through December 7, for a January 1, 2024
                          effective date. (Note: the enrollment application must
                          be received by December 7 for the enrollment to be
                          effective on January 1.)
                        </label>
                      </div> 
                      <br /> */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                          className="panelSummary"
                        >
                          <h3>I am moving or have moved</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {/* LTC */}
                            <div
                              className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepltc
                                js-form-item-sepltc
                                checkbox
                              "
                            >
                              <label
                                htmlFor="edit-sepltc"
                                className="control-label option"
                              >
                                <input
                                  {...register("sepltc")}
                                  value={state.enrollData.sepltc}
                                  onChange={handleLtc}
                                  data-drupal-selector="edit-sepltc"
                                  className="form-checkbox"
                                  data-msg-required="I live in or recently moved out of a long-term care facility (for example, a nursing home or long-term care facility). is required."
                                  type="checkbox"
                                  id="edit-sepltc"
                                  checked={state.enrollData.sepltc === "Y"}
                                />
                                I live in or recently moved out of a long-term
                                care facility (for example, a nursing home or
                                long-term care facility).
                              </label>
                            </div>
                            <div
                              className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepltcdate
                                js-form-item-sepltcdate
                                form-group
                              "
                              {...(!state.enrollData.ltc && {
                                style: { display: "none" },
                              })}
                            >
                              <h4>
                                I moved/will move into/out of the facility on
                              </h4>

                              

                              <div className="input-group">                                
                                {/* <span> className="input-group-addon"> */}
                                <span>
                                  <div className="date_wrap_div">                                      
                                      <CSDateInput
                                          name="sepltcdate"
                                          rules={{                                             
                                            //validate: value => { return (!!value || !state.enrollData.ltc) || "Date required.";},
                                            validate: {
                                              requiredValidation:  value => { if (state.enrollData.ltc && !value) return "I moved/will move into/out of the facility on is required."; return true }
                                            }
                                          }}  
                                          control={control}
                                          defaultValue={state.enrollData.sepltcdate}  // needed for initial form validation 
                                          value={state.enrollData.sepltcdate}
                                          onChange={handleChange("sepltcdate")}
                                          className="form-text
                                          required
                                          form-control"                                             
                                      /> 
                                      {/* </div> */}

                                      
                                  </div>
                                  
                                </span>
                                
                              </div>
                              <div className="error">
                                          <ErrorMessage errors={errors} name="sepltcdate">
                                            {({ messages }) =>
                                              messages &&
                                              Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message}</p>
                                              ))
                                            }
                                          </ErrorMessage>
                                    
                                          {/* <span className="input-group-addon"></span> */}
                                      </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                          className="panelSummary"
                        >
                          <h3>I lost or am losing my coverage or Extra Help</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {/******************************* 
                              Disenrollment/Coverage Changes
                            *********************************/}

                            {/* Change */}
                            <div
                              className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepextrahelp
                                  js-form-item-sepextrahelp
                                  checkbox
                                "
                            >
                              <label
                                htmlFor="edit-sepextrahelp"
                                className="control-label option"
                              >
                                <input
                                  {...register("sepextrahelp")}
                                  value={state.enrollData.sepextrahelp}
                                  onChange={handleExtraHelp}
                                  data-drupal-selector="edit-sepextrahelp"
                                  className="form-checkbox"
                                  data-msg-required="I recently had a change in my Extra Help paying for Medicare prescription drug coverage (newly got Extra Help, had a change in the level of Extra Help, or lost Extra Help) is required."
                                  type="checkbox"
                                  id="edit-sepextrahelp"
                                  checked={
                                    state.enrollData.sepextrahelp === "Y"
                                  }
                                />
                                I recently had a change in my Extra Help paying
                                for Medicare prescription drug coverage (newly
                                got Extra Help, had a change in the level of
                                Extra Help, or lost Extra Help)
                              </label>
                            </div>
                            <div
                              data-drupal-states='{"visible":{":input[name=\u0022sepextrahelp\u0022]":{"checked":true}}}'
                              className="
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  js-webform-states-hidden
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-sepextrahelpdate
                                  js-form-item-sepextrahelpdate
                                  form-group
                                "
                              {...(!state.enrollData.extraHelp && {
                                style: { display: "none" },
                              })}
                            >
                              <label
                                htmlFor="edit-sepextrahelpdate"
                                className="control-label"
                              >
                                Date of change
                              </label>

                              <div className="input-group">
                                {/* <span className="input-group-addon"> */}
                                <span>
                                  <div className="date_wrap_div">

                                      <CSDateInput
                                              name="sepextrahelpdate"
                                              rules={{ 
                                                validate: {
                                                  requiredValidation:  value => { if (state.enrollData.extraHelp && !value) return "Date of change is required."; return true }
                                                }
                                              }} 
                                              control={control}
                                              defaultValue={state.enrollData.sepextrahelpdate}  // needed for initial form validation 
                                              value={state.enrollData.sepextrahelpdate}
                                              onChange={handleChange("sepextrahelpdate")}
                                              className="form-text
                                              required
                                              form-control"                                             
                                          />
                                      {/* </div> */}


                                  </div>
                                </span>
                              </div>
                              <div className="error">
                                          <ErrorMessage errors={errors} name="sepextrahelpdate">
                                            {({ messages }) =>
                                              messages &&
                                              Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message}</p>
                                              ))
                                            }
                                          </ErrorMessage>
                                    
                                          {/* <span className="input-group-addon"></span> */}
                                      </div>
                            </div>

                            {/* Medicaid Change */}
                            <div
                              className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepmedicaid
                                  js-form-item-sepmedicaid
                                  checkbox
                                "
                            >
                              <label
                                htmlFor="edit-sepmedicaid"
                                className="control-label option"
                              >
                                <input
                                  {...register("sepmedicaid")}
                                  value={state.enrollData.sepmedicaid}
                                  onChange={handleMedicaid}
                                  data-drupal-selector="edit-sepmedicaid"
                                  className="form-checkbox"
                                  data-msg-required="I recently had a change in my Medicaid (newly got Medicaid, had a change in level of Medicaid assistance, or lost Medicaid.) is required."
                                  type="checkbox"
                                  id="edit-sepmedicaid"
                                  checked={state.enrollData.sepmedicaid === "Y"}
                                />
                                I recently had a change in my Medicaid (newly
                                got Medicaid, had a change in level of Medicaid
                                assistance, or lost Medicaid.)
                              </label>
                            </div>
                            <div
                              data-drupal-states='{"visible":{":input[name=\u0022sepmedicaid\u0022]":{"checked":true}}}'
                              className="
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  js-webform-states-hidden
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-sepmedicaiddate
                                  js-form-item-sepmedicaiddate
                                  form-group
                                "
                              {...(!state.enrollData.medicaid && {
                                style: { display: "none" },
                              })}
                            >
                              <label
                                htmlFor="edit-sepmedicaiddate"
                                className="control-label"
                              >
                                Date of change
                              </label>

                              <div className="input-group">
                                {/* <span className="input-group-addon"> */}
                                <span>
                                  <div className="date_wrap_div">
                                      <CSDateInput
                                          name="sepmedicaiddate"
                                          rules={{ 
                                            validate: {
                                              requiredValidation:  value => { if (state.enrollData.medicaid && !value) return "Date of change is required."; return true }
                                            }
                                          }}  
                                          control={control}
                                          defaultValue={state.enrollData.sepmedicaiddate}  // needed for initial form validation 
                                          value={state.enrollData.sepmedicaiddate}
                                          onChange={handleChange("sepmedicaiddate")}
                                          className="form-text
                                          required
                                          form-control"                                             
                                      />
                              


                                  </div>
                                </span>
                              </div>
                              <div className="error">
                                          <ErrorMessage errors={errors} name="sepmedicaiddate">
                                            {({ messages }) =>
                                              messages &&
                                              Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message}</p>
                                              ))
                                            }
                                          </ErrorMessage>
                                    
                                          {/* <span className="input-group-addon"></span> */}
                                      </div>
                            </div>

                            {/* Medicare/Medicaid No Change */}
                            <div
                              className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepmedicarepremcoverage
                                  js-form-item-sepmedicarepremcoverage
                                  checkbox
                                "
                            >
                              <label
                                htmlFor="edit-sepmedicarepremcoverage"
                                className="control-label option"
                              >
                                <input
                                  {...register("sepmedicarepremcoverage")}
                                  value={
                                    state.enrollData.sepmedicarepremcoverage
                                  }
                                  onChange={handleMedicarePremCoverage}
                                  data-drupal-selector="edit-sepmedicarepremcoverage"
                                  className="form-checkbox"
                                  data-msg-required="I have both Medicare and Medicaid (or my state helps pay for my Medicare premiums) or I get Extra Help paying for my Medicare prescription drug coverage, but I haven’t had a change) is required."
                                  type="checkbox"
                                  id="edit-sepmedicarepremcoverage"
                                  checked={
                                    state.enrollData.sepmedicarepremcoverage ===
                                    "Y"
                                  }
                                />
                                I have both Medicare and Medicaid (or my state
                                helps pay for my Medicare premiums) or I get
                                Extra Help paying for my Medicare prescription
                                drug coverage, but I haven’t had a change)
                              </label>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5a-content"
                          id="panel5a-header"
                          className="panelSummary"
                        >
                          <h3>I have other coverage (and other reasons)</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {/******************************* 
                              Other
                            *********************************/}

                            {/* MT Big Sky */}
                            <div
                              className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepmedicarepremcoverage
                                  js-form-item-sepmedicarepremcoverage
                                  checkbox
                                "
                              {...(state.enrollData.permstate !== "MT" && {
                                style: { display: "none" },
                              })}
                            >
                              <label
                                htmlFor="edit-sepmedicarepremcoverage"
                                className="control-label option"
                              >
                                <input
                                  {...register("seppap")}
                                  value={state.enrollData.pap}
                                  onChange={handleSeppap}
                                  data-drupal-selector="edit-seppap"
                                  className="form-checkbox"
                                  type="checkbox"
                                  id="edit-seppap"
                                  {...(state.enrollData.pap && {
                                    checked: true,
                                  })}
                                />
                                I belong to Big Sky Rx (a state pharmaceutical
                                assistance program) provided by the state of
                                Montana
                              </label>
                            </div>

                            {/* Emergency/Natural Disaster */}
                            <div
                              className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepemergency
                                  js-form-item-sepemergency
                                  checkbox
                                "
                            >
                              <label
                                htmlFor="edit-sepemergency"
                                className="control-label option"
                              >
                                <input
                                  {...register("sepemergency")}
                                  value={state.enrollData.sepemergency}
                                  onChange={handleEmergency}
                                  data-drupal-selector="edit-sepemergency"
                                  className="form-checkbox"
                                  data-msg-required="I was affected by a weather-related emergency or major disaster (as declared by the Federal Emergency Management Agency (FEMA)). One of the other statements here applied to me, but I was unable to make my enrollment because of the natural disaster. is required."
                                  type="checkbox"
                                  id="edit-sepemergency"
                                  checked={
                                    state.enrollData.sepemergency === "Y"
                                  }
                                />
                                I was affected by a weather-related emergency or
                                major disaster (as declared by the Federal
                                Emergency Management Agency (FEMA)). One of the
                                other statements here applied to me, but I was
                                unable to make my enrollment because of the
                                natural disaster.
                              </label>
                            </div>

                            {/* Other */}
                            <div
                              className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepother
                                  js-form-item-sepother
                                  checkbox
                                "
                            >
                              <label
                                htmlFor="edit-sepother"
                                className="control-label option"
                              >
                                <input
                                  {...register("sepother")}
                                  value={state.enrollData.sepother}
                                  onChange={handleOther}
                                  data-drupal-selector="edit-sepother"
                                  className="form-checkbox"
                                  data-msg-required="Other special enrollment period not identified above is required."
                                  type="checkbox"
                                  id="edit-sepother"
                                  checked={state.enrollData.sepother === "Y"}
                                />
                                Other special enrollment period not identified
                                above
                              </label>
                            </div>
                            <div
                              className="
                                  js-webform-states-hidden
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-sepothertxt
                                  js-form-item-sepothertxt
                                  form-group
                                "
                              {...(!state.enrollData.other && {
                                style: { display: "none" },
                              })}
                            >
                              <label
                                htmlFor="edit-sepothertxt"
                                className="control-label"
                              >
                                Reason
                              </label>

                              <input
                                {...register("sepothertxt", {
                                  validate: {
                                    requiredValidation:  value => { if (state.enrollData.other && !value) return "Reason is required."; return true }
                                  }}
                                )}
                                value={state.enrollData.sepothertxt}
                                onChange={handleChange("sepothertxt")}
                                pattern="^[-a-zA-Z0-9 ]*$"
                                data-webform-pattern-error="Special characters are not allowed."
                                data-drupal-selector="edit-sepothertxt"
                                className="form-text form-control"
                                data-msg-maxlength="Reason field has a maximum length of 255."
                                data-msg-required="Reason is required."
                                data-msg-pattern="Special characters are not allowed."
                                type="text"
                                id="edit-sepothertxt"
                                //name="sepothertxt"
                                //value=""
                                size="60"
                                maxLength="255"
                                data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022sepother\u0022]":{"checked":true}},"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022sepother\u0022]":{"checked":true}}}'
                              />
                              <div className="error">
                                <ErrorMessage errors={errors} name="sepothertxt">
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                      <p key={type}>{message}</p>
                                    ))
                                }
                              </ErrorMessage>
                        </div> 
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <br />
                      <div
                        id="edit-no-statements-apply"
                        className="
                              form-item
                              js-form-item
                              form-type-processed-text
                              js-form-type-processed-text
                              form-item-
                              js-form-item-
                              form-no-label form-group
                            "
                      >
                        <div
                          className="
                                form-item
                                webform-component webform-component-markup
                              "
                          id="webform-component-no-statements-apply"
                        >
                          <p>
                            If none of these statements apply to you or you're
                            not sure, please contact a MedicareBlue Rx Medicare
                            Solutions specialist at
                            <strong>
                              <a href="tel:1-866-434-2037">1-866-434-2037</a>{" "}
                            </strong>
                            (TTY:
                            <strong>
                              <a href="tel:711">711</a>
                            </strong>
                            ), 8 a.m. to 8 p.m., daily, Central and Mountain
                            times to see if you are eligible to enroll.
                          </p>

                          <p>
                            Please contact us at
                            <strong>
                              <a href="tel:1-866-434-2037">1-866-434-2037</a>
                            </strong>
                            (TTY:
                            <strong>
                              <a href="tel:711">711</a>
                            </strong>
                            ) if you would prefer that we send you information
                            in a language other than English or in an accessible
                            format. Our office hours are 8 a.m. to 8 p.m.,
                            daily, Central and Mountain times.
                          </p>
                        </div>
                      </div>

                      <div
                        data-drupal-selector="edit-actions"
                        className="
                            form-actions
                            webform-actions
                            form-group
                            js-form-wrapper
                            form-wrapper
                          "
                        data-msg-required="Submit button(s) is required."
                        id="edit-actions"
                      >
                        {/* <button
                            formNoValidate="formnoValidate"
                            className="
                              webform-button--previous
                              button
                              js-form-submit
                              form-submit
                              btn-default btn
                            "
                            data-drupal-selector="edit-actions-wizard-prev"
                            data-msg-required="This field is required."
                            type="submit"
                            id="edit-actions-wizard-prev"
                            name="op"
                            value="Previous Page"
                          >
                            Previous Page
                          </button>
                          <button
                            className="
                              webform-button--next
                              button
                              js-form-submit
                              form-submit
                              btn-default btn
                            "
                            data-drupal-selector="edit-actions-wizard-next"
                            data-msg-required="This field is required."
                            type="submit"
                            id="edit-actions-wizard-next"
                            name="op"
                            value="Continue to Step 3"
                          >
                            Continue to Step 3
                          </button> */}

                        <Button
                          className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handlePrevious}
                          //disabled={p1}
                          {...(p1 && {
                            style: { display: "none" },
                          })}
                        >
                          Previous
                        </Button>

                        <Button
                          className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handleNextClick}
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : activeStep === steps.length - 2
                            ? "Submit"
                            : "Continue to " + steps[activeStep + 1].toString()}
                        </Button>
                        <div
                          className="error"
                          {...(displayCheckOptionError !== true && {
                            style: { display: "none" },
                          })}
                        >
                          At least one item must be selected.
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Step2_2024;
