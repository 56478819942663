import { handleResponse, handleError, getConfirmationNum } from "./apiUtils";
const url = process.env.REACT_APP_ENROLLMENT_API_URL;
const env = process.env.REACT_APP_ENV;

export async function submitEnrollment(enrollment) {

  // Reg expression to match '123' anywhere in the string
  let patternTrigger = /123/;

  // 4/8/24 - disable API call for non-production environments
  if ((env === 'dev' || env === 'stage') && (patternTrigger.test(enrollment.firstname) || patternTrigger.test(enrollment.lastname))) {
    console.log('API call disabled for environment: ' + env);
    enrollment.confirmationno = getConfirmationNum();
    return JSON.stringify(enrollment);
  }

  try {
    let response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(enrollment),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
