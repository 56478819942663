import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const Covid19 = () => {
  const[content, isLoading] = useContent("6pWV7n7mE75tpstJOSwqo");

  if (isLoading) return <p>Loading...</p>;
  
  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>covid-19</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content.headline} />
            </h1>

            <article
              data-history-node-id="224"
              role="article"
              about="/covid-19"
              typeof="schema:WebPage"
              className="page full clearfix"
            >
              <span
                property="schema:name"
                content="covid-19"
                className="hidden"
              ></span>

              <div className="content">
                <div
                  property="schema:text"
                  className="field field--name-body field--type-text-with-summary field--label-hidden field__item"
                >
                  {/* begin covid page NPA */}
                  <div id="covid">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content.copy} />
                  </div>
                  {/* end covid page NPA */}
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Covid19;
