import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Button } from "@material-ui/core";
import WhatsNext from "./WhatsNext";
import moment from "moment";
import updateEnrollData from "../../state/actions/updateEnrollData";
import clearEnrollData from "../../state/actions/clearEnrollData";
import { ErrorMessage } from "@hookform/error-message";
import { submitConfirmation } from "../../api/confirmationApi";

const ChangePaymentConfirmation = ({ confNum, diffAddress, setBackButtonDisabled }) => {
  const { state, actions } = useStateMachine({
    updateEnrollData,
    clearEnrollData,
  });

  const {
    register,
    formState: { errors },
    getValues,
  } = useForm();

  // // disable back button
  // useEffect(
  //   () => {
  //     setBackButtonDisabled(true);
  //   }, []);

  useEffect(() => () => actions.clearEnrollData(), []);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [emailSent, setEmailSent] = useState(false);

  const handleEmailSent = async (e) => {
    e.preventDefault();
    setTimeout(() => setEmailSent(!emailSent), 3000); //<--setTimeout is to simulate the email submission. This will be replaced with call/response to API

    let confRequest = { conf: state.enrollData.confirmationno, email: email };

    let s = await submitConfirmation(confRequest);

    // console.log("Email conf response: " + s);

    setEmailSent(true);

    // if (s.confirmationno !== "") {

    // } else {
    // }
  };
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePrint = () => {
    window.print();
  };

  const getPaymentOptionText = () => {
    switch (state.enrollData.paymentoption) {
      case "Paper":
        return "Get a paper bill";
      case "EFT":
        return "Electronic funds transfer (EFT)";
      case "SSA":
        return "Automatic deduction from your Social Security benefit check";
      case "RRB":
        return "Automatic deduction from your Railroad Retirement Board (RRB) benefit check";
      default:
        return "";
    }
  };

  return (
    <>
      <div
        data-webform-key="step_5"
        data-drupal-selector="edit-step-5"
        className="js-form-wrapper form-wrapper form-group"
        data-msg-required="Step 5 is required."
        id="edit-step-5--2"
      >
        <div
          id="edit-step-5-title"
          className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                        webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                      "
        >
          <div className="webform-confirmation">
            <div className="webform-confirmation__message">
              <div className="row">
                <div className="col-sm-12">
                  <h3>
                    Your confirmation number is{" "}
                    {" " + state.enrollData.confirmationno}
                  </h3>
                  <p>
                    Congratulations, you have completed the online enrollment
                    for MedicareBlue Rx.
                  </p>
                  <p className="leftarrowheading">
                    Here's what to expect next.
                  </p>
                  <ul>
                    <li>
                      {" "}
                      You will receive several mailings over the next few weeks.
                    </li>
                    {/* begin post enroll call center script markup */}
                    <li>
                      <button
                        type="button"
                        onClick={handleOpen}
                        className="btn btn-link btn-anchor btn-lg"
                      >
                        See complete details on what to expect next
                      </button>
                      .
                    </li>

                    <WhatsNext
                      open={open}
                      handleOpen={handleOpen}
                      handleClose={handleClose}
                      confNum={confNum}
                    />
                    {/* begin post enroll call center script markup 2018 full path */}
                    <li>
                      If you have any questions or concerns about MedicareBlue
                      Rx{" "}
                      <a
                        href="/contact/medicareblue-rx"
                        data-tooltip="You will not lose your place on this confirmation page by clicking this link."
                        target="_blank"
                      >
                        contact customer service
                      </a>
                      .
                    </li>
                  </ul>
                  <p className="leftarrowheading">
                    Please write down the confirmation number above.
                  </p>
                  <ul>
                    <li>
                      You can also send it by email (other information on this
                      page will not be included in the email).
                    </li>
                    <li>You can print this page for your records.</li>
                  </ul>
                  <p></p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <form
                    id="confirmation-form"
                    className="email-confirmation form-inline"
                    novalidate="novalidate"
                  >
                    <div className="form-group">
                      <input
                        {...register("confirmationEmail", {
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email address.",
                          },
                        })}
                        value={email}
                        onChange={handleEmail}
                        className="form-control"
                        type="email"
                        id="uemail"
                        maxLength="255"
                      />
                      <div className="error">
                        <ErrorMessage errors={errors} name="confirmationEmail">
                          {({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                              <p key={type}>{message}</p>
                            ))
                          }
                        </ErrorMessage>
                      </div>
                    </div>{" "}
                    <div className="send-confirmation">
                      <button
                        onClick={handleEmailSent}
                        className="btn btn-default"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                  <div
                    className="error"
                    {...(!emailSent && {
                      style: { display: "none" },
                    })}
                  >
                    Email request has been sent.
                  </div>
                </div>
                <div className="col-sm-6">
                  <button onClick={handlePrint} className="btn btn-default">
                    Print this Page
                  </button>
                </div>
              </div>
              <h3>Date and time of enrollment submission</h3>
              {state.enrollData.dor}
              <div>
                <p>The above is shown in Central time</p>
              </div>
              <h3>Your information</h3>
            </div>
            <br />

            {/*Row 1*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Medicare Information*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Medicare information
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">
                    Medicare claim (ID) number:{" "}
                  </label>{" "}
                  {state.enrollData.medicareclaimnum}
                </div>
              </div>

              {/*Premium Payment Option*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Premium payment option
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">Payment options: </label>{" "}
                  {getPaymentOptionText()}
                  <br />
                  <div
                    {...(state.enrollData.paymentoption !== "EFT" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Account holder name:
                    </label>{" "}
                    {state.enrollData.accountholdername}
                    <br />
                    <label className="control-label">Bank name:</label>{" "}
                    {state.enrollData.bankname}
                    <br />
                    <label className="control-label">Account type:</label>{" "}
                    {state.enrollData.accttype}
                    <br />
                    <label className="control-label">
                      Bank routing number:
                    </label>{" "}
                    {state.enrollData.bankroutingnumber}
                    <br />
                    <label className="control-label">
                      Bank account number:
                    </label>{" "}
                    {state.enrollData.bankacctnumber}
                    <br />
                  </div>
                </div>
              </div>
            </div>

            {/*Row 2*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Personal Information*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Personal information
                  </div>
                </div>

                <div className="panel-body">
                  <div
                    {...(state.enrollData.sal === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Title:</label>{" "}
                    {state.enrollData.sal}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.firstname === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">First name: </label>{" "}
                    {state.enrollData.firstname}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.mi === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Middle initial:</label>{" "}
                    {state.enrollData.mi}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.lastname === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Last name:</label>{" "}
                    {state.enrollData.lastname}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.dob === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Date of birth:</label>{" "}
                    {state.enrollData.dob}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.sex === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Gender:</label>{" "}
                    {state.enrollData.sex}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permst1 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Address:</label>{" "}
                    {state.enrollData.permst1}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permst2 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Address 2</label>{" "}
                    {state.enrollData.permst2}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permcity === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">City:</label>{" "}
                    {state.enrollData.permcity}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permstate === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">State:</label>{" "}
                    {state.enrollData.permstate}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permzip5 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Zip code (xxxxx):</label>{" "}
                    {state.enrollData.permzip5}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permzip4 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Zip+4 (xxxx)</label>{" "}
                    {state.enrollData.permzip4}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.homephone === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Home phone:</label>{" "}
                    {state.enrollData.homephone}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.altphone === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Alternate phone:</label>{" "}
                    {state.enrollData.altphone}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.email === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Email address</label>{" "}
                    {state.enrollData.email}
                    <br />
                  </div>
                  <div
                    {...(!diffAddress && {
                      style: { display: "none" },
                    })}
                  >
                    <div
                      {...(state.enrollData.mailst1 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing Address:</label>{" "}
                      {state.enrollData.mailst1}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailst2 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        Mailing Address 2:
                      </label>{" "}
                      {state.enrollData.mailst2}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailcity === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing City:</label>{" "}
                      {state.enrollData.mailcity}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailstate === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing State:</label>{" "}
                      {state.enrollData.mailstate}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailzip5 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        Mailing Zip Code (xxxxx):
                      </label>{" "}
                      {state.enrollData.mailzip5}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailzip4 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing Zip+4:</label>{" "}
                      {state.enrollData.mailzip4}
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              {/*Applicant/Auth Rep Signature*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Authorization signature
                  </div>
                </div>
                <br />I agreed to and accepted the terms as described in the
                payment method authorization.
                <div className="panel-body">
                  <div>
                    <label className="control-label">Today's date:</label>{" "}
                    {moment().format("MM/DD/YYYY")}
                    <br />
                  </div>

                  <div>
                    <label className="control-label">Enrollee first name</label>{" "}
                    {state.enrollData.firstname}
                    <br />
                  </div>

                  <div>
                    <label className="control-label">Enrollee last name:</label>{" "}
                    {state.enrollData.lastname}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Button
        className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
        onClick={handleNextClick}
        disabled={activeStep === 5 && !acceptTerms}
      >
        {activeStep === steps.length - 1
          ? "Finish"
          : activeStep === steps.length - 2
          ? "Submit"
          : "Continue to " + steps[activeStep + 1].toString()}
      </Button> */}
    </>
  );
};

export default ChangePaymentConfirmation;
