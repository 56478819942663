import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Enroll = () => {
  const [content, isLoading] = useContent("5Whhds7wa9Nt7Pn1AYsXi9");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Enroll</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content.headline}
              />
            </h1>

            <article className="standard full clearfix">
              <div className="content">
                <div>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.copy}
                  />
                </div>
              </div>
            </article>
          </div>
        </section>

        {/* <aside className="col-sm-4 col-md-3 side-section order-md-1">
          <div className="region region-sidebar-second">
            <ul className="menu menu--main nav navbar-nav">
              <li className="first med-2021-enroll-now">
                <Link to="/enroll/2022-enroll-now">2022 Enroll now</Link>
              </li>
              <li className="med-2021-change-plan">
                <Link to="/enroll/2022-change-plan">2022 Change plan</Link>
              </li>
            </ul>
          </div>
        </aside> */}
      </div>
    </div>
  );
};

export default Enroll;
